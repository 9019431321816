import { useCallback, useRef, useState } from 'react';
import { useFetchCustom } from '../../../../../common/utils/HttpUtils';
import { HereResponse } from './HereResponse';
import { OsmPlace, reverse } from '../../../../../common/component/map/Nominatim';
import { LatLng } from 'leaflet';
import { countryCodesIso2ToIso3 } from '../../../../model/HereProfilVozidla';
import { ButtonType, KilometrovnikLinkType } from './KilometrovnikLinkPart';

interface CacheType {
	[key: string]: HereResponse;
}

type ParamsWithId = {
	id: number;
	profileId: number;
	linkType: KilometrovnikLinkType;
	buttonType: ButtonType;
	anotherUrlParams: string;
};

type ParamsWithLocation = {
	odkud: string;
	kam: string;
	profileId: number;
	buttonType: ButtonType;
	anotherUrlParams: string;
};

export const useCachedFetch = () => {
	const cache = useRef<CacheType>({});  // Cache to store request data
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const {fetch:hereFetchTrasa} = useFetchCustom<HereResponse>({ endpoint: null}, undefined, HereResponse);

	const fetchTrasa = useCallback(async (params: ParamsWithId | ParamsWithLocation) => {
		let cacheKey: string;
		let cacheKeyTrasaANaklady: string;
		let cacheKeyTrasa: string;
		let cacheKeyNaklady: string;

		if ('id' in params) {
			const { id, profileId, linkType, buttonType, anotherUrlParams } = params;
			cacheKey = `id=${id}&profileId=${profileId}&kilometrovnikLinkType=${linkType}&buttonType=${buttonType}${anotherUrlParams}`;
			cacheKeyTrasaANaklady = `id=${id}&profileId=${profileId}&kilometrovnikLinkType=${linkType}&buttonType=${ButtonType.TRASA_A_NAKLADY}${anotherUrlParams}`;
			cacheKeyTrasa = `id=${id}&profileId=${profileId}&kilometrovnikLinkType=${linkType}&buttonType=${ButtonType.TRASA}${anotherUrlParams}`;
			cacheKeyNaklady = `id=${id}&profileId=${profileId}&kilometrovnikLinkType=${linkType}&buttonType=${ButtonType.NAKLADY}${anotherUrlParams}`;
		} else {
			const { odkud, kam, profileId, buttonType, anotherUrlParams } = params;
			cacheKey = `odkud=${odkud}&kam=${kam}&profileId=${profileId}&buttonType=${buttonType}${anotherUrlParams}`;
			cacheKeyTrasaANaklady = `${cacheKey}&buttonType=${ButtonType.TRASA_A_NAKLADY}`;
			cacheKeyTrasa = `${cacheKey}&buttonType=${ButtonType.TRASA}`;
			cacheKeyNaklady = `${cacheKey}&buttonType=${ButtonType.NAKLADY}`;
		}

		// Nemá smysl znovu načítat trasu a náklady jednotlivě v případě, že už máme obojí.
		if ((params.buttonType == ButtonType.TRASA || params.buttonType == ButtonType.NAKLADY) && cache.current[cacheKeyTrasaANaklady]) {
			const data = {...cache.current[cacheKeyTrasaANaklady]};
			if (params.buttonType == ButtonType.TRASA) {
				data.tolls = null;
			}
			if (params.buttonType == ButtonType.NAKLADY) {
				data.routing = null;
			}
			cache.current[cacheKey] = data;
		}

		// V případě, že jsme načetli trasu a potom náklady případně opačně. Nemá smysl znovu získávat společně trasu a náklady.
		if (params.buttonType == ButtonType.TRASA_A_NAKLADY && cache.current[cacheKeyTrasa] && cache.current[cacheKeyNaklady]) {
			const data = {...cache.current[cacheKeyTrasa]};
			data.tolls = cache.current[cacheKeyNaklady].tolls;
			cache.current[cacheKeyTrasaANaklady] = data;
			return data;
		}

		if (cache.current[cacheKey]) {
			return cache.current[cacheKey];  // Return cached data if available
		}

		let endpointPrefix: string;
		let endpoint: string;
		if ('id' in params) {
			endpointPrefix = 'user/here-pv-trasa?';
			endpoint = `${endpointPrefix}id=${params.id}&profileId=${params.profileId}&kilometrovnikLinkType=${params.linkType}&buttonType=${params.buttonType}${params.anotherUrlParams}`;
		} else {
			endpointPrefix = 'user/here-pv-trasa/customOdkudKam?';
			endpoint = `${endpointPrefix}odkud=${params.odkud}&kam=${params.kam}&profileId=${params.profileId}&buttonType=${params.buttonType}${params.anotherUrlParams}`;
		}

		try {
			setLoading(true);
			setError(null);
			if (params.buttonType == ButtonType.TRASA_A_NAKLADY && cache.current[cacheKeyTrasa]) { // Už máme načtenou trasu a potřebujeme donačíst pouze náklady.
				const data = await hereFetchTrasa({endpoint: `${endpointPrefix}${cacheKeyNaklady}`});
				const trasaANakladyData = {...cache.current[cacheKeyTrasa]};
				trasaANakladyData.tolls = data.tolls;
				cache.current[cacheKeyTrasaANaklady] = trasaANakladyData;
				return trasaANakladyData;
			} else if (params.buttonType == ButtonType.TRASA_A_NAKLADY && cache.current[cacheKeyNaklady]) { // Už máme načtené náklady a potřebujeme donačíst pouze trasu.
				const data = await hereFetchTrasa({endpoint: `${endpointPrefix}${cacheKeyTrasa}`});
				const trasaANakladyData = {...cache.current[cacheKeyNaklady]};
				trasaANakladyData.routing = data.routing;
				cache.current[cacheKeyTrasaANaklady] = trasaANakladyData;
				return trasaANakladyData;
			} else {
				const data = await hereFetchTrasa({endpoint});
				await fetchAndUpdateLocations(data);
				cache.current[cacheKey] = data;  // Store response in cache
				return data;
			}
		} catch (err) {
			console.log(err);
			setError(err);
			throw err;  // Re-throw the error to be caught by the caller
		} finally {
			setLoading(false);
		}
	}, []);

	async function fetchAndUpdateLocations(data: HereResponse) {
		const locationsArray = [...data.uniqueLocations];

		const promises = locationsArray.map(async (l) => {

			const resGeo: OsmPlace = await reverse(new LatLng(l.lat, l.lng));

			// Initialize reverseGeocodingItems if it doesn't exist
			if (!l.reverseGeocodingItems) {
				l.reverseGeocodingItems = {
					items: [{
						address: { // Ensure we have an initial address object
							postalCode: "",
							countryCode: "",
							city: ""
						}
					}]
				};
			}

			// Check if there is at least one item to update, if not, initialize one
			if (l.reverseGeocodingItems.items.length === 0) {
				l.reverseGeocodingItems.items.push({
					address: {
						postalCode: "",
						countryCode: "",
						city: ""
					}
				});
			}

			l.reverseGeocodingItems.items[0].address.postalCode = resGeo.address.postcode ?? "*";
			l.reverseGeocodingItems.items[0].address.countryCode = countryCodesIso2ToIso3[resGeo.address.country_code.toUpperCase()];
			l.reverseGeocodingItems.items[0].address.city = resGeo.address.city;
			// Open stret mapy občas vrací město občas ne atd.
			if (!resGeo.address.city) {
				l.reverseGeocodingItems.items[0].address.city = resGeo.address.town;
			}
			if (!resGeo.address.city && !resGeo.address.town) {
				l.reverseGeocodingItems.items[0].address.city = resGeo.address.municipality;
			}
			if (!resGeo.address.city && !resGeo.address.town && !resGeo.address.municipality) {
				l.reverseGeocodingItems.items[0].address.city = resGeo.address.village;
			}
		});

		await Promise.all(promises);
	}

	return { fetchTrasa, loading, error };
};