import {Box, Button, CircularProgress, createStyles, Grid, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {FormField, FormInputType, useForm} from '../../../../raal_components/form/Form';
import {exist} from '../../../../../common/utils/Util';
import {HereProfilVozidlaForm} from './HereTrasa';
import {HereViaPoints, ViaItem} from './HereViaPoints';
import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {ButtonType} from './KilometrovnikLinkPart';
import {useTranslation} from 'react-i18next';
import {useHereProfilVozidlaSelectObj} from '../../../../raal_components/SelectOptions';
import {useCachedFetch} from './HereCache';
import {HereResponse} from './HereResponse';
import {HereProfilVozidla} from '../../../../model/HereProfilVozidla';
import _ from 'lodash';
import {JsonProperty} from '../../../../../common/utils/objectmapper/Mapper';
import {useFetchCustom} from "../../../../../common/utils/HttpUtils";
import {DynamicTitle} from "../../../../raal_components/DynamicTitle";

interface Coordinates {
    coordinates: [string, string];
}

interface Misto {
    koordinat: Coordinates;
}

interface OdkudKamData {
    mistoOdkud: Misto;
    odkud: string;
    mistoKam: Misto;
    kam: string;
}

const defaultOdkudKamData: OdkudKamData = {
    mistoOdkud: {
        koordinat: {
            coordinates: null,
        },
    },
    odkud: null,
    mistoKam: {
        koordinat: {
            coordinates: null,
        },
    },
    kam: null,
};

export class Via {
    via: string;
    latitude: number;
    longitude: number;
}

export class CustomRoute {
    id: number;
    titulek: string;
    odkud: string;
    odkudLatitude: number;
    odkudLongitude: number;
    kam: string;
    kamLatitude: number;
    kamLongitude: number;
    hereProfilVozidla: HereProfilVozidlaSimple;
    // hereProfilVozidlaId: number;
    @JsonProperty({type: {clazz: Via, isArray: true}})
    via: Via[];
    trasa: string;
    counter: number;
}

const useStyles = makeStyles((theme) => createStyles({
    hidden: {
        display: 'none',
    },
}));

export class HereProfilVozidlaSimple {
    id: number;
    nazev: string;
    data: any;
}

export const HereKilometrovnikForm = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const form = useForm<CustomRoute>();
    const [formDataHistory, setFormDataHistory] = useState<CustomRoute>();
    const [dataChanged, setDataChanged] = useState(false);
    const hereProfilVozidlaSelectProps = useHereProfilVozidlaSelectObj({isClearable: true, autoOpenDisabled: true});
    const [hereProfilVozidla, setHereProfilVozidla] = useState<HereProfilVozidla>();
    const [trasa, setTrasa] = useState<HereResponse>();
    const {fetchTrasa, loading: hereLoading, error: hereTrasaError} = useCachedFetch();
    const [viaItems, setViaItems] = useState<ViaItem[]>([]);
    const [viaItemsHistory, setViaItemsHistory] = useState<ViaItem[]>([]);
    const [isTrasaAndMytoButtonDisabled, setIsTrasaAndMytoButtonDisabled] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [ignoreValidation, setIgnoreValidation] = useState(false);
    const {fetch: hereProfilVozidlaFetch} = useFetchCustom<HereProfilVozidla>({endpoint: 'user/hereprofilvozidla/default-kilometrovnik'}, undefined, HereProfilVozidla);
    const { fetch: fetchProfilVozidlaById } = useFetchCustom<HereProfilVozidla>({ endpoint: `user/hereprofilvozidla/${form?.data?.hereProfilVozidla?.id}` }, undefined, HereProfilVozidla);

    useEffect(() => {
        // TODO jinak kdyz bude cas
        const fetchData = async () => {
            if (form?.data?.hereProfilVozidla?.id && form?.data?.hereProfilVozidla?.data == null) {
                try {
                    const d = await fetchProfilVozidlaById();
                    // @ts-ignore
                    form.data.hereProfilVozidla.data = d;
                    setHereProfilVozidla(d);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
        fetchData();

        if (form?.data?.id) {
            document.title = "Editace";
        } else {
            document.title = "Nový";
        }
    }, []);

    const clearForm = async () => {
        if (form.state.errors.errors.length > 0) {
            setIgnoreValidation(true);
        }
        for (const f of form.fields) {
            if (f.props.name === 'hereProfilVozidla') {
                loadProfile();
            } else {
                f.valueChanged(null);
            }
            f.forceUpdate();
        }
        form.data.odkud = undefined;
        form.data.kam = undefined;
        form.data.via = [];
        form.data.trasa = null;

        setViaItems([]);
        setDataChanged(false);
        setTrasa(null);
        setIsTrasaAndMytoButtonDisabled(true);
    };

    useImperativeHandle(ref, () => ({
        clearForm,
        ignoreValidation,
        setIgnoreValidation
    }));

    useEffect(() => {
        setFormDataHistory(_.cloneDeep(form?.data));
    }, [form]);

    useEffect(() => {
        if (form.data.trasa) {
            setTrasa(JSON.parse(form.data.trasa));
        }
        if (form.data.via) {
            let convertedItems: ViaItem[] = [];
            form.data.via?.map(v => {
                let convertedItem = new ViaItem(v.via, {
                    mistoKam: {
                        koordinat: {
                            coordinates: [
                                v.longitude,
                                v.latitude,
                            ],
                        },
                    },
                    viaInput: v.via,
                });
                convertedItems.push(convertedItem);
            });

            setIsTrasaAndMytoButtonDisabled(!(convertedItems.length >= 2));

            setViaItems(convertedItems);
            setViaItemsHistory(_.cloneDeep(convertedItems));
        }
    }, [form]);

    useEffect(() => {
        if (_.isEqual(viaItems, viaItemsHistory)) {
            unsetFakeFormModified();
        } else {
            setFakeFormModified();
        }
        setRefresh(true);
    }, [viaItems]);

    useEffect(() => {
        if (refresh) {
            const hereProfilExists = !!form?.data?.hereProfilVozidla?.id;
            setIsTrasaAndMytoButtonDisabled(!(viaItems.length >= 2 && hereProfilExists));
        }
        setRefresh(false);
    }, [refresh]);

    const loadProfile = async () => {
        try {
            const d = await hereProfilVozidlaFetch();
            if (exist(d?.id)) {
                // @ts-ignore
                form.data.hereProfilVozidla = { id: d.id, nazev: d.nazev, data: d };
                setHereProfilVozidla(d);
            } else {
                // @ts-ignore
                form.data.hereProfilVozidla = {};
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            // @ts-ignore
            form.data.hereProfilVozidla = {};
        }
    }

    const isNew = () => form?.data?.id === null || form?.data?.id === undefined;

    const setFakeFormModified = () => {
        form.fields.forEach((f) => {
            if (f.props.name == 'counter') {
                if (f.value == null) {
                    f.valueChanged(1);
                }
            }
        });
    };

    const unsetFakeFormModified = () => {
        form.fields.forEach((f) => {
            if (f.props.name == 'counter') {
                f.valueChanged(null);
                f.forceUpdate();
            }
        });
    };

    const getHereTrasa = async (buttonType: ButtonType) => {
        const profileId = form?.data?.hereProfilVozidla?.id;

        try {
            const odkudLatitude = viaItems[0].content.mistoKam?.koordinat?.coordinates[1];
            const odkudLongitude = viaItems[0].content.mistoKam?.koordinat?.coordinates[0];
            const kamLatitude = viaItems[viaItems.length-1].content.mistoKam?.koordinat?.coordinates[1];
            const kamLongitude = viaItems[viaItems.length-1].content.mistoKam?.koordinat?.coordinates[0];

            const odkud = odkudLatitude + ',' + odkudLongitude;
            const kam = kamLatitude + ',' + kamLongitude;
            const hereResponse = await fetchTrasa({
                odkud,
                kam,
                profileId,
                buttonType,
                anotherUrlParams: getAnotherUrlParams()
            });
            if (exist(hereResponse)) {
                form.data.trasa = JSON.stringify(hereResponse);
                setTrasa(hereResponse);
                setFakeFormModified();
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAnotherUrlParams = () => {
        let anotherUrlParams = '';
        if (viaItems?.length >= 2) {
            // Odebrat první a poslední via, protože to jsou odkud a kam.
            const viaItemsCopy = _.cloneDeep(viaItems);
            viaItemsCopy.shift();
            viaItemsCopy.pop();
            viaItemsCopy.map((item) => {
                anotherUrlParams += '&via=' + item.content.mistoKam.koordinat.coordinates[1] + ',' + item.content.mistoKam.koordinat.coordinates[0];
            });
        }
        return anotherUrlParams;
    };

    const setViaItemsWrapper = (it: ViaItem[]) => {
        setViaItems(it);
        let viaList: Via[] = [];
        it?.map(via => {
            viaList.push({
                via: via.id,
                latitude: via.content.mistoKam.koordinat.coordinates[1],
                longitude: via.content.mistoKam.koordinat.coordinates[0]
            });
        });
        form.data.via = viaList;
    };

    return <>
        <DynamicTitle title={`${form.data?.titulek ? `[${form.data?.titulek}] `: ''}${t('Sections.Mileage')} - ${t('Dials.VypocetTrasy')}`} />
        <Paper><Box ml={2} mr={2} pt={2}>
            <Grid container spacing={2}>
                {!isNew() &&
                    <Grid item lg={12}>
                        <Grid item xs={12} sm={4}>
                            <Grid item container spacing={2}>
                                <Grid item xs={12}>
                                    <FormField title={t('Here.CustomRoute.Titulek')} name="titulek" type="text" required
                                               textFieldProps={{inputProps: {maxLength: 255}}}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <HereViaPoints viaItems={viaItems}
                               noAutoFocus={false}
                               setViaItems={setViaItemsWrapper}
                               data={{
                               }}
                               routing={trasa?.routing}
                               tolls={trasa?.tolls}
                               getHereTrasa={getHereTrasa}
                               firstLastAsFromTo/>
                <Grid item xs={12} md={2}>
                    <FormField title={t('Here.Kilometrovnik.ProfilVozidla')} name="hereProfilVozidla"
                               type={FormInputType.Select} selectProps={hereProfilVozidlaSelectProps}
                               setValue={(data, fieldName, value) => {
                                   data.hereProfilVozidla = value;
                               }}
                               getValue={(data, fieldName) => {
                                   if (hereProfilVozidla == null && data.hereProfilVozidla?.data) {
                                       setHereProfilVozidla(data.hereProfilVozidla?.data);
                                   }
                                   return data.hereProfilVozidla;
                               }}
                               onChanged={(f) => {
                                   setHereProfilVozidla(f.value?.data);
                                   setRefresh(true);
                               }}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Grid item container spacing={1}>
                        <Grid item>
                            <Button type={'button'} variant="contained" color={'primary'} name={'trasaButton'}
                                    disabled={isTrasaAndMytoButtonDisabled}
                                    onClick={() => getHereTrasa(ButtonType.TRASA)}>{`${t('Here.Kilometrovnik.Button.Trasa')}${dataChanged ? ' (' + t('PVI.Modified') + ')' : ''}`}</Button>
                        </Grid>
                        <Grid item>
                            <Button type={'button'} variant="contained" color={'primary'}
                                    disabled={isTrasaAndMytoButtonDisabled}
                                    onClick={() => getHereTrasa(ButtonType.NAKLADY)}>{`${t('Here.Kilometrovnik.Button.Naklady')}${dataChanged ? ' (' + t('PVI.Modified') + ')' : ''}`}</Button>
                        </Grid>
                        <Grid item>
                            <Button type={'button'} variant="contained" color={'primary'}
                                    disabled={isTrasaAndMytoButtonDisabled}
                                    onClick={() => getHereTrasa(ButtonType.TRASA_A_NAKLADY)}>{`${t('Here.Kilometrovnik.Button.TrasaANaklady')}${dataChanged ? ' (' + t('PVI.Modified') + ')' : ''}`}</Button>
                        </Grid>
                    </Grid>
                </Grid>
                {hereLoading && (
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Box>
                                <CircularProgress color="inherit"/>
                            </Box>
                        </Grid>
                    </Grid>)
                }
                {trasa &&
                    <Grid item style={{position: 'relative'}} xs={12}>
                        {hereLoading && (
                            <Grid
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(128, 128, 128, 0.5)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1,
                                }}
                            >
                                <CircularProgress/>
                            </Grid>
                        )}
                        <div id="scrollPoint" style={{display: 'hidden'}}/>
                        <Grid style={{position: 'relative', zIndex: 0}} xs={12}>
                            {trasa && (
                                <HereProfilVozidlaForm
                                    routing={trasa.routing}
                                    tolls={trasa.tolls}
                                    uniqueLocations={trasa.uniqueLocations}
                                    hereProfilVozidla={hereProfilVozidla}
                                />
                            )}
                        </Grid>
                    </Grid>
                }
                {hereTrasaError && (
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            {t('Here.TrasaDetail.HereError')}
                        </Grid>
                        <Grid item xs={12}>
                            {hereTrasaError?.json?.message}
                        </Grid>
                    </Grid>)
                }
                <Grid container className={classes.hidden}>
                    <FormField title="counter" name="counter" type="number"/>
                </Grid>
            </Grid></Box></Paper>
    </>;
});