import {OsmPlace, search} from '../map/Nominatim';
import {useTranslation} from 'react-i18next';
import {Button, Grid} from '@material-ui/core';
import {MuiModal} from '../MuiModal';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Form, FormField, FormInputType, useForm} from '../../../web/raal_components/form/Form';
import {useMpzRevertResolver} from '../../utils/MpzUtils';
import {FieldError} from './ValidationError';
import {Loading} from "../Loading";

export type FormCustomPlaceDialogType = {
    setOpenDialog: (openDialog: boolean) => void,
    openDialog: boolean,
    osmPlace: OsmPlace,
    onSaved: (data: OsmPlace) => void,
    onlyValidLocationIsAllowed?: boolean,
    setValue?: (value: any) => void,
    invokeOnValueChanged?: (_value: OsmPlace) => void,
}

export type FormCustomPlace = {
    mpz?: string,
    psc?: string,
    mesto?: string,
}

export type FormCustomPlaceSeach = {
    country?: string,
    psc?: string,
    city?: string,
}

export function FormCustomPlaceDialog(props: FormCustomPlaceDialogType) {
    const {t} = useTranslation();
    const [revertResolveMpz] = useMpzRevertResolver();
    const [formData, setFormData] = useState<FormCustomPlace>({mpz: '', psc: '', mesto: ''});
    const [testSearch, setTestSearch] = useState(null);
    const [mpzDom, setMpzDom] = useState(null);
    const [placeLoading, setPlaceLoading] = useState(false);

    useEffect(() => {
        const parts = props.osmPlace?.display_name?.split(':') ?? [];
        let mpz = '', psc = '', mesto = '';

        if (props.osmPlace?.display_name && parts.length === 1) {
            mesto = props.osmPlace.display_name;
        } else {
            [mpz = '', psc = '', mesto = ''] = parts;
        }

        setFormData({
            mpz: mpz,
            psc: psc,
            mesto: mesto,
        });
        setTestSearch(null);
        if (mpzDom) {
            mpzDom.focus();
            mpzDom.click();
        }
    }, [props]);

    const getSearchFields = (): FormCustomPlaceSeach => {
        const country = revertResolveMpz(formData.mpz);
        const psc = formData.psc;
        // V případě, že je vyplněno město i PSČ, tak výsledkem je na prvním místě město bez PSČ.
        // Mělo by stačit kombinace státu a města. Ta je přesnější.
        const city = formData.psc?.trim().length > 0 ? '' : formData.mesto;
        return {country, psc, city};
    };

    const handleForm = async () => {
        try {
            const {country, psc, city} = getSearchFields();

            // Pokus o vyhledávání lokace přes Nominatim.
            const resOsm: OsmPlace[] = await search(`${country}:${psc}:${city}`) ?? [];

            // TODO nevím kde všude v adrese je to potřeba - najít a vylepšit
            // TODO a obecně je to takový workaround s default hodnotami, které reálně nejsou potřeba, aby to prošlo validací
            // @ts-ignore
            const notFoundWithNominatim: OsmPlace = {
                place_id: '0',
                licence: "x",
                lat: 0.0,
                lon: 0.0,
                category: "place",
                type: "postcode",
                place_rank: '0',
                importance: '0',
                addresstype: "postcode",
                name: "x",
                display_name: `${country?.toUpperCase()}:${formData.psc?.toUpperCase()}:${formData.mesto?.toUpperCase()}`,
                address: {
                    postcode: formData.psc?.toUpperCase(),
                    suburb: "x",
                    city: formData.mesto?.toUpperCase(),
                    county: "x",
                    state: country?.toUpperCase(),
                    country: country?.toUpperCase(),
                    country_code: country?.toUpperCase(),
                    neighbourhood: "x",
                    road: "x",
                    village: "x"
                }
            };

            if (resOsm?.length > 0) {
                if (resOsm[0].address?.city?.toUpperCase() != formData?.mesto?.toUpperCase()) {
                    resOsm[0].address.city = formData?.mesto?.toUpperCase();
                }

                if (resOsm[0].address?.postcode?.toUpperCase() != formData?.psc?.toUpperCase()) {
                    resOsm[0].address.postcode = formData?.psc?.toUpperCase();
                }

                toUpperCaseRemoveDiacritics([resOsm[0]]);
                props.onSaved(resOsm[0]);
            } else {
                props.onSaved(notFoundWithNominatim);
            }
        } finally {
            setTimeout(() => {
                setPlaceLoading(false);
                props.setOpenDialog(false);
            }, 100);
        }
    };

    const check= (displayPlaceLoading: boolean) => {
        if (displayPlaceLoading) {
            setPlaceLoading(true);
        }
        return new Promise<OsmPlace[]>(async (resolve, reject) => {
            try {
                const { country, psc, city } = getSearchFields();
                const resOsm: OsmPlace[] = await search(`${country}:${psc}:${city}`) ?? [];
                setTestSearch(resOsm);
                resolve(resOsm);
            } catch (error) {
                reject(error);
            } finally {
                if (displayPlaceLoading) {
                    setPlaceLoading(false);
                }
            }
        });
    };

    // const check = async () => {
    //     const {country, psc, city} = getSearchFields();
    //     const resOsm: OsmPlace[] = await search(`${country}:${psc}:${city}`) ?? [];
    //     setTestSearch(resOsm);
    //     return resOsm;
    // };

    const closeDialog = () => {
        // TODO Když bude čas tak lépe.
        try {
            props.setValue(null);
        } catch (e) {
            // swallow
        }
        try {
            props.invokeOnValueChanged(null);
        } catch (e) {
            // swallow
        }
        props.setOpenDialog(false);
    }

    return (
        <>
            <MuiModal keepMounted={false} open={props.openDialog} onClose={() => closeDialog()} title={t('VlastniMisto.titulek')}>
                <Form<FormCustomPlace> data={formData} localization={t('FormLocalization', {returnObjects: true})} disableKeyEvents>
                    <Grid container spacing={3}>
                        <Grid item lg={4} sm={4} xs={12}>
                            <FormField setDom={setMpzDom} title={t('VlastniMisto.mpz')} name={'mpz'} type={FormInputType.Text} required={true}
                                       validate={(data: any, value: any) => {
                                           if (value?.trim().length == 0) {
                                               return [FieldError.Create(t('FormLocalization.FieldMessages.FieldIsRequired'), true, 'mpz')];
                                           }
                                           return null;
                                       }} textFieldProps={{inputProps: {maxLength: 3}}}/>
                        </Grid>
                        <Grid item lg={4} sm={4} xs={12}>
                            <FormField title={t('VlastniMisto.psc')} name={'psc'} type={FormInputType.Text} required={true} validate={(data: any, value: any) => {
                                if (value?.trim().length == 0) {
                                    return [FieldError.Create(t('FormLocalization.FieldMessages.FieldIsRequired'), true, 'psc')];
                                }
                                return null;
                            }} textFieldProps={{inputProps: {maxLength: 7}}}/>
                        </Grid>
                        <Grid item lg={4} sm={4} xs={12}>
                            <FormField title={t('VlastniMisto.mesto')} name={'mesto'} type={FormInputType.Text} required={true} validate={(data: any, value: any) => {
                                if (value?.trim().length == 0) {
                                    return [FieldError.Create(t('FormLocalization.FieldMessages.FieldIsRequired'), true, 'mesto')];
                                }
                                return null;
                            }} textFieldProps={{inputProps: {maxLength: 30}}}/>
                        </Grid>
                    </Grid>
                    {testSearch?.length > 0 &&
                        <Grid container spacing={3}>
                            <Grid item lg={12} sm={12} xs={12}>
                                {testSearch[0].display_name} [{testSearch[0].lon},{testSearch[0].lat}]
                            </Grid>
                        </Grid>
                    }
                    {testSearch?.length == 0 &&
                        <Grid container spacing={3}>
                            <Grid item lg={12} sm={12} xs={12}>
                                {props.onlyValidLocationIsAllowed ? t('Here.CustomRoute.Dial.NelzePridatDoKilometrovniku') : t('VlastniMisto.notFound')}
                            </Grid>
                        </Grid>
                    }
                    <Loading show={placeLoading} fullscreen />
                    <div className={'modal-buttons'} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '0.5rem'}}>
                        <Button type={'button'} variant="contained" color="primary" onClick={() => {
                            check(true);
                        }} style={{marginRight: '8px'}}>
                            {t('Buttons.Check')}
                        </Button>
                        <FormCustomPlaceButton
                        	handleForm={handleForm}
                        	setOpenDialog={props.setOpenDialog}
                            check={check}
                        	disabled={props.onlyValidLocationIsAllowed && (testSearch?.length == 0 || testSearch == null)}
                            onlyValidLocationIsAllowed={props.onlyValidLocationIsAllowed}
                            setPlaceLoading={setPlaceLoading}
                        />
                    </div>
                </Form>
            </MuiModal>
        </>
    );

}

export type FormCustomPlaceButton = {
    handleForm: () => void,
    setOpenDialog: (openDialog: boolean) => void,
    disabled?: boolean,
    check?: (displayPlaceLoading: boolean) => Promise<OsmPlace[]>,
    onlyValidLocationIsAllowed?: boolean,
    setPlaceLoading: React.Dispatch<React.SetStateAction<boolean>>,
}

export function FormCustomPlaceButton(props: FormCustomPlaceButton) {
    const {t} = useTranslation();
    const {data, validate} = useForm<FormCustomPlace>();

    const handleForm = () => {
        props.handleForm();
        // props.setOpenDialog(false);
    }

    return (
        <Button type={'button'} variant="contained" color="primary" disabled={props.disabled} onClick={() => {
            if (validate()) {
                if (props.onlyValidLocationIsAllowed) {
                    props.setPlaceLoading(true);
                    props.check(false).then((result) => {
                        if (result != null && result.length > 0) {
                            handleForm();
                        }
                    })
                } else {
                    handleForm();
                }
            }
        }}>
            {t('Buttons.Save')}
        </Button>
    );
}

export function toUpperCaseRemoveDiacritics(osmPlaces: OsmPlace[] | []) {
    if (osmPlaces?.length > 0) {
        osmPlaces.forEach((osmPlace) => {
                osmPlace.display_name = normalizeAndToUpperCase(osmPlace.display_name);
                osmPlace.address.city = normalizeAndToUpperCase(osmPlace.address.city);
                osmPlace.address.country = removeDiacriticsAndUpperCase(osmPlace.address.country);
                osmPlace.address.country_code = removeDiacriticsAndUpperCase(osmPlace.address.country_code);
                osmPlace.address.postcode = removeDiacriticsAndUpperCase(osmPlace.address.postcode);
                osmPlace.address.state = removeDiacriticsAndUpperCase(osmPlace.address.state);
                osmPlace.address.village = normalizeAndToUpperCase(osmPlace.address.village);
                osmPlace.address.county = normalizeAndToUpperCase(osmPlace.address.county);
            },
        );
    }
}

const removeDiacriticsAndUpperCase = (str: string) => {
    return str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
};

const normalizeAndToUpperCase = (str: string) => {
    return str?.normalize('NFD').toUpperCase();
};