import {MutableRefObject, useContext, useEffect, useMemo, useRef, useState} from 'react';
import MaterialTable, {Filter, QueryResult} from "material-table";
import {FetchParams, httpEndpoint, HttpResult, useFetchArray, useFetchCustom} from "../../../common/utils/HttpUtils";
import {
    clamp,
    cloneClassObject,
    deepSearchInString,
    exist,
    isDeepEqualNullIgnored,
    isNumber,
    isStringArray,
    isStringObject,
    jsonToFormUrlEncoded,
    objectToQueryParam, objectToQueryParamDokladky,
    objectToQueryParamPrejezdy,
    ScrollableList,
    useDataStore
} from "../../../common/utils/Util";
import {Mapper} from "../../../common/utils/objectmapper/Mapper";
import * as DG from "./DataGrid.d";
import {Column, MapperProps, Props, SortField} from "./DataGrid.d";
import {DGContext} from "./DataGrid";
import {invoke} from "../../../common/utils/Invoke";
import {GenericMap} from "../../../index.d";
import {useDidMount} from "../../../common/component/hooks/SharedHooks";
import {useAppContext} from "../../context/AppContext";
import {Template} from "../../model/Template";
import _ from "lodash";
import {showSnack} from "../../../common/component/SnackContainer";
import {StompListener, useStompSubscribe, useWebsocketContext} from "../../../common/utils/Websocket";
import {LockableDto, LockDto} from "../../model/LockResult";
import {getCurrentTabId} from "../../../common/utils/unque-tab-id";
import {CrudOperationType, CrudUpdateEvent} from "../../model/CrudUpdate";
import {AutoRefreshStateHandler} from "../../AutoRefresh";
import {useTranslation} from "react-i18next";
import {useCodeBookControllerContext} from "../controller/CodeBookController";
import {useConfirmDialog} from "../ConfirmDialog";
import {useErrorTranslator} from "../../../common/utils/error-utils";
import {SimpleValueBoolean} from "../../model/SimpleValue";
import {PubSub} from 'use-pubsub-js'
import moment from "moment";
import DataStorage from "../../../common/DataStorage";
import MTableBody from "./MTBody";
import {useTableCache} from './MTCache';

type onChangeRowsPerPage = (pageSize: number) => void;
type onChangePage = (page: number) => void;
type onOrderChange = (orderBy: number, orderDirection: ("asc" | "desc")) => void;
type onFilterChange<T> = (data?:T, name?:string) => void;
type FilterType<RowData extends object> = Filter<RowData>&{index:number};
export interface Query<RowData extends object> {
    filters: FilterType<RowData>[];
    page: number;
    pageSize: number;
    search: string;
    orderBy: Column<RowData>;
    orderDirection: "asc" | "desc";
}

/**
 * Hook na ukladani a nacitani sloupcu
 * @param defaultColumns - puvodni definice sloupecku definovana na objecktu DataGrid
 * @param typ - typ (url) filtru - pro možnost uložení výchozího nastavení
 * @param filterClazz
 * @return [ColumnHeaderDefinition - usporadani sloupcu v gridu, (columns:DG.ColumnHeaderDefinition[])=>void - funkce na zmenu usporadani sloupcu v gridu]
 */
function useColumnsDefinition<T extends object, Filter>(defaultColumns:Column<T>[], typ: string, filterClazz?:{new(): Filter}):[Column<T>[], ((sourceIndex: number, destinationIndex: number) => void), (() => void), ((column: string, hidden: boolean) => void)] {
    const {getState, setState} = useContext(DGContext);
    const fns = useTemplates<Filter>(undefined, filterClazz);

    const changeOrderColumnDefinition = (sourceIndex:number, destinationIndex:number) => {
        const state = getState();
        const defaultColumnsOrder = defaultColumns.map(item => item.field);
        const currentColumns = getState()?.columnsOrder && getState()?.columnsOrder.length > 0 ?
            getState()?.columnsOrder.filter(column => defaultColumnsOrder.includes(column)) //filter out non existent columns
            : defaultColumnsOrder

        //Add missing columns in stored order at the end
        if(currentColumns.length > 0){
            defaultColumnsOrder.forEach(column => {
                if (!currentColumns.includes(column)) {
                    currentColumns.push(column);
                }
            });
        }

        const hiddenColums = [
            ...getState()?.hiddenColumns ?? [],
            ...defaultColumns.filter(item => item.hidden).map(item => item.field) ?? []
        ]
        const currentColumnsHiddenRemoved = currentColumns.filter(field => !hiddenColums.includes(field));
        const sourceIndexNew = currentColumns.indexOf(currentColumnsHiddenRemoved[sourceIndex]);
        const destinationIndexNew = currentColumns.indexOf(currentColumnsHiddenRemoved[destinationIndex]);

        const value = currentColumns[sourceIndexNew];
        currentColumns.splice(sourceIndexNew, 1);
        currentColumns.splice(destinationIndexNew, 0, value);

        setState({...state, ...{columnsOrder: currentColumns}});
        fns.processTemplateChange(typ);
    };
    const hideColumnDefinition = (column: string, hidden: boolean) => {
        const state = getState();
        const hiddenColumns = state.hiddenColumns ? [...state.hiddenColumns] : [];
        if (hidden && hiddenColumns.indexOf(column) === -1) hiddenColumns.push(column);
        if (!hidden && hiddenColumns.indexOf(column) !== -1) hiddenColumns.splice(hiddenColumns.indexOf(column), 1);
        setState({...state, ...{hiddenColumns: hiddenColumns}});
        fns.processTemplateChange(typ);
    };
    const resetColumnDefinition = () => {
        const state = getState();
        const tempState: any = _.omit({...state}, ['columnsOrder', 'hiddenColumns']);
        setState({...tempState});
        fns.processTemplateChange(typ);
    }
    const state = getState();
    const sortedColumns = state.columnsOrder;
    const tempCols = [...defaultColumns];
    const cols: Column<T>[] = [];

    if (sortedColumns && sortedColumns?.length > 0) {
        sortedColumns.forEach(columnKey => {
            const column = tempCols.find(c => c.field === columnKey);
            if (column) {
                cols.push({...column});
            }
        });
        // Add missing columns at the end
        tempCols.forEach(column => {
            if (!sortedColumns.includes(column.field)) {
                cols.push({...column});
            }
        });
    } else {
        cols.push(...tempCols)
    }
    return [cols, changeOrderColumnDefinition, resetColumnDefinition, hideColumnDefinition];
}




export type TemplatesFunctions = {
    processTemplateChange: (typ: string) =>void,
    saveTemplate: (name: string, vychozi: boolean, typ: string, id?:number)=>Promise<Template>,
    applyTemplateData: (template?:Template) => void,
    isTemplateModified: (filters: any) => boolean | null,
    getMappedFilters: (data: Template) => any,
    applyTemplate: (id?:number) => Promise<any>,
    removeTemplate: (id?: number) => void
}

export const getTemplateHodnota = (template: Template, nazev: string, typ: string) => {
    return template?.hodnoty?.find(h => h.nazev === nazev && h.typHodnoty === typ)?.hodnota
}
export const getTemplateFilters = (template: Template) => {
    const filters = template?.hodnoty?.filter(h => h.typHodnoty === 'filter');
    let data: any = {};
    if(filters) for (let i = 0; i < filters.length; i++) {
        data[filters[i].nazev] = typeof filters[i].hodnota === 'string' && !isStringArray(filters[i].hodnota) && !isStringObject(filters[i].hodnota) ?
            filters[i].hodnota :
            JSON.parse(filters[i].hodnota)
    }
    return data;
}

// eslint-disable-next-line
export function useTemplates<Filter>(filtersRef?: MutableRefObject<Filter>, filterClazz?:{new(): Filter}):TemplatesFunctions {
    const {getState, setState} = useContext(DGContext);
    const { initialTemplates, setInitialTemplates } = useAppContext();
    const initialTemplTimeout = useRef(null);
    const mapper: Mapper<Filter> | null = useMemo(()=> {
        if (filterClazz)
            return new Mapper<Filter>({constructor: filterClazz});
        return null;
    }, [filterClazz]);

    const r = {
        processTemplateChange: async (typ: string) => {
            const state = getState();
            clearTimeout(initialTemplTimeout.current);
            initialTemplTimeout.current = setTimeout(async() => {
                if(!state?.current?.name){
                    let template = await r.saveTemplate(null, false, typ, null, 'user/filter-template/initial');
                    const templateIndex = initialTemplates.findIndex(template => template?.typUlozenehoFiltru === typ);
                    initialTemplates[templateIndex] = template;
                    setInitialTemplates(initialTemplates);
                }
            }, 100)
        },
        saveTemplate: async (name: string | null, vychozi: boolean, typ: string, id?:number, endpoint?:string) => {
            const state = getState();
            const template = {...state.current};
            const columns = state.columnsOrder && state.columnsOrder.length !== 0 ? [...state.columnsOrder] : null;
            const hiddenColumns = state.hiddenColumns && state.hiddenColumns.length !== 0  ? [...state.hiddenColumns] : null
            if(state.current.filters.constructor) {
                template.filters = cloneClassObject(state.current.filters);
            }
            let notNullFilter = _.pickBy(filtersRef?.current as any ?? template.filters, _.identity);

            const filters = Object.entries(notNullFilter)?.map(([k, v]) => ({ nazev: k, hodnota: typeof v === 'string' ? v : JSON.stringify(v), typHodnoty: 'filter' }))
                .filter(f => f.hodnota !== '[]' && f.hodnota !== '{}' && f.hodnota !== '{"max":null}');
            const templ: Template = {
                nazevFiltru: name,
                typUlozenehoFiltru: typ,
                vychozi: vychozi,
                hodnoty: [
                    ...filters,
                    { nazev: 'orderDirection', hodnota: template?.orderDirection?.toString() ?? 'asc', typHodnoty: 'grid'},
                    { nazev: 'orderBy', hodnota: template?.orderBy?.toString(), typHodnoty: 'grid'},
                    { nazev: 'pageSize', hodnota: template?.pageSize?.toString() ?? '100', typHodnoty: 'grid'}
                ]
            }

            if (columns) templ.hodnoty.push({ nazev: 'columnsOrder', hodnota: JSON.stringify(columns), typHodnoty: 'grid'});
            if (hiddenColumns) templ.hodnoty.push({ nazev: 'hiddenColumns', hodnota: JSON.stringify(hiddenColumns), typHodnoty: 'grid'});

            const result = await httpEndpoint<Template>(Template, endpoint ?? `user/filter-template/${id ? id : ''}`,
                {
                    method: id ? "PUT" : "POST",
                    body: jsonToFormUrlEncoded(templ),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                    }
                });

            if (result?.errors?.errors && name != null)
                showSnack({title: result.errors.errors[0].message, severity: "error"});

            if (result.data && name != null) {
                const modifiedState = {...state, current:{
                        ...state.current,
                        filters: filtersRef.current,
                        name: !id ? result.data.nazevFiltru : state.current.name,
                        id: result.data.id,
                        vychozi: result.data.vychozi
                }};
                const newState = {...modifiedState, templateSettings: cloneClassObject(modifiedState)}
                delete newState.templateSettings.templateSettings;
                setState(newState);
            }
            return result.data;
        },
        isTemplateModified: (filters: any) : boolean | null => {
            const state = getState();
            if(state?.current?.name){
                return !(isDeepEqualNullIgnored(state?.templateSettings?.current?.filters, filters, true)
                    && isDeepEqualNullIgnored(state?.columnsOrder, state?.templateSettings?.columnsOrder, true)
                    && isDeepEqualNullIgnored(state?.hiddenColumns, state?.templateSettings?.hiddenColumns, true)
                    && isDeepEqualNullIgnored(state?.current?.orderBy, state?.templateSettings?.current?.orderBy, true)
                    && isDeepEqualNullIgnored(state?.current?.orderDirection, state?.templateSettings?.current?.orderDirection, true)
                    && isDeepEqualNullIgnored(state?.current?.pageSize, state?.templateSettings?.current?.pageSize, true)
                );
            }
            return null;
        },
        getMappedFilters: (data: Template): Filter => {
            const createFilterObject = ():Filter => filterClazz ? new filterClazz() : {} as Filter;
            let filtersTemplate = createFilterObject()
            filtersTemplate = {...filtersTemplate, ...getTemplateFilters(data)};
            if(mapper) filtersTemplate = mapper.readValue(filtersTemplate);
            return filtersTemplate;
        },
        applyTemplateData: (template?:Template) => {
            const state = getState();
            const storedTemplate = {
                filters: r.getMappedFilters(template),
                orderDirection: getTemplateHodnota(template, 'orderDirection', 'grid') || 'asc',
                orderBy: getTemplateHodnota(template,'orderBy', 'grid'),
                pageSize: Number.parseInt(getTemplateHodnota(template,'pageSize', 'grid')) || 100,
                page: 0,
                id: template.id,
                name: template.nazevFiltru,
                vychozi: template.vychozi,
            }
            const cols = getTemplateHodnota(template, 'columnsOrder', 'grid') ? {columnsOrder: JSON.parse(getTemplateHodnota(template, 'columnsOrder', 'grid'))} : {};
            const hiddenCols = getTemplateHodnota(template, 'hiddenColumns', 'grid') ? {hiddenColumns: JSON.parse(getTemplateHodnota(template, 'hiddenColumns', 'grid'))} : {};
            const modifiedState = {...state, current: cloneClassObject(storedTemplate), ...cols, ...hiddenCols};
            const newState = {...modifiedState, templateSettings: cloneClassObject(modifiedState)}
            delete newState.templateSettings.templateSettings;
            setState(newState);
        },
        applyTemplate: async (id?:number) => {
            if (!id) {
                const state = getState();
                const tempState : any = _.omit({...state}, 'columnsOrder');
                setState({...tempState});
                return;
            }
            const result = await httpEndpoint<Template>(Template, `user/filter-template/${id ? id : ''}`)
            if (result?.errors?.errors)
                showSnack({title: result.errors.errors[0].message, severity: "error"});
            if (result.data) {
                r.applyTemplateData(result.data);
            }
        },
        removeTemplate: async (id?:number) => {
            const state = getState?.();
            if (id && isNumber(id)) {
                const result = await httpEndpoint<Template>(Template, `user/filter-template/${id}`,{method: "DELETE"});
                if (result.response.status === 200 && state) {
                    const query = {...state.current, filters: {}};
                    query.name = "";
                    query.id = 0;
                    setState({...state, current: query});
                }
            }
        }
    }
    return r;
}
type QueryResultEnhanced<RowData extends object> = QueryResult<RowData> & {origin: RowData[], overallCount:number, loaded:boolean, totalInfoCount: number, timestamp: string}
function useDoQuery<RowData extends object, Filter>({setLoading, endpoint, clazz, mapper, createFilterDataObject, defaultQueryParameters, tableRef, bodyRef, rowDataChanged, lockSupport, arState, ...props}:DG.FilterProps<Filter>&Props<RowData>&MapperProps<Filter>&{createFilterDataObject():Filter, onFilterChanged?(filter:Filter):void, setLoading:(status:boolean)=>void, tableRef: MutableRefObject<MaterialTable<any>>, bodyRef: MutableRefObject<MTableBody>, arState: MutableRefObject<AutoRefreshStateHandler>, rowDataChanged: MutableRefObject<boolean>}, {columns}:{columns:Column<RowData>[], updateColumnsOrder:(sourceIndex:number, destinationIndex:number)=>void}, typ: string, hasLayoutFilter?: boolean):[
    Filter,
    QueryResultEnhanced<RowData>,
    onChangeRowsPerPage,
    onChangePage,
    onOrderChange,
    onFilterChange<Filter>,
    (extraHeaders: GenericMap)=>Promise<any>,
    ()=>Promise<HttpResult<ScrollableList<RowData>>>,
    ()=>Promise<number>,
    (status:boolean)=>void,
    ()=>Promise<number>
] {
    const overallCount = useRef(true);
    const isMounted = useRef(false);
    const {checkDataChanged, user, asyncClearFunctions} = useAppContext();
    const [data, setData] = useState<QueryResultEnhanced<RowData>>({data: [], origin:[], totalCount: 0, page: 0, overallCount:0, loaded:false, totalInfoCount: 0, timestamp: undefined});
    const {getState, setState, table} = useContext(DGContext);
    const fetching = useRef<boolean>(false);
    const {service: webSocketService} = useWebsocketContext();
    const fetchLockConfig = useMemo<FetchParams<void>>(() => ({endpoint: `${endpoint}/lock`}), [endpoint])
    const {fetch: fetchLocks} = useFetchArray<LockableDto, void>(LockableDto,  fetchLockConfig);
    const cbcContext = useCodeBookControllerContext();
    const {t} = useTranslation();
    const [showConfirm] = useConfirmDialog()
    const et = useErrorTranslator();
    const {fetch: templateFetch} = useFetchCustom<any, string>({endpoint: (arg) => `user/filter-template?vychozi=true&typUlozenehoFiltru=${arg}`})
    const fns = useTemplates<Filter>(undefined, props.filterClazz);
    const [templateApplied, setTemplateApplied] = useDataStore(SimpleValueBoolean, `${typ}_applied`, true, "session");
    const { initialTemplates} = useAppContext();
    const { getTableCache, setTableCache } = useTableCache(props.id || endpoint, props?.cache?.group);

    useStompSubscribe<LockDto>( lockSupport?.enabled ? `/provozovna/${user.provozovna.kod}/lock/${lockSupport.stompPath}` : undefined, {callback:(lock) => {
        if(isMounted.current && ((lock.uzivatel.login!==user.login || getCurrentTabId()!==lock.tabId) ||
            //@ts-ignore
            (lock.uzivatel.login===user.login && getCurrentTabId()===lock.tabId && lock.locked===false && !exist(tableRef.current?.dataManager?.lastEditingRow)))) {
            data.data.forEach((r) => {
                //@ts-ignore
                if (r.id === lock.id) {
                    //@ts-ignore
                    r.tabId = lock.locked ? lock.tabId : undefined;
                    //@ts-ignore
                    r.lockUserInfo = lock.locked ? lock.uzivatel : undefined;
                }
            });
            tableRef.current.forceUpdate();
        } else if(isMounted.current && lock.uzivatel.login===user.login && getCurrentTabId()===lock.tabId && lock.locked===false) {
            //@ts-ignore
            if(tableRef.current.dataManager.lastEditingRow?.id===lock.id && tableRef.current.dataManager.lastEditingRow?.lockUserInfo) {
                //@ts-ignore
                tableRef.current.dataManager.changeRowEditing(tableRef.current.dataManager.lastEditingRow, undefined)
                tableRef.current.forceUpdate();
                setData(_.clone(data));
                showSnack({title: t("Errors.LockLost"), severity:"warning", duration: 15000});
            }
        }
    }});

    const count = async () => {
        const result = await fetch();
        return result?.data?.total;
    };

    const modifiedCount = async (timestamp?: string) => {
        const result = await fetch({timestamp: timestamp ?? data.timestamp}, {calculateModifiedCount: true}, true);
        return result?.data?.total;
    };

    /**
     * @description Funkcia ktora patchuje okoli na 0 pokial ziadne okoli nebolo poskytnute
     * na zaklade poziadavky z #4476
     * @param filter
     * @return filter
     */
    function patchOkoli (filter: any) {
        if (_.has(filter, 'okoliOdkud.radius') && _.has(filter, 'okoliOdkud.osmPlace') && _.isNull(filter.okoliOdkud.radius)) {
            filter.okoliOdkud.radius = 0
        }
        if (_.has(filter, 'okoliKam.radius') && _.has(filter, 'okoliKam.osmPlace') && _.isNull(filter.okoliKam.radius)) {
            filter.okoliKam.radius = 0
        }
        return filter
    }

    const fetch = async (additionalParams: GenericMap = {}, headers: GenericMap = {}, background: boolean = false) => {
        const state = getState();
        const query = state.current;
        let params = new Array<string>();
        let map: GenericMap;
        let defaultQueryParams: GenericMap = {};


        if (mapper) {
			map = mapper.writeValueAsJson(query.filters);
            // map = patchOkoli(mapper.writeValueAsJson(query.filters));
            if (defaultQueryParameters) {
                if(exist(props.defaultQueryParametersClz)) {
                    const mapperGeneric = new Mapper({constructor: props.defaultQueryParametersClz})
                    defaultQueryParams = mapperGeneric.writeValueAsJson(defaultQueryParameters);
                } else {
                    defaultQueryParams = defaultQueryParameters
                }
            }
        } else {
            map = query.filters as GenericMap;
            defaultQueryParams = defaultQueryParameters
        }
        map = {..._.omitBy(defaultQueryParams, _.isNil), ..._.omitBy(map, _.isNil), ..._.omitBy(additionalParams, _.isNil)}
        if (map?.itemAge?.age === -1) {
           if (props.lastBrowsedDateKey) {
                const date = DataStorage.get(props.lastBrowsedDateKey);
                map.itemAge.date = date ?? moment().toISOStringWithMillis();
           }
        }

        //column filter
        if (endpoint === "user/preprava-prejezd" || endpoint === "user/vozidlo-prejezd") {
            // kontrola, zda jsou prejezdOdWaypoint nebo prejezdKamWaypoint null/undefined
            if (!map.prejezdOdWaypoint && query.filters.prejezdOdWaypoint) {
                map.prejezdOdWaypoint = query.filters.prejezdOdWaypoint;
            }

            if (!map.prejezdKamWaypoint && query.filters.prejezdKamWaypoint) {
                map.prejezdKamWaypoint = query.filters.prejezdKamWaypoint;
            }

            params.push(objectToQueryParamPrejezdy(map));
        } else if (endpoint === "user/dokladka") {
            params.push(objectToQueryParamDokladky(map));
        } else {
            params.push(objectToQueryParam(map));
        }

        // Priority sorting
        let orderBy = query.orderBy;
        let orderDirection = query.orderDirection;
        if (query.prioritySort) {
            orderBy = query.prioritySort.orderBy;
            orderDirection = query.prioritySort.orderDirection;
        }

        //order by
        // Kontrola a zpracování parametrů řazení pro různé typy filtrů:
        // 1. Pokud je `orderBy` nastaveno na "odkud" a jsou k dispozici souřadnice (longitude a latitude) z filtru "Okolí Odkud",
        //    přidají se do parametrů request tyto informace: název řazení (name), směr řazení (dir),
        //    a souřadnice zadaného místa (longitude, latitude). (vytvořeno v rámci tasku #4670 - PROHLÍŽENÍ + ARCHIV - OKOLI ODKUD A OKOLI KAM - řazení)
        // 2. Pokud je `orderBy` nastaveno na "kam" a jsou k dispozici souřadnice (longitude a latitude) z filtru "Okolí Kam",
        //    přidají se obdobné parametry jako v případě "Okolí Odkud". (vytvořeno v rámci tasku #4670 - PROHLÍŽENÍ + ARCHIV - OKOLI ODKUD A OKOLI KAM - řazení)
        // 3. Pokud není ani "Okolí Odkud" ani "Okolí Kam" použito, ale je nastaven obecný parametr `orderBy`,
        //    přidá se název řazení a směr bez dalších specifických parametrů.
        //
        // Parametry, které jsou navíc oproti zakladním (name a dir), musí být přidány přes mapu `additionalParams`,
        // aby tyto parametry byly zpracovány na backendu.
        if (
            orderBy &&
            orderBy === "odkud" &&
            query.filters?.okoliOdkud?.koordinat?.coordinates[0] &&
            query.filters?.okoliOdkud?.koordinat?.coordinates[1]
        ) {
            params.push(
                `${encodeURI('order[0].name')}=${encodeURI(orderBy)}` +
                `&${encodeURI('order[0].dir')}=${orderDirection}` +
                `&${encodeURI('order[0].additionalParams.filterOkoliOdkudKoordinatLongitude')}=${encodeURI(query.filters.okoliOdkud.koordinat.coordinates[0])}` +
                `&${encodeURI('order[0].additionalParams.filterOkoliOdkudKoordinatLatitude')}=${encodeURI(query.filters.okoliOdkud.koordinat.coordinates[1])}`
            );
        } else if (
            orderBy &&
            orderBy === "kam" &&
            query.filters?.okoliKam?.koordinat?.coordinates[0] &&
            query.filters?.okoliKam?.koordinat?.coordinates[1]
        ) {
            params.push(
                `${encodeURI('order[0].name')}=${encodeURI(orderBy)}` +
                `&${encodeURI('order[0].dir')}=${orderDirection}` +
                `&${encodeURI('order[0].additionalParams.filterOkoliKamKoordinatLongitude')}=${encodeURI(query.filters.okoliKam.koordinat.coordinates[0])}` +
                `&${encodeURI('order[0].additionalParams.filterOkoliKamKoordinatLatitude')}=${encodeURI(query.filters.okoliKam.koordinat.coordinates[1])}`
            );
        } else if (query.orderBy) {
            params.push(`${encodeURI('order[0].name')}=${encodeURI(orderBy)}&${encodeURI('order[0].dir')}=${orderDirection}`);
        }

        //pagination
        if(query.page !== null && query.pageSize !== null) {
            params.push(`page=${query.page + 1}&pageSize=${query.pageSize}`);
        }
        if(overallCount.current) {
            params.push(`overallCount=true`);
        }
        let url = `${endpoint}?${params.filter(i => i!=="").join("&")}`;
        console.log("params", params);
        console.log("url", url);
        if(!background) fetching.current = true;
        try {
            return httpEndpoint<ScrollableList<RowData>>(ScrollableList, url, {headers: headers});
        } finally {
            if(!background) fetching.current = false;
        }
    };

    const scrollTimeout = useRef(null);
    const onScroll = (e: Event) => {
        clearTimeout(scrollTimeout.current);
        scrollTimeout.current = setTimeout(() => {
            let tableCache = getTableCache();
            tableCache.tableScroll = {x: (e.target as HTMLElement).scrollLeft, y: (e.target as HTMLElement).scrollTop}
            setTableCache(tableCache);
        }, 10);
    }

    useEffect(() => {
        if(!props?.cache?.disabled){
            const tableElement = bodyRef?.current?.ref?.current?.parentNode?.parentNode?.parentNode as HTMLDivElement;
            let tableCache = getTableCache();
            setTimeout(() => {
                tableElement?.scrollTo(tableCache.tableScroll?.x ?? 0, tableCache.tableScroll?.y ?? 0);
            }, 1);

            tableElement.addEventListener("scroll", onScroll);
            return ()=>{
                tableElement.removeEventListener("scroll", onScroll);
            }
        }
    });

    const doQuery = async (setData:(data:QueryResultEnhanced<RowData>)=>void, loaded=false, extraHeaders: GenericMap = {}, crudUpdate?: CrudUpdateEvent, force?:boolean, loadFromCache?: boolean) => {
        const emptyData:QueryResultEnhanced<RowData> = {data:[], origin:[], page:0, totalCount: 0, overallCount:0, loaded, totalInfoCount: 0, timestamp: undefined};
        try {
            if (props.lazyLoading && !loaded) {
                setData(emptyData);
            } else {
                // @ts-ignore
                if (crudUpdate?.stompProcessingOnly || (props.stomp?.allowStompUiUpdates && !force && (exist(tableRef.current?.dataManager?.lastEditingRow) || (!exist(tableRef.current?.dataManager?.lastEditingRow) && rowDataChanged.current) || (!(arState?.current?.enabled ?? true) && exist(data.timestamp))))) {
                    if (exist(crudUpdate)) {
                        if (crudUpdate.crudOperationType === CrudOperationType.REMOVE && (arState.current?.enabled !== true || crudUpdate?.stompProcessingOnly)) {
                            data.data.forEach((d, index) => {
                                // @ts-ignore
                                if (d.id === crudUpdate?.entity?.id) {
                                    data.data[index] = new Mapper<RowData>({constructor: clazz}).readValue(d);
                                    // @ts-ignore
                                    data.data[index]._deleted = true;
                                    // @ts-ignore
                                    data.data[index].tableData = {id: data.data[index].id, checked: false, disabled: true}
                                    tableRef.current.forceUpdate();
                                }
                            });
                        } else if (!crudUpdate?.stompProcessingOnly &&
                            ((crudUpdate.crudOperationType === CrudOperationType.CREATE && arState.current?.enabled === true) ||
                                (crudUpdate.crudOperationType === CrudOperationType.REMOVE && arState.current?.enabled === true))) {
                            const result = await fetch({}, extraHeaders, true);

                            if (result.response.status === 200) {
                                const totalCount = clamp(result.data.total ?? 0, 0, props.maxCount ?? result.data.total ?? 0)
                                let limit = null;
                                if (props.maxCount) {
                                    const length = (result.data.page * result.data.objectsPerPage) - props.maxCount
                                    limit = clamp(length, 0, result.data.objectsPerPage)
                                }

                                const newData = new Mapper<RowData>({constructor: clazz}).readValueAsArray(!limit ? result.data.list : result.data.list.slice(0, limit));
                                newData.forEach((v, i) => {
                                    // @ts-ignore
                                    data.data[i] = {tableData: {id: v.id}, ...data.data[i], _deleted: undefined, ...v}
                                })
                                while (data.data.length > newData.length)
                                    data.data.pop()

                                data.page = result.data.page - 1;
                                data.totalCount = totalCount;
                                data.totalInfoCount = result.data.total;
                                data.overallCount = !overallCount.current ? data.overallCount : result.data.overallCount;
                                data.timestamp = result.data.timestamp;
                                data.loaded = true
                            }

                            tableRef.current.forceUpdate();
                        } else if (crudUpdate.crudOperationType === CrudOperationType.UPDATE && (!props.stomp?.allowStompUiUpdates || crudUpdate.stompProcessingOnly)) {
                            table.current?.onResetLastTabIndex();
                            data.data.forEach((d, index) => {
                                // @ts-ignore
                                if (d.id === crudUpdate?.entity?.id) {
                                    //merge data
                                    const original = {...data.data[index], ...(new Mapper<RowData>({constructor: props.stomp?.clazz ?? clazz}).readValue(crudUpdate.entity))} as RowData;
                                    const mapped = new Mapper<RowData>({constructor: clazz}).readValue(original);
                                    // @ts-ignore
                                    mapped.tableData = data.data[index].tableData;
                                    if (props.watchChanges) {
                                        // @ts-ignore
                                        mapped.changed = true;
                                        // @ts-ignore
                                        mapped.origin = !exist(data.data[index].origin) ? data.origin[index] : data.data[index].origin;
                                    }
                                    // @ts-ignore
                                    data.data[index] = mapped;
                                    // @ts-ignore
                                    data.data[index].tableData.checked = false;
                                    // @ts-ignore
                                    data.data[index].tableData.disabled = true;
                                    tableRef.current.forceUpdate();
                                }
                            });
                        }
                    }
                } else {
                    let tableCache = getTableCache();
                    if(!(!props?.cache?.disabled && loadFromCache && tableCache.data != null)) setLoading(true);

                    const setResultData = (data: any) => {
                        if (data.pages && data.page > data.pages) {
                            onChangePage(data.pages - 1);
                        } else {
                            const totalCount = clamp(data.total ?? 0, 0, props.maxCount ?? data.total ?? 0)
                            let limit = null;
                            if (props.maxCount) {
                                const length = (data.page * data.objectsPerPage) - props.maxCount
                                limit = clamp(length, 0, data.objectsPerPage)
                            }
                            const tempData = new Mapper<RowData>({constructor: clazz}).readValueAsArray(!limit ? data.list : data.list.slice(0, limit));
                            //save filter only when query is correct
                            setData({
                                data: tempData,
                                origin: props.watchChanges ? _.clone(tempData) : [],
                                page: data.page - 1,
                                totalCount: totalCount,
                                totalInfoCount: data.total,
                                overallCount: data.overallCount,
                                timestamp: data.timestamp,
                                loaded
                            });

                            arState.current.resetCounter();
                        }
                    }

                    if (isMounted.current) {
                        if(!props?.cache?.disabled && loadFromCache && tableCache.data != null) {
                            setResultData(tableCache.data);
                        } else {
                            const result = await fetch({}, extraHeaders);
                            if(result.response.status === 200){
                                if(!overallCount.current || !result.data.overallCount) result.data.overallCount = data.overallCount;
                                setTableCache({data: result.data});
                                setResultData(result.data);
                            } else {
                                setData(emptyData);
                            }
                        }
                        if (cbcContext && !cbcContext.preventClearFunctions) await asyncClearFunctions();
                    }
                }
            }
        }
        catch(e: any) {
            if(e?.response?.status===429) {
                showConfirm({
                    title: "",
                    body: t("Errors.SystemOverload"),
                    hideCancel: true
                });
            } else {
                showSnack({title: et(e), severity: "error"});
            }
        }
        finally {
            setLoading(false);
            if(!props.lazyLoading || loaded)
            overallCount.current=false;
        }
    };

    const onChangeRowsPerPage:onChangeRowsPerPage = (pageSize) => checkDataChanged(() => {
        const state = getState();
        setState({...state, current:{...state.current, pageSize: pageSize, page:0}});
        fns.processTemplateChange(endpoint);
        invoke(doQuery, setData, data.loaded, undefined, undefined, true);
    });

    const onChangePage:onChangePage = (page) => checkDataChanged(() => {
        const state = getState();
        setState({...state, current:{...state.current, page: page}});
        invoke(doQuery, setData, data.loaded, undefined, undefined, true);
    });
    const onOrderChange:onOrderChange = (orderBy, orderDirection) => checkDataChanged(() => {
        const state = getState();
        //jako kod tyhle funkce je dost divnej ale funguje to
        columns.forEach(c=>c.defaultSort = null);
        const column = columns[orderBy];
        if(column) column.defaultSort = orderDirection;
        // Zablokování orderBy=null
        const previousOrderBy = state.current?.orderBy ?? null;
        if (!exist(orderDirection) || orderDirection.length === 0) orderDirection = "asc";

        setState({...state, current:{...state.current, orderBy: (column?.sortBy ?? column?.field) ?? previousOrderBy, orderDirection: orderDirection, prioritySort: null}});
        fns.processTemplateChange(endpoint);
        invoke(doQuery, setData, data.loaded, undefined, undefined, true);
    });

    const onFilterChanged:onFilterChange<Filter> = (data, name?:string) => {
        const state = getState();
        const initialTemplate = initialTemplates.find(obj => obj?.typUlozenehoFiltru === endpoint);
        const getValue = (name: string) =>
            initialTemplate?.hodnoty?.find(h => h.nazev === name && h.typHodnoty === 'grid')?.hodnota;
        const hiddenColumns = JSON.parse(getValue('hiddenColumns') ?? '[]');
        const columnsOrder = JSON.parse(getValue('columnsOrder') ?? '[]');
        const filters = data||createFilterDataObject();
        // Priority sorting
        let prioritySort:SortField = null;
        //jako kod tyhle funkce je dost divnej ale funguje to
        if (props.prioritySort && filters) {
            let existPrioritySort = false;
            for (let i = 0; i < props.prioritySort.length; i++) {
                existPrioritySort = deepSearchInString(filters, props.prioritySort[i].field, (k: any, v: any) => exist(v)) !== null;
                if(existPrioritySort) {
                    prioritySort = props.prioritySort[i]
                    break;
                }
            }
        }
        const storedName = name ? name : state.current?.name

        const sortField = () => {
            if (prioritySort) return prioritySort.orderBy;

            if (!props.prioritySort?.some(ps => ps.orderBy === state.current?.orderBy)) return state.current?.orderBy;

            return props.defaultSort?.field ?? null;
        }

        const sortDirection = () => {
            if (prioritySort) return prioritySort.orderDirection;

            if (!props.prioritySort?.some(ps => ps.orderBy === state.current?.orderBy)) return state.current.orderDirection

            return props.defaultSort?.orderDirection ?? "asc";
        }

        setState({...state,
                ...(!data ? {hiddenColumns: hiddenColumns, columnsOrder: columnsOrder} : {}),
                current:{...state.current,
                orderBy: sortField(),
                orderDirection: sortDirection(),
                page:0,
                filters: filters,
                name: !data ? null : storedName,
                id: !data ? null : state.current?.id,
                prioritySort: prioritySort,
                vychozi: state?.current?.vychozi}});

        fns.processTemplateChange(endpoint);
        if(props.onFilterChanged)
            props.onFilterChanged(data);
        invoke(doQuery, setData, props.lazyLoading ? Boolean(data) : true, undefined, undefined, true);
    };
    const refresh = (extraHeaders:GenericMap = {}, update?:CrudUpdateEvent, force?:boolean): Promise<any> => {
        if(fetching.current) {
            console.log("refresh ignored, currently fetching");
        } else {
            console.log("refreshing", extraHeaders, update, force);
            overallCount.current = true;
            return doQuery(setData, data.loaded, extraHeaders, update, force);
        }
    };
    useDidMount(() => {

         const load = async() => {
            try {
                if (hasLayoutFilter && templateApplied()?.value !== true) {
                    setLoading(true);
                    const result = await templateFetch({arg: typ});
                    if (exist(result.list) && result.list.length > 0) {
                        await fns.applyTemplateData(result.list[0]);
                        PubSub.publish('defaultTemplateSet', true);
                    }
                    setTemplateApplied(SimpleValueBoolean.of(true));
                }

                await doQuery(setData, data.loaded, undefined, undefined, undefined, true);
            } catch {
                showSnack({title: t("DialDefaults.ServerTimeoutMessage"), severity:"error"});
                setLoading(false);
            }
        }
        isMounted.current = true;
        if(!props.lazyLoading) {
            invoke(load);
        }

        return ()=>{
            isMounted.current = false;
        }
    });

    //reload locks when websocket is reconnected (for grids where lock support is enabled)
    const connectionListener: StompListener = useMemo(() => ({
        onWebsocketConnected(s) {
            //apply this logic only for lock support, timestamp is as indication that data was loaded
            if(lockSupport?.enabled && data.timestamp) {
                arState.current.refresh(undefined);
                // @ts-ignore
                fetchLocks({params: {id: data.data.map(d => d.id)}}).then((locks) => {
                    data.data.forEach((d, index) => {
                        // @ts-ignore
                        if(d.id===locks[index].id) {
                            // @ts-ignore
                            d.tabId = locks[index].tabId;
                            // @ts-ignore
                            d.lockUserInfo = locks[index].lockUserInfo;
                        }
                    });
                    tableRef.current.forceUpdate();
                });
            }
        },
        onWebsocketConnecting(s) {},
        onWebsocketDisconnected(s) {},
        onWebsocketReconnectFailed(s) {}
        // eslint-disable-next-line
    }), [data, arState, lockSupport, fetchLocks, fetchLockConfig, tableRef]);

    useEffect(() => {
        webSocketService.addConnectionListener(connectionListener);
        return () => webSocketService.removeConnectionListener(connectionListener);
        // eslint-disable-next-line
    }, [connectionListener])

    return [cloneClassObject(getState().current.filters), data, onChangeRowsPerPage, onChangePage, onOrderChange, onFilterChanged, refresh, fetch, count, setLoading, modifiedCount];
}

export type MaterialTableOverridesType<Filter, RowData extends object> = {
    filters:Filter,
    columns:Column<RowData>[],
    updateColumnsOrder:(sourceIndex:number, destinationIndex:number)=>void,
    data:QueryResultEnhanced<RowData>,
    onChangeRowsPerPage:onChangeRowsPerPage,
    onChangePage:onChangePage,
    onOrderChange:onOrderChange,
    onFilterChanged:onFilterChange<Filter>,
    refresh:(extraHeaders?: GenericMap, data?:CrudUpdateEvent, force?:boolean)=>Promise<any>,
    fetch:()=>Promise<HttpResult<ScrollableList<RowData>>>,
    count:()=>Promise<number>
    setLoading?:(status:boolean)=>void
    modifiedCount:(timestamp?: string)=>Promise<number>
    resetColumns:() => void
    hideColumn:(column: string, hidden: boolean)=>void,
}

type MaterialTableProps<RowData extends object, Filter> = {
    createFilterDataObject():any
    onFilterChanged?: (filter:Filter) => void
    setLoading:(status:boolean)=>void
    tableRef: MutableRefObject<MaterialTable<any>>
    bodyRef: MutableRefObject<MTableBody>
    rowDataChanged: MutableRefObject<boolean>, arState: MutableRefObject<AutoRefreshStateHandler>
    typ: string
    hasLayoutFilter?: boolean
}&Props<RowData>&MapperProps<Filter>

export function useMaterialTableOverrides<RowData extends object, Filter>(props: DG.FilterProps<Filter> & MaterialTableProps<RowData, Filter>) : MaterialTableOverridesType<Filter, RowData> {
    const [columns, updateColumnsOrder, resetColumns, hideColumn] = useColumnsDefinition<RowData, Filter>(props.columns, props.typ, props.filterClazz);
    const [filters, data, onChangeRowsPerPage, onChangePage, onOrderChange, onFilterChanged, refresh, fetch, count, setLoading, modifiedCount] = useDoQuery<RowData, Filter>(props, {columns, updateColumnsOrder}, props.typ, props.hasLayoutFilter);
    return {filters, columns, updateColumnsOrder, data, onChangeRowsPerPage, onChangePage, onOrderChange, onFilterChanged, refresh, fetch, count, setLoading, modifiedCount, resetColumns, hideColumn};
}
