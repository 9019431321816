import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { Button, CircularProgress, Grid, Popper, TextField } from '@material-ui/core';
import {CustomFieldComponentProps} from "./FormFieldInterface";
import {OsmPlace, OsmPlaceClz, search} from "../map/Nominatim";
import {useNetworkActionValue, useSafeTimeout, useThrottlingLogic} from "../hooks/SharedHooks";
import {useTranslation} from "react-i18next";
import {exist} from "../../utils/Util";
import {useFocusable, useFocusTypeDetection} from "./FocusHook";
import {useHighlight} from "./FormMuiAutocomplete";
import {DynamicFontSize} from "../DynamicFontSize";
import {invoke} from "../../utils/Invoke";
import {useFetchArray, useFetchCustom} from "../../utils/HttpUtils";
import {PopperProps} from "@material-ui/core/Popper/Popper";
import {LatLng} from "leaflet";
import {useAppContext} from "../../../web/context/AppContext";
import {countryCodesForRequest} from "../../logic/permissions-logic";
import {Region} from "../../../web/model/CommonTypes";
import {useMpzResolver} from "../../utils/MpzUtils";
import { FormCustomPlaceDialog, toUpperCaseRemoveDiacritics } from './FormCustomPlaceDialog';
import { useFormSafe } from '../../../web/raal_components/form/Form';
import { Vozidlo } from '../../../web/model/Vozidlo';
import { Preprava } from '../../../web/model/Preprava';
import { DGContext } from '../../../web/raal_components/grid/DataGrid';

export type FormNominatimOptions = {
    onChange?:(osmPlace:OsmPlace)=>void,
    onBlur?:(osmPlace:OsmPlace, invokeOnValueChange:(newValue?:OsmPlace)=>void)=>boolean,
    enableCustomValue?:boolean,
    disableClearable?:boolean,
    autofillValue?: boolean,
    resolveMpz?:(isoCode:string)=>string,
    maxLength?: number,
    selectAll?: boolean,
    checkLicense?: boolean
    countryCodeForCompare?: () => boolean
    enableOSRM?: boolean
    checkUserProvozovna?: boolean
	enableCustomPlace?: boolean,
    onlyValidLocationIsAllowed?: boolean
}
export type FormNominatimProps<T> = {
    countryCodeForCompare?: () => boolean
}&CustomFieldComponentProps<T, FormNominatimOptions>

export function resolveCity(option:OsmPlace, crop: boolean = true):string {
    const a = (option?.address?.city ??
        option?.address?.village ??
        option?.address?.suburb ??
        option?.address?.county?.replace("okres", "").trim() ??
        option.address?.country ?? "")

    return crop ? a.substring(0, 50) : a;
}

export function resolvePostCode(postCode:string) {
    return postCode?.replace(/\s/g, "")??"*";
}


export const formatForRaalUsers = (option:OsmPlace, resolveMpz:(isoCode:string)=>string, crop: boolean = true) => {
    if(!option) {
        return null;
    }
    if(option.isCustomValue) {
        // TODO kontrola na array pouze docasne - opravit jinde
        if (Array.isArray(option.display_name)) {
            return "";
        }
        return option.display_name ?? "";
    }
    return option.address && `${resolveMpz ? resolveMpz(option?.address?.country_code) : option?.address?.country_code}:${resolvePostCode(option.address?.postcode)}:${resolveCity(option, crop)}`;
};

const NomPopper = (props: PopperProps) => {
    return <Popper {...props} placement="bottom-start" style={{maxWidth: 400}}/>;
}

export function FormNominatim(props:FormNominatimProps<string>) {
    const {t} = useTranslation();
    const poptions = typeof props.options === 'function' ? props.options() : props.options;
    const {fetch} = useFetchArray<OsmPlaceClz>(OsmPlaceClz, {endpoint: () => `kilom/search`});
    const {fetch: fetchNearest} = useFetchCustom<OsmPlace, LatLng>({endpoint: params => `kilom/nearest?lon=${params.lng}&lat=${params.lat}`})
    const {protectedCall} = useNetworkActionValue<OsmPlace>();
    const createValue = (v:any) => ({display_name:v, isCustomValue:true} as OsmPlace);
    const [value, setValue] = useState(props.value ? ({display_name:props.value, isCustomValue:true, isInitialValue:true}) as OsmPlace : null);
    const [options, setOptions] = useState([] as OsmPlace[]);
    const [loading, setLoading] = useState(false);
    // const [gpsLoading, setGpsLoading] = useState(false);
    const [setTimeout] = useSafeTimeout(500);
    const input = useRef<HTMLInputElement>();
    const selectedAll = useRef(false);
    const {/*setCurrentPosition, getCurrentPosition,*/ user} = useAppContext();
    useFocusable(input, props);
    const {onMouseDown, onMouseUp, userClick} = useFocusTypeDetection();
    const filterOptions = (options1:OsmPlace[]) => options1 ?? [];
    const [hprops, htarget] = useHighlight(options);
    const reqNo = useRef(0);
    const [resolveMpz] = useMpzResolver();
	const [openFromDialog, setOpenFromDialog] = useState(false);
	const [selectedFromItem, setSelectedFromItem] = useState(null);
	const [showAddButton, setShowAddButton] = useState(false);
	const form = useFormSafe<Vozidlo | Preprava>();
	const {table} = useContext(DGContext);

    useEffect(()=>{if(props.focused){
        if (poptions?.selectAll && input.current?.value && !selectedAll.current) {
            input.current?.select();
            selectedAll.current = true;
        }
    }});

    useEffect(() => {
        setValue(({display_name:props.value, isCustomValue:true, isInitialValue:true}) as OsmPlace)
    }, [props.value]);

    const invokeOnValueChanged = useCallback((_value:OsmPlace) => {
        if(props.onValueChanged) {
            const v = formatForRaalUsers(_value, poptions?.resolveMpz??resolveMpz);
            props.onValueChanged(v?.trim() === ""? null : v);
        }
    }, [props, poptions, resolveMpz])

    const setValueInternal = useCallback((value:OsmPlace) => {
        if(poptions?.onChange) {
            poptions.onChange(value);
        }
        invokeOnValueChanged(value);
        setValue({...createValue(formatForRaalUsers(value, poptions?.resolveMpz??resolveMpz)), isOption: exist(value.isOption) ? value.isOption : true});
    }, [props.value, setValue, invokeOnValueChanged, poptions, resolveMpz])

    const scheduleSearch = useCallback(async (valueForSearch:string | number[], useGeocoding?: boolean, thisReqNo?: number) => {
        setLoading(true);
        try {
            if (!useGeocoding && typeof valueForSearch === 'string') {
                const value = valueForSearch?.trimStart() ?? "";
                let countryCodes: string;
                const reg = !poptions.checkUserProvozovna ? user?.region : user?.provozovna?.typ;
                if (reg === Region.EU) {
                    if ((exist(props.countryCodeForCompare) && props.countryCodeForCompare()) ||
                        (exist(poptions.countryCodeForCompare) && poptions.countryCodeForCompare())){
                        countryCodes = countryCodesForRequest(user, poptions.checkUserProvozovna, true);
                    } else {
                        countryCodes = countryCodesForRequest(user, poptions.checkUserProvozovna, true, ['CZ']);
                    }
                } else {
                    countryCodes = countryCodesForRequest(user, poptions.checkUserProvozovna, true);
                }
                const resKilom = value.length===0 ? [] : (await fetch({params: !poptions.checkLicense ? {search: value} : {search: value, countryCodes: countryCodes}}) ?? []) as any[] as OsmPlace[];
                resKilom.forEach(i => {i.ignoreDetail = true});

                try {
                    const countryCodesRequest = poptions.checkLicense ? countryCodesForRequest(user, poptions.checkUserProvozovna) : '';
                    const resOsm: any[] = poptions.enableOSRM ? await search(value + countryCodesRequest) ?? [] : [];
					toUpperCaseRemoveDiacritics(resOsm);
                    console.log(`Req: ${reqNo.current}, this: ${thisReqNo}`);
                    if(exist(thisReqNo) && thisReqNo!==reqNo.current)
                        return;
                    setOptions([...resKilom, ...resOsm]);
                } catch (e) {
                    //in case of osrm error, at least kilom is offered
                    if(exist(thisReqNo) && thisReqNo===reqNo.current)
                        setOptions([...resKilom]);
                }
            }
            else {
                if (valueForSearch instanceof Array) {
                    try {
                        const resGeo: OsmPlace = await protectedCall(() => fetchNearest({arg: new LatLng(valueForSearch[0], valueForSearch[1])}));
                        if (resGeo) {
                            setOptions([resGeo]);
                            return resGeo;
                        }
                    } catch (e) {
                        //in case of osrm error, at least kilom is offered
                        setOptions([]);
                    }
                }
            }
        } catch (e) {
            console.log(e)
            if(exist(thisReqNo) && thisReqNo===reqNo.current)
                setOptions([]);
        }
        finally {
            if(exist(thisReqNo) && thisReqNo===reqNo.current)
                setLoading(false);
        }
        // eslint-disable-next-line
    }, [fetch, poptions?.checkLicense, user, poptions?.enableOSRM, props.countryCodeForCompare, poptions?.countryCodeForCompare])

    const throttleSearchFun = useCallback(async (data?: any) => {
        reqNo.current = reqNo.current + 1;
        return await scheduleSearch(data as string, undefined, reqNo.current)
    }, [scheduleSearch]);

    const throttledSearch = useThrottlingLogic<string, OsmPlace>({
        fun: throttleSearchFun,
        throttlingDelay: 500
    })

    const onopen = useCallback(() => {
        if(!props.focused) invoke(props.onFocus, true);
        invoke(props.onInteractStart);

    },[props.onInteractStart, props.focused, props.onFocus])

    const onclose = useCallback(() => {
        invoke(props.onInteractEnd);
    },[props.onInteractEnd])

    const onchange = useCallback(async (event:any, values) => {
        setValueInternal({...(values as OsmPlace), isOption:true});
    }, [setValueInternal])

    /*  skryto na žádost zákazníka dle #3834
        const findAddress = useCallback(async(latLng: number[]) => {
        if (latLng) {
            scheduleSearch(latLng, true).then((osmPlace) => {
                setValueInternal({...osmPlace, isOption:true});
                // setGpsLoading(false);
            }).catch(() => {
                // setGpsLoading(false);
            })
        }
        input.current?.select();
        props.onFocus&&props.onFocus(true);
        // eslint-disable-next-line
    }, [scheduleSearch, setValueInternal,input, props.onFocus, /!*setGpsLoading*!/])


        const getPosition = useCallback(async () => {
        // setGpsLoading(true);
        const currentPos = getCurrentPosition();
        if (currentPos.length === 0) {
            getGeolocation().then(res => {
                const latLng = [res.coords.latitude, res.coords.longitude];
                setCurrentPosition(latLng);
                findAddress(latLng);
            }).catch(() => {
                // setGpsLoading(false);
                input.current?.select();
                props.onFocus && props.onFocus(true);
            })
        } else {
            findAddress(currentPos);
        }
    // eslint-disable-next-line
    },[input, props.onFocus, /!*setGpsLoading*!/, findAddress])

    const getGeolocation = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            return navigator.geolocation.getCurrentPosition(resolve, reject,
                {
                    enableHighAccuracy: true,
                    timeout: 35000,
                    maximumAge: 0
                })
            }
        );
    }*/

	const filter = createFilterOptions<OsmPlace>();

	const handleOpenFromDialog = (option: OsmPlace) => {
		form?.setOnKeyDisabled(true);
		setSelectedFromItem(option);
		setOpenFromDialog(true);
	};

	const onSavedFrom = (osmPlace: OsmPlace) => {
		// setValue(osmPlace);
		setValueInternal(osmPlace);
		if (table) {
			table.current.focusNextEditRow();
		}
		if (form) {
			form.forwardFocus();
		}
	};

	return (
        <Grid container item wrap={"nowrap"} alignItems={"center"} lg={12} sm={12} md={12} >
            <Grid item lg={!props.disabled ? 12 : 12} sm={!props.disabled ? 12 : 12} md={!props.disabled ? 12 : 12} style={{width: '100%'}}>
				<FormCustomPlaceDialog
					setOpenDialog={setOpenFromDialog}
					openDialog={openFromDialog}
					osmPlace={selectedFromItem}
					onSaved={onSavedFrom}
                    onlyValidLocationIsAllowed={poptions?.onlyValidLocationIsAllowed}
                    // setValue={setValue}
                    invokeOnValueChanged={invokeOnValueChanged}
				/>
				<Autocomplete<OsmPlace, boolean, boolean, boolean>
                    options={options}
                    loading={loading}
                    loadingText={t("Default.Loading")}
                    style={props.style}
                    disableClearable={poptions?.disableClearable || false}
                    noOptionsText={t("MuiAutocomplete.NoOption")}
					filterOptions={(options, params) => {
						const filtered: OsmPlace[] = filter(options, params);
						// @ts-ignore
						if (filtered?.length === 0 && params.inputValue && params.inputValue !== '' && props.options?.enableCustomPlace) {
							// @ts-ignore
							const newPlace: OsmPlace = { isCustomValue: true, display_name: params.inputValue };
							filtered.push(newPlace);
							setTimeout(() => {setShowAddButton(true)}, 500);
						}
						return filtered;
					}}
                    // filterOptions={filterOptions}
                    {...hprops}
                    value={value}
                    disabled={props.disabled ?? false}
                    openOnFocus={false}
                    onChange={onchange}
                    onBlur={() => {setTimeout(() => {setOptions([])}, 100)}}
                    PopperComponent={NomPopper}
                    renderOption={option => {
                        const formatAdditionalInformation = () => {
                            const p:string[] = [option.display_name];
                            return p.join(",");
                        };

						return (
							<>
								{!option.address && !showAddButton &&
									<>{t("Default.Loading")}</>
									// <CircularProgress color="inherit"/>
								}
								{
									// @ts-ignore
									option.isCustomValue && props.options?.enableCustomPlace && showAddButton && (
									<Button onClick={() => handleOpenFromDialog(option)} type={"button"} variant="contained" color="primary">
										{t('VlastniMisto.Pridat')}
									</Button>)
								}
								{!option.isCustomValue && (
								<ul style={{listStyle:"none"}}>
									<li style={{fontWeight:"bold"}}>{formatForRaalUsers(option, poptions?.resolveMpz??resolveMpz, false)}</li>
									{option.address && !option.ignoreDetail && (
										<li>
											{formatAdditionalInformation()}
										</li>
									)}
								</ul>)}
							</>
                        );
                    }}
                    onClose={onclose}
                    onOpen={onopen}
                    getOptionLabel={option => formatForRaalUsers(option, poptions?.resolveMpz??resolveMpz)}
                    // @ts-ignore
                    renderInput={params => <TextField {...params} variant={props.variant ?? "outlined"} hiddenLabel={!Boolean(props.title)} label={props.title} size={"small"}
                                                      inputRef={input}
                                                      InputProps={{...{...{
                                                                  ...params.InputProps,
                                                                  className: props.inputClassName && value
                                                                      ? props.inputClassName
                                                                      :
                                                                      params.InputProps?.className
                                                              }}}}
                                                      error={typeof props.error !== "undefined"}
                                                      helperText={props.error}
                                                      onKeyDown={(e:React.KeyboardEvent<HTMLInputElement>)=>{
														  setShowAddButton(false);
                                                          if(e.key === "Tab" || e.key === "Enter") {
                                                              const optionsTemp = options;
                                                              // @ts-ignore
                                                              const option = optionsTemp.length > htarget.current?.index ? optionsTemp[htarget.current?.index] : null;
                                                              if(option && (props.autofillValue || poptions?.autofillValue)) {
                                                                  setValueInternal(option);
                                                              }
                                                          }
                                                          props.onKeyDown&&props.onKeyDown(e);
                                                      }}
                                                      placeholder={props.placeholder}
                                                      onMouseDownCapture={(e) => {
                                                          userClick.current = true;
                                                          e.stopPropagation();
                                                      }}
                                                      onMouseDown={onMouseDown}
                                                      onMouseUp={onMouseUp}
                                                      onKeyUp={props.onKeyUp}
                                                      onFocus={()=>{
                                                          input.current.select();
                                                          props.onFocus&&props.onFocus(userClick.current);
                                                      }}
                                                      onBlur={() => {
                                                          if(poptions?.onBlur) {
                                                              poptions?.onBlur(value, (newValue:OsmPlace)=>{
                                                                  if(newValue !== undefined) {
                                                                      setValueInternal(newValue);
                                                                      invokeOnValueChanged(value);
                                                                  }
                                                              });

                                                          }
                                                          props.onBlur&&props.onBlur();
                                                      }}
                                                      onChange={(event) => {
                                                          if(poptions?.enableCustomValue) {
                                                              setValueInternal({...createValue(event.target.value), isOption:false}  );
                                                          }
                                                          //scheduleSearch(event.target.value as string);
                                                          throttledSearch(event.target.value as string);
                                                      }}
                    />}

                />
                <DynamicFontSize text={value?.display_name} target={input}/>
            </Grid>
            {/* skryto na žádost zákazníka dle #3834
                {!props.disabled ? <Grid item lg={1} sm={1} md={1} style={{marginLeft: 10, marginRight: 5}}>
                {gpsLoading ?
                    <Box>
                        <CircularProgress color="inherit" size={30}/>
                    </Box>
                    :
                    <Tooltip title={t('MuiAutocomplete.GPSCurrentPosition')}>
                        <span>
                            <IconButton size={"small"} color={"default"} onClick={() =>{
                                getPosition();
                            }}>
                                <GpsFixedIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                }
            </Grid> : null }*/}
        </Grid>
    )
}