import {Column} from "../../../../raal_components/grid/DataGrid.d";
import {DokladkaFilter, DokladkaZadani, VPFilter, VPList} from "../../../../model/PrepravaVozidlo";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {Dial} from "../../../../raal_components/dial/Dial";
import {Preprava, PrepravyList} from "../../../../model/Preprava";
import {ComponentMode} from "../../../../routes";
import {getFilterVersion, sizeFormat, vahaConstraint} from "../_vp/PrepravaAVozidlaShared";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {Waypoint} from "../../../../model/Waypoint";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {Avatar, Box, Button, Card, CardContent, Grid, Typography, useMediaQuery} from "@material-ui/core";
import {cloneClassObject} from "../../../../../common/utils/Util";
import {CodeBookForm} from "../../../../raal_components/controller/CodebookForm";
import {FormButton, FormField, FormInputType, useForm} from "../../../../raal_components/form/Form";
import {useCiselnikSelect, useDateIsoSelect} from "../../../../raal_components/SelectOptions";
import {FormSectionCard} from "../../../../../common/component/form/FormSectionCard";
import {brown, deepOrange, green} from "@material-ui/core/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import {FormNominatim} from "../../../../../common/component/form/FormNominatim";
import {njsSwitch} from "../_vp/VPFilter";
import {CustomFieldComponentProps} from "../../../../../common/component/form/FormFieldInterface";
import {FormDateRangeSelect} from "../../../../raal_components/FormTwoDatesSelect";
import {DEFAULT_ADVANCE_VALUE, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY} from "../prohlizeni/PrepravyCiselnikView";
import {DokladkaDetail} from "./DokladkaDetail";
import {useTemplates} from "../../../../raal_components/grid/MTOverrides";
import {useFilterSelectPopup} from "../../../../raal_components/form/FormFilter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DataStorage from "../../../../../common/DataStorage";
import {FormSelectPopupItem} from "../../../../../common/component/form/FormSelectPopup";

export const DefaultDokladkyPreprava = ({columns, dokladkaFilter}:{columns: Column<VPList>[], dokladkaFilter: DokladkaFilter}) => {
    const {t} = useTranslation();
    const [colsDefault] = useState(columns);
    const endpointPreprava = "user/prepravaview";

    return <div style={{marginBottom: '3rem'}}>
        <Dial<PrepravyList, VPFilter, Preprava>
            mode={ComponentMode.GridMode}
            config={{
                id: "dokladky-preprava-default",
                lockSupport: {enabled: false},
                overflowHidden: true,
                watchChanges: true,
                hideItemAge: true,
                version:getFilterVersion(),
                tableTitle: t("Dokladky.Preprava"),
                endpoint: endpointPreprava,
                clazz: PrepravyList,
                filtering: false,
                hideAddNewActions: true,
                columns: colsDefault,
                hideDefaultDummyAction:true,
                extendedFilter: false,
                defaultQueryParameters: {id: dokladkaFilter.excludedNabidkaId },
                defaultQueryParametersClz: VPFilter,
                stomp:{
                    topic: `/crud-preprava`,
                    toggleable: true,
                    allowStompUiUpdates: true
                },
            }}
            logActivity
            clazzDetail={Preprava}
            layoutDetail={()=><TabHelperNew<Preprava> render={(data)=> {
                return <DokladkaDetail
                    data={data}
                    nakladka={Waypoint.fromWaypoint(dokladkaFilter.nakladka)}
                    vykladka={Waypoint.fromWaypoint(dokladkaFilter.vykladka)}
                />
            }}/>}
            modalHeaderName={() => t("Dokladky.Preprava")}
            pagingDisabled
            crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
            filterClazz={VPFilter}
            tabDetailUrl={''}
        />
    </div>
}

type DokladkyFilterLayoutProps = {
    first: boolean
    dokladkaZadani: DokladkaZadani
    dokladkaFilter: DokladkaFilter
    storeZadani: (data: DokladkaZadani) => void
    storeFilter: (data: DokladkaFilter) => void
}

export const DokladkyFilterLayout = ({first, dokladkaZadani, dokladkaFilter, storeZadani, storeFilter}: DokladkyFilterLayoutProps) => {
    const {classes} = useStyleContext();
    const {t} = useTranslation();

    return first ?
        <Card elevation={0} className={classes.dialogBackground}>
            <CardContent>
                <Box marginBottom={2}>
                    <Typography variant="h6">{t("Dokladky.zadaniDokladky")}</Typography>
                </Box>
                <Box marginLeft={1} marginTop={3} marginRight={1} marginBottom={0}>
                    <DokladkyZadaniForm dokladkaZadani={dokladkaZadani} dokladkaFilter={dokladkaFilter} commit={(result, filter) => {
                        if(filter){
                            DataStorage.set(`DokladkaZadani-filter`, JSON.stringify(filter), true, 'session');
                        } else {
                            DataStorage.clear(`DokladkaZadani-filter`, true, 'session');
                        }
                        result instanceof DokladkaFilter ? storeFilter(result) : storeZadani(result);
                    }}/>
                </Box>
            </CardContent>
        </Card> : null
}

interface DokladkyZadaniFormProps {
    dokladkaZadani: DokladkaZadani;
    dokladkaFilter: DokladkaFilter;
    commit: (dokladkaZadani: DokladkaZadani | DokladkaFilter, filter?: {id: any, name: string}) => void;
    isModal?: boolean;
}

export function DokladkyZadaniForm(props: DokladkyZadaniFormProps) {
    const [dokladkaZadani, setDokladkaZadani] = useState(() => cloneClassObject(props.dokladkaZadani));

    useEffect(() => {
        setDokladkaZadani(props.dokladkaZadani);
    }, [props.dokladkaZadani]);

    return <CodeBookForm
        excludeFieldsForDirtyCheck={Object.keys(dokladkaZadani)}
        hideNewButtonOnEdit
        hideSaveButton
        data={dokladkaZadani}
        clazz={DokladkaZadani}
        url={""}
        focusFieldWhenMounted
        render={() =>
            <DokladkyZadani {...props}/>}
    />
}

export function DokladkyZadani(props: DokladkyZadaniFormProps) {
    const {t} = useTranslation();
    const {data} = useForm<DokladkaZadani>();
    const ciselnikSelectFilter = useCiselnikSelect(CiselnikTyp.R, {isClearable: true, isMulti: true});
   /* const {classes: cls} = useStyleContext(); #4660 zakomentovano z duvodu odstraneni divider -> promenna po odstraneni je nepotrebna*/
    const compareRegion = useCallback((field: "nakladka" | "vykladka") => {
        const nakladka = data.nakladka?.countryCode;
        const vykladka = data.vykladka?.countryCode;
        return (field === "vykladka" && nakladka === "CZ") || (field === "nakladka" && vykladka === "CZ");
    }, [data])
    const dateFromSelect = useDateIsoSelect({dateType: 'datOd', typAdvance: PARAM_ADVANCE_KEY, defaultAdvanceValue: DEFAULT_ADVANCE_VALUE})
    const dateSpacing = 0;
    const isWideScreen = useMediaQuery("(min-width:870px)");

    const fns = useTemplates<DokladkaFilter>(undefined, DokladkaFilter);
    const showPopup = useFilterSelectPopup({
        typ: "user/dokladka",
        templateFunctions: fns,
        setSelectedValue: (filter: FormSelectPopupItem) => {
            props.commit(cloneClassObject(fns.getMappedFilters(filter.data)), {id: filter.value, name: filter.label});
        }
    });

    return <>
            {!props.isModal && <Grid container item lg={12} md={12} sm={12} style={
                // #4652 - pridano kvuli tomu, ze na male zobrazovaci plose, ktera je mensi na sirku nez 870px se prekryval text Dokladky preprav a tlacitko pro ulozene filtry
                isWideScreen
                    ? {   marginTop: "-3.75rem" }
                    : {}
            } justifyContent={"flex-end"}>
                <Button style={{marginLeft: "10px", marginBottom: "10px"}} color="primary" variant="contained" onClick={() => {
                    showPopup(null).then();
                }}><FilterAltIcon style={{marginRight: 5}}/>{t("Filter.SavedFilters")}</Button>
                <FormField type={FormInputType.Custom} name={"button"} customComponent={DokladkaCalcButton}
                           customComponentOptions={{props: props}}/>
            </Grid>}
        <Grid container spacing={1}>
            <FormSectionCard title={t("SbernaSluzba.trasa")}
                             avatar={<Avatar style={{backgroundColor: green[400]}}><FontAwesomeIcon
                                 icon={faIcon.faRoute} size="sm"/></Avatar>}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormField required forceUpdateWhenDataChanged={["button"]} title={t("Dokladky.nakladka")}
                               name={"nakladka"} type={"Custom"}
                               customComponent={FormNominatim}
                               getValue={(data) => {
                                   return data.nakladka?.nazevMista;
                               }}
                               setValue={(data, fieldName, value) => {
                                   data.nakladka.nazevMista = value;
                               }}
                               customComponentOptions={{
                                   disableClearable: true,
                                   autofillValue: true,
                                   checkLicense: true,
                                   countryCodeForCompare: () => compareRegion("nakladka"),
                                   onChange: (osmPlace: any) => {
                                       data.nakladka = Waypoint.fromOsmPlace(osmPlace);
                                   }
                               }}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormField required forceUpdateWhenDataChanged={["button"]} title={t("Dokladky.vykladka")}
                               name={"vykladka"} type={"Custom"}
                               customComponent={FormNominatim}
                               getValue={(data) => {
                                   return data.vykladka?.nazevMista;
                               }}
                               setValue={(data, fieldName, value) => {
                                   data.vykladka.nazevMista = value;
                               }}
                               customComponentOptions={{
                                   disableClearable: true,
                                   autofillValue: true,
                                   checkLicense: true,
                                   countryCodeForCompare: () => compareRegion("vykladka"),
                                   onChange: (osmPlace: any) => {
                                       data.vykladka = Waypoint.fromOsmPlace(osmPlace);
                                   }
                               }}
                    />
                </Grid>
            </FormSectionCard>
            <FormSectionCard title={t("SbernaSluzba.vozidlo")}
                             avatar={<Avatar style={{backgroundColor: brown[400]}}><FontAwesomeIcon
                                 icon={faIcon.faTruck} size="sm"/></Avatar>}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormField required forceUpdateWhenDataChanged={["button"]} title={t("Preprava.delka")}
                               name={"delka"} type={FormInputType.Number}
                               numberProps={{format: sizeFormat, constraint: vahaConstraint}}/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormField required forceUpdateWhenDataChanged={["button"]} title={t("Preprava.vaha")} name={"vaha"}
                               type={FormInputType.Number}
                               numberProps={{format: sizeFormat, constraint: vahaConstraint}}/>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormField forceUpdateWhenDataChanged={["button"]} title={t("Preprava.druhy")}
                               name={"druhyDokladka"} type={"select"}
                               selectProps={ciselnikSelectFilter}/>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <FormField title={t("Preprava.naves")} name={"naves"} type={"checkbox"}
                               onWillChange={(field, newValue) => {
                                   if (newValue) njsSwitch('N', data);
                               }} forceUpdateWhenDataChanged={['souprava', 'jine']}/>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <FormField title={t("Preprava.souprava")} name={"souprava"} type={"checkbox"}
                               onWillChange={(field, newValue) => {
                                   if (newValue) njsSwitch('S', data)
                               }} forceUpdateWhenDataChanged={['naves', 'jine']}/>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                    <FormField title={t("Preprava.jine")} name={"jine"} type={"checkbox"}
                               onWillChange={(field, newValue) => {
                                   if (newValue) njsSwitch('J', data)
                               }} forceUpdateWhenDataChanged={['souprava', 'naves']}/>
                </Grid>
            </FormSectionCard>
            <FormSectionCard title={t("Dokladky.datum")}
                             avatar={<Avatar style={{backgroundColor: deepOrange[500]}}><FontAwesomeIcon
                                 icon={faIcon.faCalendar} size="sm"/></Avatar>}>
                <Grid item lg={12} sm={12} xs={12}>
                    <FormField name={"vpRange"} type={"Custom"} forceUpdateWhenDataChanged={["button"]}
                               customComponent={FormDateRangeSelect} customComponentOptions={{
                        dateSpacing,
                        dateFromSelect,
                        typParametru: PARAM_ADVANCE_KEY,
                        DEFAULT_RANGE_VALUE,
                        flexDirection: "row",
                        datOdRequired: false
                    }}/>
                </Grid>
            </FormSectionCard>
        </Grid>
        <div className="modal-buttons" style={{marginTop: "10px"}}>
            <FormField type={FormInputType.Custom} name={"button"} customComponent={DokladkaCalcButton}
                       customComponentOptions={{props: props}}/>
        </div>
    </>;
}

function DokladkaCalcButton(props: CustomFieldComponentProps<any, any>) {
    const {t} = useTranslation();
    const {data} = useForm<DokladkaZadani>();

    return <FormButton disabled={!data.isFilled()} key={"update"} type={"update"} skipBlock forceTabClick onClick={() => {
        props.options.props.commit(cloneClassObject(data));
    }}>{t("Dokladky.calculate")}</FormButton>
}

export const convertFilterData = (filter?: DokladkaZadani): DokladkaFilter => {
    if (!filter) return null;
    // @ts-ignore
    const dokladkaFilter = {
        okoliOdkud: filter.nakladka && {
            osmPlace: filter.nakladka.nazevMista,
            countryCode: filter.nakladka.countryCode,
            koordinat: filter.nakladka.koordinat
        },
        okoliKam: filter.vykladka && {
            osmPlace: filter.vykladka.nazevMista,
            countryCode: filter.vykladka.countryCode,
            koordinat: filter.vykladka.koordinat
        },
        excludedNabidkaId: filter.excludedNabidkaId,
        delkaRange: filter.delka && {max: filter.delka},
        vahaRange: filter.vaha && {max: filter.vaha},
        naves: filter.naves,
        souprava: filter.souprava,
        jine: filter.jine,
        druhy: filter.druhyDokladka && [...filter.druhyDokladka],
        vpRange: {
            min: filter.vpRange?.min,
            max: filter.vpRange?.max,
        },
        nakladka: filter.nakladka,
        vykladka: filter.vykladka
    }

    return dokladkaFilter as DokladkaFilter;
}
