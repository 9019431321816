import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {SelectProps} from "../../../common/component/form/FormSelect";
import * as React from "react";
import {createRef, MutableRefObject, ReactElement} from "react";
import {
    CustomFieldComponent,
    FormFieldInterfaceProps,
    FormFieldListeners
} from "../../../common/component/form/FormFieldInterface";
import {CustomDateTimePickerOptions, FormDatetime} from "../../../common/component/form/FormDatetime";
import {FieldError} from "../../../common/component/form/ValidationError";
import {TextFieldProps} from "@material-ui/core";
import {FormNumber, NumberProps} from "../../../common/component/form/FormNumber";
import {FieldMessages, HiddenFormProps} from "./Form.d";
import {CustomDateTimePickerLocalization} from "../../../common/component/CustomDateTimePicker";
import {FormComponent, FormInputType} from "./Form";
import {clamp, distinctArray, exist} from "../../../common/utils/Util";
import {FormInput} from "../../../common/component/form/FormInput";
import {FormInputFile} from "../../../common/component/form/FormInputFile";
import {FormPhoneNumber} from "../../../common/component/form/FormPhoneNumber";
import {FormTextarea} from "../../../common/component/form/FormTextarea";
import {FormEditor} from "../../../common/component/form/FormEditor";
import {FormColorPicker} from "../../../common/component/form/FormColorPicker";
import {FormMuiAutocomplete} from "../../../common/component/form/FormMuiAutocomplete";
import {FormCheckbox} from "../../../common/component/form/FormCheckbox";

/********************
 *
 * FORM FIELD
 *
 ********************/
type Validation = {regexp: RegExp, message:string}
type ServerValidation = {translate:(code:string)=>string, index?:number}
type CustomFieldWithRef = {resetFocus: () => void, addNew: () => void, deleteItem: () => void}
export interface FormFieldProps<CustomOptions = any, Value=any> {
    type: FormInputType | string;
    name: string;
    getValue?:(data:any, fieldName:string)=>Value,
    setValue?:(data:any, fieldName:string, value:Value)=>void,
    title?: string;
    required?: boolean;
    icon?: IconProp;
    showIcon?: boolean;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    useFormGroup?: boolean;
    skipInputGroup?:boolean;
    disabled?: boolean | ((field?:FormFieldComponent<CustomOptions, Value>)=>boolean);
    onChanged?: (field: FormFieldComponent) => void;
    onWillChange?: (field: FormFieldComponent, newValue?:Value) => void;
    selectProps?: SelectProps|((field:FormFieldComponent)=>SelectProps);
    onKeyDown?: (e: React.KeyboardEvent) => void
    onFocus?: () => void
    onBlur?: () => void
    preventFocusNext?: boolean
    customComponent?: CustomFieldComponent | any;
    customComponentOptions?: CustomOptions | (()=>CustomOptions);
    dateTimeOptions?: CustomDateTimePickerOptions;
    ignoreBlockSave?:boolean;
    dataTip?:string;
    validate?:((data:any, value?:any)=>FieldError[])|Validation|Validation[]
    serverValidation?:ServerValidation,
    textFieldProps?: TextFieldProps,
    wrappingFunction?:(value:string)=>any,
    numberProps?: NumberProps,
    forceUpdateWhenDataChanged?:Array<string>,
    showAdornment?:boolean,
    startAdornment?: ReactElement,
    focusCountAs?:number,
    additionalServerErrorKeys?:string[],
    noAutofocus?:boolean,
    onDisableActionKeys?:(isDisabled:boolean, focusNext?: boolean)=>void,
    autoSelectFirstValueOnTab?: boolean,
    isContainer?: boolean,
    isTabbedContainer?: boolean,
    defaultValue?: any,
    isFirstInTab?: number,
    isFormGrid?:boolean;
    isLastInTab?:boolean,
    errorKey?:string,
    togglePasswordVisibility?: () => void,
    passwordVisible?: boolean,
    preventEmptyLines?: boolean,
    getColor?:()=>string
    fieldRef?: MutableRefObject<FormFieldExposed>
	setDom?: (dom: any) => void
    onlyValidLocationIsAllowed?: boolean
}

export type FormFieldOverrides = {name:string, disabled?:boolean, invisible?:boolean}

export type FormFieldExposed = {
    clearValue?: () => void
}

interface FormFieldState {
    errors?: Array<string>;
    focused:boolean;
}

export interface HiddenFormFieldProps extends HiddenFormProps {
    defaultValue: any;
    onValueChanged: (field: FormFieldComponent) => void;
    fieldErrors: Array<FieldError>;
    localization?:FieldMessages;
    dateTimePickerLocalization?:CustomDateTimePickerLocalization
    onFocus:(field:FormFieldComponent, userfocus:boolean)=>void
    autofillValue?:boolean;
    blockSaveUntilChange?:boolean;
    formFieldOverrides:FormFieldOverrides
}

/**
 * Master komponenta pro formularova pole.
 * zapouzdruje logiku pro nastavovani dat do datoveho objektu a renderuje konkretni typy poli dle nastaveni
 */
export class FormFieldComponent<CustomOptions = any, Value = any> extends React.Component<FormFieldProps<CustomOptions>&{getForm():FormComponent<any>}, FormFieldState> {
    listenersInstance = new FormFieldListeners();
    state = {focused: false};
    // @ts-ignore
    hiddenProps: HiddenFormFieldProps;

    supportTabFocus:number = 0;
    currentTabFocused:number = 1;
    isLastContainerField:boolean = false;
    isLastInTab?: boolean;
    realtimeErrors:string[] = [];
    value: Value = null;
    componentRef = createRef<CustomFieldWithRef>();
    filterForm = false
    childForm  =false

    constructor(props:FormFieldProps<CustomOptions>&{getForm():FormComponent<any>}) {
        super(props);
        const form = props.getForm();
        this.filterForm = form.props.filterForm;
        this.childForm = form.props.childForm;
        form.fields.push(this);
        this.updateProps();
    }
    componentDidMount(): void {
        const form = this.props.getForm();
        form.onFieldMounted(this);
    }

    componentWillUnmount(): void {
        const form = this.props.getForm();
        if(form) {
            form.fields = form.fields.filter(f=>f!==this);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<FormFieldProps>, nextContext: any): void {
        this.updateProps();
    }

    updateProps() {
        this.hiddenProps = this.props.getForm().hiddenFieldProps(this.props);
        this.value = this.hiddenProps.defaultValue;
    }

    static defaultProps = {
        ignoreBlockSave:false,
        showIcon: true,
        useFormGroup: false,
        disabled: false
    };

    valueChanged = (value: any) => {
        this.props.onWillChange && this.props.onWillChange(this, value);
        this.value = value;
        this.hiddenProps.onValueChanged(this);
        this.props.onChanged && this.props.onChanged(this);
    };

    hasError() {
        return this.hiddenProps.fieldErrors.length > 0;
    }
    getErrorMessage = (e:FieldError) => {
        if(!exist(e))
            return undefined;
        let msg = e.localize ? this.hiddenProps.localization[e.message] : e.message;
        const sv = this.props.serverValidation;
        if(!e.localError && sv) {
            const index = sv.index||0;
            const messageKey = [...e.codes].reverse()[clamp(index, 0, e.codes.length-1)];
            msg = sv.translate(messageKey);
            if(msg===messageKey)
                msg = sv.translate(e.message);
        }
        return msg;
    };


    resolveDisabled():boolean {
        if(typeof this.props.disabled === 'function') {
            return this.props.disabled(this);
        }
        return this.props.disabled as boolean;
    }

    createDefaultProps = ():FormFieldInterfaceProps<Value> => {
        return {
            dataTip:this.props.dataTip,
            className: "form-control",
            inputClassName: this.filterForm ? "filter-input" : "",
            disabled: this.resolveDisabled() || this.hiddenProps.formFieldOverrides?.disabled || this.props.getForm().props.disabled,
            placeholder: this.props.placeholder,
            focused:this.state.focused,
            value: this.value,
            name: this.props.name,
            onValueChanged: this.valueChanged,
            simpleLabel: this.hiddenProps.simpleLabel,
            listeners: this.listenersInstance,
            title: `${this.props.title} ${this.props.required ? "*": ""}`,
            error:this.hasError() ? distinctArray(this.hiddenProps.fieldErrors.map(e=>this.getErrorMessage(e)), (i)=>i).join(",") : undefined,
            required:this.props.required,
            showAdornment:this.props.showAdornment,
            startAdornment: this.props.startAdornment,
            autofillValue:this.hiddenProps.autofillValue,
            currentFocused:this.currentTabFocused,
            allErrors:this.props.getForm().state?.errors?.errors,
            data:this.props.getForm().data,
            autoSelectFirstValueOnTab: this.props.autoSelectFirstValueOnTab,
            isFirstInTab: this.props.isFirstInTab,
            isLastInTab: this.props.isLastInTab,
            isFormGrid: this.props.isFormGrid,
            isTabbedContainer: this.props.isTabbedContainer,
            onKeyDown: this.props.onKeyDown,
            fieldRef: this.props.fieldRef,
            onBlur: this.props.onBlur,
            onError:(errors:string[])=>{
                this.realtimeErrors = errors.filter(e=>e&&e.trim().length > 0)
            },
            enableFocusSupport:(countAs:number = 1, currentFocused:number=0)=>{
                this.supportTabFocus = countAs;
                this.currentTabFocused = currentFocused;
            },
            onFocus: (userfocus:boolean, index?:number) => {
                this.currentTabFocused = index;
                this.hiddenProps.onFocus(this, userfocus);
            },
            setLastField: (isLast) => {
                this.isLastContainerField = isLast;
            },
            onDisableActionKeys: (isDisabled: boolean, focusNext?: boolean) => {
                this.props.getForm().onDisableActionKeys(isDisabled, focusNext);
            },
            onFocusNextField: (focusFirstOrButtons?: boolean) =>{
                this.props.getForm().onFocusNextField(focusFirstOrButtons);
            },
            onFocusPrevField: (index?: number) =>{
                this.props.getForm().onFocusPrevField(index);
            },
            onFocusButton: () => {
                this.props.getForm().focusButton();
            },
            resetFormFocus: () => {
                this.props.getForm().resetFormFocus();
            }
        };
    };

    renderFormInput() {
        return <FormInput type={this.props.type}
                          textFieldProps={this.props.textFieldProps}
                          wrappingFunction={this.props.wrappingFunction}
                          togglePasswordVisibility={this.props.togglePasswordVisibility}
                          passwordVisible={this.props.passwordVisible}
						  setDom={this.props.setDom}
                          {...this.createDefaultProps()}
        />
    }

    renderFormInputFile() {
        return <FormInputFile textFieldProps={this.props.textFieldProps}
                              {...this.createDefaultProps()}
        />
    }

    renderFormPhoneNumber() {
        return <FormPhoneNumber
            textFieldProps={this.props.textFieldProps}
            wrappingFunction={this.props.wrappingFunction}
            {...this.createDefaultProps()}
        />
    }

    renderFormTextarea() {
        return <FormTextarea
            textFieldProps={this.props.textFieldProps}
            preventEmptyLines={this.props.preventEmptyLines}
            {...this.createDefaultProps()}
        />;
    }

    renderFormEditor() {
        return <FormEditor
            textFieldProps={this.props.textFieldProps}
            customComponentOptions={this.props.customComponentOptions}
            preventEmptyLines={this.props.preventEmptyLines}
            defaultValue={this.props.defaultValue}
            {...this.createDefaultProps()}
        />;
    }

    renderFormColorPicker() {
        return <FormColorPicker
            getColor={this.props.getColor}
            {...this.createDefaultProps()}
        />;
    }

    renderFormSelect() {
        return this.renderFormAutocomplete();
    }
    renderFormAutocomplete() {

        const getSelectProps = () => {
            if(typeof this.props.selectProps === "function") {
                return this.props.selectProps(this);
            }
            return this.props.selectProps;
        };

        return <FormMuiAutocomplete  {...this.createDefaultProps()}
                                     key={`${new Date().getMilliseconds()}`}
                                     selectProps={getSelectProps()}
        />;
    }
    renderFormCheckbox() {
        // eslint-disable-next-line
        return <FormCheckbox  {...this.createDefaultProps()} value={this.value && typeof this.value === 'string' ? eval(this.value) : this.value}
        />;
    }

    renderDatetime() {
        // @ts-ignore
        return <FormDatetime  {...this.createDefaultProps()}
                              dateTimeOptions={this.props.dateTimeOptions}
                              localization={this.hiddenProps.dateTimePickerLocalization}
                              defaultValue={this.props.defaultValue}
        />;
    }

    renderCustomField() {
        const comp = () => {
            if (this.props.customComponent === null || this.props.customComponent === undefined) {
                return <div>Custom component must have set prop customComponent</div>;
            }
            const Component = this.props.customComponent;
            return <Component  {...this.createDefaultProps()}
                // @ts-ignore
                               options={typeof this.props.customComponentOptions === "function" ? () => ({data:this.props.getForm().data, ...this.props.customComponentOptions()}) : {data:this.props.getForm().data, ...this.props.customComponentOptions}}
                               errorKey={this.props.errorKey}
            />;
        };
        return comp();
    }

    renderCustomFieldWithRef() {
        const comp = () => {
            if (this.props.customComponent === null || this.props.customComponent === undefined) {
                return <div>Custom component must have set prop customComponent</div>;
            }
            const Component = this.props.customComponent;
            return <Component  {...this.createDefaultProps()} ref={this.componentRef}
                // @ts-ignore
                               options={typeof this.props.customComponentOptions === "function" ? () => ({data:this.props.getForm().data, ...this.props.customComponentOptions()}) : {data:this.props.getForm().data, ...this.props.customComponentOptions}}
                               errorKey={this.props.errorKey}
            />;
        };
        return comp();
    }

    validate():FieldError[] {
        let errors = [] as FieldError[];
        const isNull = this.value === null || this.value === undefined;

        if(isNull && this.props.required) {
            errors.push(FieldError.Create("FieldIsRequired", true));
        }

        if(this.props.validate) {
            if(typeof this.props.validate === "function") {
                // @ts-ignore
                errors = errors.concat(this.props.validate(this.props.getForm().data, this.value) || []);
            } else {
                const doValidate = (validation:Validation) => {
                    if(!isNull&&!this.value.toString().match(validation.regexp)) {
                        errors.push(FieldError.Create(validation.message));
                    }
                };
                if(Array.isArray(this.props.validate)) {
                    this.props.validate.forEach(doValidate);
                } else {
                    doValidate(this.props.validate);
                }
            }
        }

        if(this.realtimeErrors.length > 0) {
            errors = errors.concat(this.realtimeErrors.map(e=>FieldError.Create(e, false)))
        }

        //distinct
        errors.forEach(e=>{
            if(!exist(e.name))
                e.name = this.props.name;
            e.localError = true;
        });
        return errors;
    }

    resetFocus() {
        this.componentRef.current?.resetFocus();
    }

    addNew() {
        this.componentRef?.current?.addNew();
    }

    deleteItem() {
        this.componentRef?.current?.deleteItem();
    }

    render() {
        if(this.hiddenProps.formFieldOverrides?.invisible) {
            return "invisible field";
        }
        if (this.props.type === FormInputType.Text || this.props.type === FormInputType.Password || this.props.type === FormInputType.Email) {
            return this.renderFormInput();
        } else if(this.props.type === FormInputType.File) {
            return this.renderFormInputFile();
        } else if (this.props.type === FormInputType.Select) {
            return this.renderFormSelect();
        } else if (this.props.type === FormInputType.TextArea) {
            return this.renderFormTextarea();
        } else if (this.props.type === FormInputType.Editor) {
            return this.renderFormEditor();
        } else if (this.props.type === FormInputType.Checkbox) {
            return this.renderFormCheckbox();
        } else if (this.props.type === FormInputType.Custom) {
            return this.renderCustomField();
        } else if (this.props.type === FormInputType.CustomWithRef) {
            return this.renderCustomFieldWithRef();
        } else if (this.props.type === FormInputType.DateTime) {
            return this.renderDatetime();
        } else if (this.props.type === FormInputType.AutoComplete) {
            return this.renderFormAutocomplete();
        } else if(this.props.type === FormInputType.Number) {
            // @ts-ignore
            return <FormNumber
                textFieldProps={this.props.textFieldProps}
                numberProps={this.props.numberProps}
                {...this.createDefaultProps()}
            />
        }
        else if (this.props.type === FormInputType.PhoneNumber) {
            return this.renderFormPhoneNumber();
        }
        else if (this.props.type === FormInputType.Color) {
            return this.renderFormColorPicker();
        }
        return `Wrong input type ${this.props.type}`;
    }

}
