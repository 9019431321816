import {useTranslation} from "react-i18next";
import * as React from "react";
import {useCallback, useRef, useState} from "react";
import {useViewColumns} from "../_vp/VPColumns";
import DataStorage from "../../../../../common/DataStorage";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import {PrejezdFilter, PrejezdFilterZadani, PrejezdVPList} from "../../../../model/PrepravaVozidlo";
import {Dial} from "../../../../raal_components/dial/Dial";
import {Preprava} from "../../../../model/Preprava";
import {
    exportableFieldsView,
    getExportableProps,
    getFilterVersion,
    useVPPdfLayout
} from "../_vp/PrepravaAVozidlaShared";
import {FilterForm} from "../_vp/VPFilter";
import {ExtendedViewNew} from "../_vp/VPExtendedView";
import {
    CISELNIK_DRUH,
    DEFAULT_ADVANCE_VALUE,
    DEFAULT_RANGE_VALUE,
    PARAM_ADVANCE_KEY,
    PARAM_RANGE_KEY
} from "../prohlizeni/PrepravyCiselnikView";
import {PrejezdFilterAdditionalPart, prepareCols} from "./PrejezdyTransportNabidkaDialCommon";
import {Mapper} from "../../../../../common/utils/objectmapper/Mapper";
import {exist} from "../../../../../common/utils/Util";
import {TabHelperNew} from "../../../../raal_components/controller/TabHelper";
import {useHashId} from "../../../../raal_components/controller/CodeBookController";
import {InvalDuvodPreprava} from "../../../../model/CommonTypes";
import {useAppContext} from "../../../../context/AppContext";
import {useCiselnikValues, useLocalizeCiselnikValue} from "../../../../context/DataContext";
import {RouteComponentProps} from "../../../../routes";
import {useLocation} from "react-router";
import {useDidMount} from "../../../../../common/component/hooks/SharedHooks";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";
import {convertFilterZadaniData, DefaultPreprava, DefaultVozidlo, FilterLayout} from "./PrejezdyDefault";
import {KilometrovnikLinkType} from "./KilometrovnikLinkPart";
import {Waypoint} from "../../../../model/Waypoint";
import {Box} from "@material-ui/core";
import {useClearPrepravaViewRecords, useZaznamOznaceniStyle} from "../Oznaceni";

export function PrejezdyPrepravDial(props: RouteComponentProps) {
    const {t} = useTranslation();
    const refreshFun = useRef(() => {});
    let [createColumns] = useViewColumns(CiselnikTyp.R, false, refreshFun, "user/preprava", [], PARAM_RANGE_KEY, DEFAULT_RANGE_VALUE, PARAM_ADVANCE_KEY, DEFAULT_ADVANCE_VALUE, false, {disableMultichoice: false, refactor: true}, true, true);
    const endpoint = "user/preprava-prejezd";
    const dtGrid = useRef<DataGridExposed<PrejezdVPList, PrejezdFilter>>();
    const [filterData, setFilterData] = useState<PrejezdFilter>(new PrejezdFilter());
    const [filterZadani, setFilterZadani] = useState<PrejezdFilterZadani>(new PrejezdFilterZadani());
    const [first, setFirst] = useState(true);
    const filtername = 'user/preprava-prejezd'
    const storageKey = 'filter_prejezdy-preprav';
    const dataGridStorageKey = `datagrid_${filtername}`;
    const id = useHashId();
    const {user} = useAppContext();
    const {pdfLayout} = useVPPdfLayout(user);
    const locFun = useLocalizeCiselnikValue(CiselnikTyp.P);
    const {druhyJoined} = useCiselnikValues(CISELNIK_DRUH);
    const {pathname, state} = useLocation<any>();
    const {replace} = useHistoryCustom();
    const savedFilter = JSON.parse(DataStorage.get(`${storageKey}-filter`, true, 'session'));
    const changeFilterData = useCallback(() => {
        const filter = DataStorage.get(storageKey, true, 'session');
        if (filter) {
            clearFilters();
            const mapper = new Mapper<PrejezdFilter>({constructor: PrejezdFilter});
            const tempFilterData = mapper.readValue(filter);
            setFilterZadani(convertFilterZadaniData(tempFilterData));
            setFilterData(tempFilterData);
        }
        // eslint-disable-next-line
    }, [setFilterData])

    useDidMount(()=>{
        if (state?.clearFilter === true) {
            clearFilters(true);
            replace(pathname, { clearFilter : false })
        } else {
            setFirst(false);
        }
        changeFilterData();
    });

    const clearFilters = (clearFilterName?: boolean) => {
        DataStorage.clear(storageKey, true, 'session');
        DataStorage.clear(dataGridStorageKey, true, 'session');
        if(clearFilterName) DataStorage.clear(`${storageKey}-filter`, true, 'session');
        setFilterData(new PrejezdFilter());
        setFilterZadani(new PrejezdFilterZadani());
    }

    const getCurrentFilter = (okoli: 'odkud'|'kam') => {
        const data = JSON.parse(DataStorage.get(dataGridStorageKey, true, 'session'))
        return okoli === "odkud" ?
            Waypoint.fromOkoli(data?.current?.filters?.okoliOdkud) ?? filterData.prejezdOdWaypoint ?? data?.current?.filters?.prejezdOdWaypoint
            :
            Waypoint.fromOkoli(data?.current?.filters?.okoliKam) ?? filterData.prejezdKamWaypoint ?? data?.current?.filters?.prejezdKamWaypoint;
    }

    const clearPrepravaViewRecords =
        useClearPrepravaViewRecords(() => {dtGrid.current?.table()?.refresh({}, null, true)});
    const nabidkaOznaceni = useZaznamOznaceniStyle(endpoint);

    const filtersFilled = getCurrentFilter('odkud') && getCurrentFilter('kam');
    return <Box style={{ display: "flex", flexDirection: "column", flexGrow: "inherit", overflow: !first && ((filtersFilled) || exist(id)) ? "auto" : "initial" }}>
            {!exist(id) && <FilterLayout
                filterData={filterZadani}
                first={first || !(filtersFilled)}
                setFirst={(state) => {
                    setFirst(state);
                    changeFilterData();
                }}
                redirectAfterSend={false}
                title={t("Dials.PrejezdyPreprav")}
                type={KilometrovnikLinkType.PREPRAVA}
            />}
            {!first && ((filtersFilled) || exist(id)) && <>
                {filterData.excludedNabidkaId && !exist(id) ?
                    <DefaultPreprava columns={createColumns()} filterData={filterData} id={filterData.excludedNabidkaId}/> : null}
                {filterData.nabidkaId && !exist(id) ?
                    <DefaultVozidlo columns={createColumns()} filterData={filterData} id={filterData.nabidkaId}/> : null}
                <Dial<PrejezdVPList, PrejezdFilter, Preprava>
                    mode={props.mode}
                    gridRef={dtGrid}
                    lastBrowsedDateKey={"prejezdy-preprav"}
                    config={{
                        requiredColumns: ['odkud','kam'],
                        defaultHiddenColumns: ["palety", "sirka", "vyska", "lozPlocha", "objem", "klasifikace"],
                        alternativeButtons: props.admin ? undefined : [clearPrepravaViewRecords],
						cache: {disabled: false},
                        exportConfig: {
                            exportable: true,
                            exportDetailOnly: true,
                            endpoint: endpoint,
                            fileName: "export_prejezdy_prepravy",
                            translationPrefix: ['Preprava', 'User'],
                            formats: ["pdf", "xls", "csv", "xml"],
                            exportAllFormats: ["csv"],
                            exportableProps: getExportableProps(exportableFieldsView),
                            pdfLayout: (data, fields, pageBreak, index, origin) => pdfLayout(data, fields, pageBreak, index, origin),
                            extendedProps: [
                                {
                                    type: ["csv", "xls"],
                                    addExtendedProps: (data) => {
                                        return {
                                            provozovna: data.uzivatel?.provozovna?.kod
                                        }
                                    }
                                }
                            ],
                            formattedProps: [
                                {
                                    type: ["csv", "xls", "pdf"],
                                    field: "druhy",
                                    format: (data) => {
                                        return druhyJoined(data)
                                    }
                                },
                                {
                                    type: ["csv", "xls", "pdf"],
                                    field: "invalDuv",
                                    format: (data) => {
                                        return locFun("Enumerations.InvalDuvodPreprava", InvalDuvodPreprava, data)
                                    }
                                },
                                {
                                    type: ["csv", "xls", "pdf"],
                                    field: "rychlyKontakt",
                                    format: (data) => {
                                        return data ? `${data.slice(0, 4)} ${data.slice(4)}` : null;
                                    }
                                }
                            ]
                        },
                        id: filtername,
                        initialFilter: {id: savedFilter?.id, name: savedFilter?.name, data: filterData},
                        overflowHidden: true,
                        version:getFilterVersion(),
                        tableTitle: t("Dials.PrejezdyPreprav"),
                        endpoint: endpoint,
                        clazz: PrejezdVPList,
                        filtering: true,
                        hideAddNewActions: true,
                        columns: prepareCols(createColumns, false, t),
                        hideDefaultDummyAction:true,
                        onClearFilter: () => {
                            clearFilters(true);
                        },
                        watchChanges: true,
                        stomp: {
                            topic: '/crud-preprava',
                            toggleable: true,
                            allowStompUiUpdates: true
                        },
                        options: {
                            rowStyle: (data: any) => {
                                if (data.klasifikace?.barva) {
                                    return {backgroundColor: data.klasifikace?.barva}
                                }
                                const oznaceniStyle = nabidkaOznaceni.getStyle(data);
                                if(oznaceniStyle) return oznaceniStyle;
                            }
                        }
                    }}
                    hideSaveButton
                    hideNewButtonOnEdit
                    clazzDetail={Preprava}
                    crudConfig={{editEnabled: false, removeEnabled: false, addEnabled: false}}
                    layoutFilter={() => <FilterForm {...{typCiselniku:CiselnikTyp.R, showOznaceno:false,
                        preprava: true, viewing: true, defaultRange:DEFAULT_RANGE_VALUE, typAdvance: PARAM_ADVANCE_KEY,
                        defaultAdvance: DEFAULT_ADVANCE_VALUE, admin: false, disableMultichoice: false, hideItemAge: true, okoliDisabled: true}}>
                        <PrejezdFilterAdditionalPart/>
                    </FilterForm>}
                    layoutDetail={()=><TabHelperNew<Preprava> render={(data)=> {
                        return <>
                            <ExtendedViewNew ciselnikTyp={CiselnikTyp.R} data={data} admin={false} archive={false} prejezdProps={
                                {
                                    trasa: data.getMista().map(value => value.koordinat),
                                    prejezdOd: getCurrentFilter("odkud"),
                                    prejezdKam: getCurrentFilter("kam")
                                }
                            }/>
                        </>;
                    }}/>}
                    modalHeaderName={() => t("Dials.PrejezdyPreprav")}
                    filterClazz={PrejezdFilter}
                />
            </>}
        </Box>
}
