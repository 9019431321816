import React, { useEffect } from 'react';
import { MapContainer, Polyline, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import H from '@here/maps-api-for-javascript';
import { RouteResponse, Section } from './HereResponse';

export class HereMapComponentProps {
	routing?: RouteResponse;
	tolls?: RouteResponse;
}

export function HereMapComponent(props: HereMapComponentProps) {

	const getPolylines = (): number[][] => {
		let sections: Section[] = null;
		if (props.routing?.routes && props.routing.routes[0]) {
			sections = props.routing.routes[0].sections;
		}
		if (props.tolls?.routes && props.tolls.routes[0]) {
			sections = props.tolls.routes[0].sections;
		}
		let result: number[][] = [];
		sections?.forEach((section) => {
			// const sectionLatLngAltArray =  H.geo.LineString.fromFlexiblePolyline(section.polyline).getLatLngAltArray();
			// @ts-ignore
			const sectionLatLngAltArray = convertArrayToCoordinates(H.geo.LineString.fromFlexiblePolyline(section.polyline).T);
			result = [...result, ...sectionLatLngAltArray];
		});
		return result;
	}

	const convertArrayToCoordinates = (inputArray: number[]) => {
		let result = [];
		for (let i = 0; i < inputArray.length; i += 3) {
			if (inputArray[i + 2] === 0) {  // Check if the third item is 0, which it should be according to your pattern
				result.push([inputArray[i], inputArray[i + 1]]);
			}
		}
		return result;
	}

	return (
		<MapComponentLeaflet polyline={getPolylines()}/>
	);

}

interface MapComponentLeafletProps {
	polyline: number[][];
}

export function MapComponentLeaflet(props: MapComponentLeafletProps) {
	const customSvgIcon = (color: string) => {
		const svgIcon = `
      	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="32" height="32" fill="${color}">
  			<path d="M172.268 501.67C29.26 311.042 0 272.806 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 80.806-29.26 119.042-172.268 309.67-9.516 13.517-29.948 13.519-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"/>
		</svg>`;

		return L.divIcon({
			html: svgIcon, // Inject the SVG as an HTML string
			className: '', // Remove default Leaflet styles
			iconSize: [32, 32], // Size of the icon
			iconAnchor: [16, 32], // Anchor point (center bottom)
		});
	};

	const startIcon = customSvgIcon('blue');   // Red circle for start point
	const endIcon = customSvgIcon('green');   // Green circle for end point

	const startPoint = props.polyline[0] as [number, number];
	const endPoint = props.polyline[props.polyline.length - 1] as [number, number];

	return (
		<MapContainer center={[0, 0]} zoom={13} style={{ height: '100vh', width: '100%' }}>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
			/>
			{/*// @ts-ignore*/}
			<Polyline pathOptions={{ color: 'red' }} positions={props.polyline} />
			{/* Start Marker */}
			{startPoint && (
				<Marker position={startPoint} icon={startIcon}>
					<Popup>Start Point</Popup>
				</Marker>
			)}

			{/* End Marker */}
			{endPoint && (
				<Marker position={endPoint} icon={endIcon}>
					<Popup>End Point</Popup>
				</Marker>
			)}
			<SetupMap polyline={props.polyline} />
		</MapContainer>
	);
}

export function SetupMap(props: MapComponentLeafletProps) {
	const map = useMap();

	useEffect(() => {
		if (props.polyline && props.polyline.length > 0) {
			// @ts-ignore
			const bounds = L.latLngBounds(props.polyline);
			map.fitBounds(bounds);
		}
	}, [map, props.polyline]);

	return (<></>);
}