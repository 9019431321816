import {Box, Button, createStyles, Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography} from '@material-ui/core';
import {Context, FormButton, FormDialog, FormInputType, useForm} from '../../../../raal_components/form/Form';
import {exist} from '../../../../../common/utils/Util';
import React, {PropsWithChildren, useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {HereProfilVozidla, HereProfilVozidlaFilter} from '../../../../model/HereProfilVozidla';
import {useFetchCustom} from '../../../../../common/utils/HttpUtils';
import {Dial} from '../../../../raal_components/dial/Dial';
import {CustomRoute, HereKilometrovnikForm} from './HereKilometrovnikForm';
import {RouteComponentProps} from '../../../../routes';
import {Callback, Callbacks, ConfirmOptions} from "../../../../raal_components/ConfirmDialog";
import {dispatchModal, ModalActionType} from "../../../../../common/component/ModalContainer";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import {StandaloneField} from "../../../../../common/component/form/StandaloneField";
import {FieldError} from "../../../../../common/component/form/ValidationError";
import {CodeBookControllerExposed} from "../../../../raal_components/controller/CodeBookController.d";
import {DataGridExposed} from "../../../../raal_components/grid/DataGrid";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core/styles";
import {FormButtonProps} from "../../../../raal_components/form/FormButton";

export function HereKilometrovnik(props: RouteComponentProps) {
    const {t} = useTranslation();
    const {fetch: hereFetch} = useFetchCustom<HereProfilVozidla>({endpoint: 'user/hereprofilvozidla/default-kilometrovnik'}, undefined, HereProfilVozidla);
    const [routeName, setRouteName] = useState();
    const dialRef = useRef<CodeBookControllerExposed>();
    const dtGrid = useRef<DataGridExposed<CustomRoute, HereProfilVozidlaFilter>>();
    const formRef = useRef();
    // Zamedzuje rerenderom pri ktualnej implementacii - iba hotfix
    const routeNameRef = useRef<string>('')
    const setName = (name: string) => {
        routeNameRef.current = name;
    }

    const dialog: FormDialog = {
        body: t("Default.DeleteText"),
        title: t("Default.DeleteTitle"),
        buttons: {confirm: t("Buttons.Delete")}
    };

    return <Dial<CustomRoute, HereProfilVozidlaFilter>
                focusFieldWhenMounted
                dialRef={dialRef}
                gridRef={dtGrid}
                mode={props.mode}
                firstTabName={() => t("Here.CustomRoute.FirstTab")}
                localization={
                    {
                        CreateRecordAsNew: t("Here.Dial.CreateRecordAsNew"),
                        CreateRecord: t("Here.Dial.CreateRecord"),
                        UpdateRecord: t("Here.Dial.UpdateRecord"),
                        DeleteRecord: t("DialDefaults.DeleteRecord")
                    }
                }
                onDetailLoaded={(data) => {
                    if (!data.via) {
                        data.via = [];
                    }
                    data.via.unshift({via: data.odkud, latitude: data.odkudLatitude, longitude: data.odkudLongitude});
                    data.via.push({via: data.kam, latitude: data.kamLatitude, longitude: data.kamLongitude});
                }}
                validate={(r) => {
                    // TODO přidat typescript, případně vymyslet jak jinak ignorovat validaci při vyčištění formuláře
                    // @ts-ignore
                    const ignoreValidation: boolean = formRef?.current?.ignoreValidation;
                    const errors = [] as FieldError [];
                    if ((r.odkud == undefined || r.kam == undefined) && (r.via == undefined || r.via?.length < 2) && !ignoreValidation) {
                        errors.push(FieldError.Create(t("Here.CustomRoute.Validation.VyplnitAlesponDveMista"), false, "viaInput"));
                    }
                    if (ignoreValidation) {
                        // @ts-ignore
                        formRef?.current?.setIgnoreValidation(false);
                    }
                    return errors;
                }}
                config={
                    {
                        id: 'here-custom-route',
                        clazz: CustomRoute,
                        cache: false,
                        endpoint: 'user/here-custom-route',
                        tableTitle: t('Here.CustomRoute.Dial.TitulekTabulky'),
                        requiredColumns: ['titulek'],
                        minimumTableHeight: 500,
                        columns: [
                            {
                                title: t('Here.CustomRoute.Titulek'),
                                field: 'titulek',
                                defaultSort: 'asc',
                                filterProps: () => ({type: FormInputType.Text}),
                            },
                            {
                                title: t('Here.CustomRoute.Dial.Odkud'),
                                field: 'odkud',
                                filterProps: () => ({type: FormInputType.Text}),
                            },
                            {
                                title: t('Here.CustomRoute.Dial.Kam'),
                                field: 'kam',
                                filterProps: () => ({type: FormInputType.Text}),
                            },
                            {
                                title: t('Here.CustomRoute.Dial.ProfilVozidla'),
                                field: 'hereProfilVozidla.nazev',
                                sorting: false,
                                filterProps: () => ({type: FormInputType.Text}),
                            },
                        ],
                    }
                }
                createInstance={async () => {
                    const cr = new CustomRoute();
                    try {
                        const d = await hereFetch();
                        if (exist(d?.id)) {
                            cr.hereProfilVozidla = {id: d.id, nazev: d.nazev, data: d};
                        } else {
                            // @ts-ignore
                            cr.hereProfilVozidla = {};
                        }
                    } catch (error) {
                        console.error("Error fetching data:", error);
                        // @ts-ignore
                        cr.hereProfilVozidla = {};
                    }
                    return cr;
                }}
                allowDelete={true}
                hideSaveButton={true}
                customButtons={(edited, data, changed, disabled, a, b) =>
                    [!data.id &&
                    <KilometrovnikNewButton routeName={routeNameRef} setRouteName={setName} buttonTitle={t('Here.CustomRoute.Button.SaveRoute')}/>,
                        // @ts-ignore
                        !data.id && <FormButton key={"cleanRoute"} skipBlock type={"new"}
                                                onClick={
                                                    () => {
                                                        // @ts-ignore
                                                        formRef.current?.clearForm();
                                                        setRouteName(null);
                                                    }
                                                }>
                            {t('Here.CustomRoute.Button.CleanForm')}
                        </FormButton>,
                        edited && <FormButton key={"editRoute"}
                                              main={edited} type={"save"}
                                              skipBlock={false}
                                              onSend={(form) => {
                                                  return {
                                                      modifyUrl: (url: string) => `${url}/${data.id}`
                                                  };
                                              }}>
                            {t('Here.CustomRoute.Button.SaveRoute')}
                        </FormButton>,
                        data.id && <KilometrovnikNewButton routeName={routeNameRef} setRouteName={setName} buttonTitle={t('Here.CustomRoute.Button.UlozitJakoNovouTrasu')}/>
                    ]}
                beforeSend={(data) => {
                    if (routeNameRef.current) {
                        data.titulek = routeNameRef.current;
                    }
                    if (data?.via?.length >= 2) {
                        data.odkud = data?.via?.[0]?.via;
                        data.odkudLatitude = data?.via?.[0]?.latitude;
                        data.odkudLongitude = data?.via?.[0]?.longitude;
                        data.kam = data?.via?.[data?.via?.length - 1]?.via;
                        data.kamLatitude = data?.via?.[data?.via?.length - 1]?.latitude;
                        data.kamLongitude = data?.via?.[data?.via?.length - 1]?.longitude;
                        data?.via?.shift();
                        data?.via?.pop();
                    } else {
                        data.odkud = null;
                        data.odkudLatitude = null;
                        data.odkudLongitude = null;
                        data.kam = null;
                        data.kamLatitude = null;
                        data.kamLongitude = null;
                    }
                }}
                // preventCloseAfterSave={id === 'new'}
                crudConfig={{editEnabled: true, removeEnabled: true, addEnabled: false}}
                layoutForm={() => {
                    return <HereKilometrovnikForm ref={formRef}/>
                }}
            />;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
);

type KilometrovnikNewButtonProps = {
    routeName?: React.MutableRefObject<string>;
    setRouteName?: (name: string) => void;
    buttonTitle?: string;
};

export function KilometrovnikNewButton<CustomOptions = any>(props: PropsWithChildren<FormButtonProps<CustomOptions>> & KilometrovnikNewButtonProps) {
    const {t} = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);
    const classes = useStyles();
    const [error, setError] = useState(undefined);
    const form = useForm<CustomRoute>();
    const [traceName, setTraceName] = useState<string>('');
    const [focused, setFocused] = useState(false);

    return <>
        <Tooltip title={''}>
            <span>
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation={false}
                    disableRipple
                    style={{marginLeft: 10, marginBottom: 10}}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (form.validate()) {
                            setOpenDialog(true);
                            setFocused(true);
                        }
                    }}>
                    {props.buttonTitle}
                </Button>
            </span>
        </Tooltip>
        <Dialog
            open={openDialog}
            onClose={() => {
                props.setRouteName(undefined);
                setOpenDialog(false)
            }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="h6" color={"inherit"}>{t('Here.CustomRoute.Dial.ZadejteNazevTrasy')}</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={(e) => {
                    e.stopPropagation();
                    props.setRouteName(undefined);
                    setOpenDialog(false);
                }}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <Box m={1}>
                        <Grid container direction="column">
                            <Grid item>
                                <StandaloneField focused={focused} type={FormInputType.Text} onValueChanged={value => {
                                    setTraceName(value)
                                }} value={traceName} variant={"outlined"} title={t('Here.CustomRoute.Titulek')} error={error}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box>
                        <Box display="flex" flexDirection="row" justifyContent={"flex-end"}>
                            <Box p={1}>
                                <Button
                                    variant="contained"
                                    color="default"
                                    disableElevation
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.setRouteName(undefined);
                                        setOpenDialog(false);
                                    }}
                                    disableRipple>
                                    {t("Buttons.Cancel")}
                                </Button>
                            </Box>
                            <Box p={1}>
                                <FormButton key={"newRoute"}
                                            skipBlock
                                            type={"update"}
                                            main={true}
                                            disabled={!props.routeName}
                                            onSend={() => {
                                                props.setRouteName(traceName);
                                                return {
                                                    modifyUrl: (url: string) => `${url}/new`,
                                                    skipValidation: false,
                                                }
                                            }}>
                                    {t('Here.CustomRoute.Button.SaveRoute')}
                                </FormButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    </>
}