import {CustomFieldComponentProps} from "./FormFieldInterface";
import {FormInputType} from "../../../web/raal_components/form/Form";
import {useRangeFocusable} from "./FormRange";
import React, {useEffect, useImperativeHandle, useState} from "react";
import {Box, Grid} from "@material-ui/core";
import {StandaloneField} from "./StandaloneField";
import {formatForRaalUsers, FormNominatim} from "./FormNominatim";
import {Waypoint} from "../../../web/model/Waypoint";
import {invoke} from "../../utils/Invoke";
import _ from "lodash";
import {ChildComponentProps, Vicinity, VicinityOptions} from "./FormVicinityContainer";
import {exist} from "../../utils/Util";
import {useMpzResolver} from "../../utils/MpzUtils";

type FVExtension  = {
    customComponentOptions?: {
        okoliDisabled?: boolean
    }
}

type localVicinity = {
    osmPlace: string | null,
    radius: string | null
}

export const FormVicinity = (props:CustomFieldComponentProps<Vicinity, VicinityOptions>&ChildComponentProps & FVExtension) => {
    const textFieldProps = {style:{maxWidth:'100%'}};
    const options = typeof props.options === 'function' ? props.options() : props.options;
    const [focused, onFieldFocused] = useRangeFocusable(props);
    const [resolveMpz] = useMpzResolver();

    const isInForm = options.filterForm ?? false;
    // @ts-ignore
    const nullVicinity: localVicinity = {osmPlace: null, radius: null};
    const osmPlaceResult = props.value?.osmPlace ?? props.data?.[props.name]?.osmPlace ?? null;
    const radiusResult = props.value?.radius ?? props.data?.[props.name]?.radius ?? null

    const [localVicinity, setLocalVicinity] = useState<{osmPlace: string | null, radius: string | null}>(nullVicinity);

    useEffect(() => {
        setLocalVicinity({osmPlace: osmPlaceResult, radius: radiusResult})
    }, [props.data, props.value]);

    const defaultValue: Vicinity = {
        psc: null,
        mpz: null,
        nazevMista: null,
    }

    const onChange = (v?: Vicinity, isUserClearOkoli: boolean = false) => {
        props.onValueChanged(v)
        invoke(options.onChange, v, isUserClearOkoli);
    }

    useImperativeHandle(props.componentRef, () => {
        return {
            selectFirstField: () => onFieldFocused(1),
            onChange
        }
    })

    return (
        <>
            <Grid item lg={12} sm={12} xs={12}>
                <Box style={{width:'100%'}}>
                    <StandaloneField
                        title={options?.showTitle&&options?.titleOkoli}
                        placeholder={options?.titleOkoli}
                        type={"Custom"}
                        customComponent={FormNominatim}
                        focused={focused===1}
                        customComponentOptions={{
                            checkLicense: true,
                            countryCodeForCompare: options?.countryCodeForCompare,
                            autofillValue: true,
                            onChange: (osmPlace: any) => {
                                const waypoint = osmPlace?.name ? Waypoint.fromOsmPlace(osmPlace) : null;
                                const tempValue: Vicinity = waypoint ? {
                                    ...props.value,
                                    ...defaultValue,
                                    osmPlace: formatForRaalUsers(osmPlace, () => resolveMpz(osmPlace.address?.country_code)),
                                    countryCode: waypoint?.countryCode,
                                    koordinat: _.isEmpty(waypoint.koordinat) ? null : waypoint.koordinat,
                                    radius: null
                                } : null
                                onChange(tempValue);
                                if(tempValue == null) {
                                    setLocalVicinity(nullVicinity);
                                    return;
                                }
                                setLocalVicinity({...localVicinity, osmPlace: tempValue.osmPlace})
                            }
                        }}
                        onBlur={()=>{
                            invoke(props.onBlur);
                        }}
                        variant={props.variant ?? "outlined"}
                        onKeyDown={props.onKeyDown}
                        showAdornment={props.showAdornment}
                        inputClassName={props.inputClassName}
                        textFieldProps={textFieldProps}
                        value={localVicinity?.osmPlace}
                        onFocus={(o, userFocus) => {
                            if(userFocus) {
                                onFieldFocused(1);
                            }
                        }}
                    />
                </Box>
            </Grid>
            <div style={{height: options?.verticalSpace ?? 8}}/>
            <Grid item lg={12} sm={12} xs={12}>
                <Box style={{width:'100%'}}>
                    <StandaloneField
                        focused={focused===2}
                        numberProps={options?.numberProps}
                        onBlur={()=>{
                            invoke(props.onBlur);
                        }}
                        variant={props.variant ?? "outlined"}
                        onKeyDown={(e) => {
                            // Rozlisujeme medzi riadkovym a rozsirenym filtrom
                            if (isInForm) {
                                if (!props.data[props.name] || !osmPlaceResult) {
                                    e.preventDefault();
                                    return;
                                }
                                props.onKeyDown&&props.onKeyDown(e);
                            }
                            else {
                               if (!exist(props.value?.koordinat)) {
                                    e.preventDefault();
                                    return;
                                }
                                props.onKeyDown&&props.onKeyDown(e);
                            }
                        }}
                        type={FormInputType.Number}
                        showAdornment={props.showAdornment}
                        inputClassName={props.inputClassName}
                        value={localVicinity.radius}
                        onValueChanged={v => {
                            let tmpValue: Vicinity | null;
                            if (isInForm) { // AK JE FORMULAR
                                if (!props.data[props.name]) {
                                    tmpValue = null;
                                } else {
                                    tmpValue = {
                                        ...props.data[props.name],
                                        radius: v
                                    }
                                }
                            } else { // AK JE RIADKOVY FILTER
                                tmpValue = exist(props.value?.koordinat) ? {
                                    ...props.value,
                                    ...defaultValue,
                                    radius: v
                                } : {...props.value}
                            }
                            const isUserClearOkoli = !v;
                            onChange(tmpValue, isUserClearOkoli);
                            setLocalVicinity({...localVicinity, radius:v})
                        }}
                        disabled={props.customComponentOptions?.okoliDisabled || options?.okoliDisabled}
                        title={options?.showTitle&&options?.titleRadius}
                        placeholder={props.customComponentOptions?.okoliDisabled ? '' : options?.titleRadius}
                        textFieldProps={textFieldProps}
                        onFocus={(o, userFocus) => {
                            if(userFocus) {
                                onFieldFocused(2);
                            }
                        }}
                    />
                </Box>
            </Grid>
        </>
    );
}
