import {useTranslation} from "react-i18next";
import React, {Ref, useEffect, useImperativeHandle, useState} from "react";
import {Form, FormButton} from "../../../../raal_components/form/Form";
import {PrejezdFilterPart, PrejezdFilterPartHelp} from "./PrejezdyTransportNabidkaDialCommon";
import {MuiModal} from "../../../../../common/component/MuiModal";
import {PrejezdFilter, PrejezdFilterZadani} from "../../../../model/PrepravaVozidlo";
import {Button, Grid, useMediaQuery} from "@material-ui/core";
import {KilometrovnikLinkType} from "./KilometrovnikLinkPart";
import {Waypoint} from "../../../../model/Waypoint";
import DataStorage from "../../../../../common/DataStorage";
import {invoke} from "../../../../../common/utils/Invoke";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {Vozidlo} from "../../../../model/Vozidlo";
import {Preprava} from "../../../../model/Preprava";
import {cloneClassObject} from "../../../../../common/utils/Util";
import {
    FormSelectPopupItem,
} from "../../../../../common/component/form/FormSelectPopup";
import {useFilterSelectPopup} from "../../../../raal_components/form/FormFilter";
import {useTemplates} from "../../../../raal_components/grid/MTOverrides";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {convertFilterData} from "./PrejezdyDefault";
import {useHistoryCustom} from "../../../../raal_components/controller/NavigationHelper";

export type ModalPrejezdFilterExposed = {
    setModalVisibility: (state: boolean) => void
}

type ModalPrejezdFilterProps = {
    onClose?: ()=> void
    data?: Preprava | Vozidlo
    excludedNabidkaId?: number
    nabidkaId?: number
    filter?: PrejezdFilterZadani
    type: KilometrovnikLinkType
    switchType?: boolean
    redirectAfterSend?: boolean
    onSend?: ()=> void
    modalRef?: Ref<ModalPrejezdFilterExposed>
}

type ModalPrejezdFilterFormProps = {
    onSend?: ()=> void
    onClose?: () => void
    filter: PrejezdFilterZadani
    type: KilometrovnikLinkType
    redirectAfterSend?: boolean
    switchType?: boolean
    data?: Preprava | Vozidlo
    isModal?: boolean
}

export function ModalPrejezdFilter(props:ModalPrejezdFilterProps) {
    const {t} = useTranslation();
    const {onClose, data, redirectAfterSend, switchType, modalRef} = props;
    const [filter, setFilter] = useState<PrejezdFilterZadani>(new PrejezdFilterZadani());
    const [modalOpen, setModalOpen] = useState(false);

    useImperativeHandle(modalRef, () => ({
        setModalVisibility
    }));

    const setModalVisibility = (state: boolean) => {
        setModalOpen(state)
    }

    useEffect(() => {
        if (data) {
            const prejezdFilter = new PrejezdFilterZadani()
            const misto = data.getMista();
            prejezdFilter.prejezdOdWaypoint = misto[0] ? Waypoint.fromMisto(misto[0]) : null;
            prejezdFilter.prejezdKamWaypoint =  misto[1] ? Waypoint.fromMisto(misto[1]) : null;
            prejezdFilter.excludedNabidkaId = props.excludedNabidkaId;
            prejezdFilter.nabidkaId = props.nabidkaId;

            setFilter(prejezdFilter)
        }
        if (props.filter) setFilter(props.filter)
        // eslint-disable-next-line
    }, [data])

    const getTitle = () => {
        if (props.type===KilometrovnikLinkType.PREPRAVA){
            return switchType ? t("Prejezdy.vyhledaniVozu") : t("Prejezdy.vyhledaniPrepravy");
        } else {
            return switchType ? t("Prejezdy.vyhledaniPrepravy") : t("Prejezdy.vyhledaniVozu");
        }
    }

    return (
        <MuiModal
            open={modalOpen}
            onClose={() => {
                invoke(onClose);
                setModalOpen(false)
            }}
            maxWidth={"md"}
            title={getTitle()}
        >
            {modalOpen ? <ModalPrejezdFilterForm {...props} filter={filter} isModal={true} onClose={() => {
                setModalOpen(false);
                invoke(onClose)
            }} redirectAfterSend={redirectAfterSend}/> : null}
        </MuiModal>
    )
}

export const ModalPrejezdFilterForm = (props:ModalPrejezdFilterFormProps) => {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const {filter, redirectAfterSend, onSend, onClose} = props;
    const [searchButton, setSearchButton] = useState(true)
    const dataGridStorageKey = 'datagrid_user/prepravaview';
    const {replace} = useHistoryCustom();
    const isWideScreen = useMediaQuery("(min-width:870px)");

    useEffect(() => {
        setSearchButton(!(filter.prejezdOdWaypoint && filter.prejezdKamWaypoint))
        // eslint-disable-next-line
    }, [])


    const getLink = () => {
        if (props.type===KilometrovnikLinkType.PREPRAVA){
            return props.switchType ? 'volnevozy' : 'prepravy';
        } else {
            return  props.switchType ? 'prepravy' : 'volnevozy';
        }
    }

    const getStorage = () => {
        if (props.type===KilometrovnikLinkType.PREPRAVA){
            return props.switchType ? 'vozidel' : 'preprav';
        } else {
            return  props.switchType ?  'preprav' : 'vozidel';
        }
    }


    const fns = useTemplates<PrejezdFilter>(undefined, PrejezdFilter);
    const storageKey = `filter_prejezdy-${getStorage()}`;

    const showPopup = useFilterSelectPopup({
        typ: props.type===KilometrovnikLinkType.PREPRAVA ? "user/preprava-prejezd" : "user/vozidlo-prejezd",
        templateFunctions: fns,
        setSelectedValue: (filter: FormSelectPopupItem) => {
            openSearchWithFilters(fns.getMappedFilters(filter.data), {id: filter.value, name: filter.label});
        }
    });

    const openSearchWithFilters = (data: PrejezdFilter, filter?: {id: any, name: string}) => {
        if(filter) {
            DataStorage.set(`${storageKey}-filter`, JSON.stringify(filter), true, 'session');
        } else {
            DataStorage.clear(`${storageKey}-filter`, true, 'session');
        }

        if (redirectAfterSend) {
            DataStorage.set(storageKey, getFormDataWithDefaultValues(data),  true, 'session');
            DataStorage.redirectWithTargetBlank(`/prejezdy/${getLink()}`);
            DataStorage.clear(storageKey, true, 'session');
            invoke(onClose);
            //push(`/prejezdy/${getLink()}`);
        } else {
            const storageKey = `filter_prejezdy-${getStorage()}`;
            DataStorage.set(storageKey, getFormDataWithDefaultValues(data),  true, 'session');
            replace(`/prejezdy/${getLink()}`);
            invoke(onSend);
        }
    }

    const getFormDataWithDefaultValues = (formData: PrejezdFilter) => {
        let data = cloneClassObject(formData);
        if (formData.nabidkaId == null){
            const filterData = JSON.parse(DataStorage.get(dataGridStorageKey, true, 'session'));
            if(data.delka == null) data.delka = filterData?.current?.filters?.delkaRange?.max;
            if(data.vaha == null) data.vaha = filterData?.current?.filters?.vahaRange?.max;
        } else {
            if(data.delka == null) data.delka = props.data?.delka;
            if(data.vaha == null) data.vaha = props.data?.vaha;
        }
        return JSON.stringify(data);
    }

    return <Form<PrejezdFilterZadani>
            data={filter}
            focusFieldWhenMounted={true}
            onSend={(form) => {
                openSearchWithFilters(convertFilterData(form.data));
                return null;
            }}
            onChange={(form) => {
                setSearchButton(!(form.data.prejezdOdWaypoint && form.data.prejezdKamWaypoint))
            }}
            localization={t("FormLocalization", {returnObjects: true})}
            simpleLabel
        >
            <>
                {!props.isModal && <Grid container item lg={12} md={12} sm={12} style={
                    // #4652 - pridano kvuli tomu, ze na male zobrazovaci plose, ktera je mensi na sirku nez 870px se prekryval text Prejezdy preprav/vozidel a tlacitko pro ulozene filtry
                    isWideScreen
                        ? {   marginTop: "-3.75rem" }
                        : {}
                } justifyContent={"flex-end"}>
                    <Button style={{marginLeft: "10px", marginBottom: "10px"}} color="primary" variant="contained" onClick={() => {
                        showPopup(null).then();
                    }}><FilterAltIcon style={{marginRight: 5}}/>{t("Filter.SavedFilters")}</Button>
                    <FormButton disabled={searchButton}>{t("Prejezdy.calculate")}</FormButton>
                </Grid>}
                <PrejezdFilterPart />
                <div className="modal-buttons">
                    <Grid container item lg={12} md={12} sm={12} style={{marginTop: "10px"}} justifyContent={"flex-end"}>
                        <FormButton disabled={searchButton}>{t("Prejezdy.calculate")}</FormButton>
                    </Grid>
                </div>
                <PrejezdFilterPartHelp />
            </>
        </Form>
}
