import { SelectProps } from '../../common/component/form/FormSelect';
import { GenericMap } from '../../index.d';
import { useTranslation } from 'react-i18next';

export class HereProfilVozidlaSelect {
	id: number;
	nazev: string;
}

export class HereProfilVozidlaFilter {
	nazev: string;
}

// Hlavní datový objekt profilu vozidla pro Here kilometrovník.
export class HereProfilVozidla {
	id: number;
	nazev: string;
	defaultInPreprava: boolean;
	defaultInVozidlo: boolean;
	defaultInKilometrovnik: boolean;

	// Currency code compliant to ISO 4217. Costs for the calculated route will be returned using this currency.
	// If not provided, the router will specify it. On a best-effort basis, the router will try to specify the costs in the local currency.
	currency: EuropeanCurrencyCodeEnum;

	vehicle: Vehicle; // Vehicle-specific parameters.

	// Avoid specific features (ferries,tollRoads) ???
	// avoidFeaturesArray: AvoidFeatureEnum[];
	avoidFeatures: string;

	tollEmissionType: EmissionTypeEnum;
	tollEmissionTypeCO2EmissionClass: CO2EmissionClassEnum;

	// exclude[countries]=CHE - Exclude the country of Switzerland from the route calculation
	// exclude[countries]=CHE,AUT - Exclude both the country of Switzerland and the country of Austria from the route calculation
	// Defines properties which will be strictly excluded from route calculation.
	// Note - Exclude countries guarantees exclusion, but doesn't guarantee finding a route.
	// excludeCountriesArray: EuropeanCountryCodeAlpha3[];
	excludeCountries: string;

	// Default: ["en-US"]
	// Example: lang=zh-tw,en-gb,en-us
	// Specifies the list of preferred languages of the response. The first supported language from the list will be used for for the response if available.
	// The next languages are used as fallbacks if the first one is not available.
	// Additionally, some more fallback logic is applied if no language is available, like generalizing the requested language code.
	// The value should comply with the IETF BCP 47.
	// Note: If the first language in the list is not supported exactly, an info notification will be generated with code mainLanguageNotFound.
	lang: string[];

	casovyNaklad: number;
	nakladNaKm: number;
}

export type Vehicle = {
	height?: number; // Vehicle height, specified in centimeters (400).
	width?: number; // Vehicle width, specified in centimeters (250).
	length?: number; // Vehicle length, specified in centimeters (1650).
	grossWeight?: number; // Vehicle weight including trailers and shipped goods, specified in kilograms (30000).
	weightPerAxle?: number; // Vehicle weight per axle, specified in kilograms (12000).
	weightPerAxleGroup?: number; // Weight of different axle groups such as single and tandem axles, specified in kilograms.
	trailerCount?: number; // The number of trailers attached to the vehicle (1).
	axleCount?: number; // Total number of axles in the vehicle (6).
	trailerAxleCount?: number; // The total number of axles across all the trailers attached to the vehicle.
	tunnelCategory?: TunnelCategoryEnum; //  The tunnel category used to restrict the transport of specific goods (B).
	// A comma-separated list of hazardous goods being shipped in the vehicle (explosive).
	// hazardousGoodsArray?: HazardousGoodsEnum[];
	hazardousGoods?: string;
	type?: TruckTypeEnum; // The type of truck.
	payloadCapacity?: number; // The allowed payload capacity of the vehicle including trailers, specified in kilograms.
}
export const newVehicle = (): Vehicle => {
	return {
		height: null, // or a default value like 0, if applicable
		width: null, // or a default value
		length: null, // or a default value
		grossWeight: null, // or a default value
		weightPerAxle: null, // or a default value
		weightPerAxleGroup: null, // or a default value
		trailerCount: null, // or a default value like 0
		axleCount: null, // or a default value like 0
		trailerAxleCount: null, // or a default value like 0
		tunnelCategory: null, // assuming TunnelCategoryEnum has a 'None' or similar, or keep as null
		// hazardousGoodsArray: [], // Initialized as an empty array, assuming no hazardous goods selected by default
		hazardousGoods: null,
		type: null, // or a default TruckTypeEnum value, if applicable
		payloadCapacity: null, // or a default value like 0
	};
}

// tunnelCategory: The tunnel category used to restrict the transport of specific goods. The following values are possible:
export enum TunnelCategoryEnum {
	B = 'B',
	C = 'C',
	D = 'D',
	E = 'E'
}
export const useTunnelCategoryOptions = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
	return (
		{
			options: Object.values(TunnelCategoryEnum),
			isClearable: true,
			formatValue: (value: any) => value["value"],
			...props
		}
	);
};

// shippedHazardousGoods: A comma-separated list of hazardous goods being shipped in the vehicle. The following values are possible:
export enum HazardousGoodsEnum {
	// 	explosive: Materials that are capable of causing an explosion.
	explosive = 'explosive',
	// 	gas: Gas (definition varies from country to country). For details, check here.
	gas = 'gas',
	// 	flammable: Materials that can easily catch fire.
	flammable = 'flammable',
	// 	combustible: Materials that have the potential to burn.
	combustible = 'combustible',
	// 	organic: Organic materials or compounds.
	organic = 'organic',
	// 	poison: Substances that can cause harm or death when ingested, inhaled, or absorbed.
	poison = 'poison',
	// 	radioactive: Materials that emit radiation and pose potential health risks.
	radioactive = 'radioactive',
	// 	corrosive: Substances that can cause damage or destruction through chemical reactions.
	corrosive = 'corrosive',
	// 	poisonousInhalation: Materials that are toxic when inhaled.
	poisonousInhalation = 'poisonousInhalation',
	// 	harmfulToWater: Materials that can cause pollution or harm to water bodies.
	harmfulToWater = 'harmfulToWater',
	// 	other: Other types of hazardous materials not covered by the above categories.
	other = 'other',
}

// type: The type of truck. The following values are possible:
enum TruckTypeEnum {
	// 	straight: A truck on a single frame with a permanently attached cargo area.
	StraightTruck = 'straightTruck',
	// 	tractor: A towing vehicle that can pull one or more semi-trailers (also known as a semi-truck).
	Tractor = 'tractor',
}
export const useTruckTypeOptions = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
	const {t} = useTranslation();
	return (
		{
			options: Object.values(TruckTypeEnum),
			formatOption: (value: string) => {
				return {
					value: value,
					label: t(`Here.Vehicle.TypeEnum.${value}`)
				}
			},
			isClearable: true,
			formatValue: (value: any) => value["value"],
			...props
		}
	);
};

// EmissionType is specified as euro6, euro5, etc., with allowed values including [euro1, euro2, euro3, euro4, euro5, euro6, euroEev].
export enum EmissionTypeEnum {
	Euro1 = 'euro1',
	Euro2 = 'euro2',
	Euro3 = 'euro3',
	Euro4 = 'euro4',
	Euro5 = 'euro5',
	Euro6 = 'euro6',
	EuroEev = 'euroEev',
}
export const useEmissionTypeOptions = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
	const {t} = useTranslation();
	return (
		{
			options: Object.values(EmissionTypeEnum),
			formatOption: (value: string) => {
				return {
					value: value,
					label: t(`Here.TollEmission.Type.${value}`)
				}
			},
			isClearable: true,
			formatValue: (value: any) => value["value"],
			...props
		}
	);
};

// CO2EmissionClass is optional, specified as ;co2class=1, ;co2class=2, etc., with allowed values for co2class being [1, 2, 3, 4, 5].
export enum CO2EmissionClassEnum {
	Class1 = '1',
	Class2 = '2',
	Class3 = '3',
	Class4 = '4',
	Class5 = '5',
}
export const useCO2EmissionClassOptions = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
	return (
		{
			options: Object.values(CO2EmissionClassEnum),
			isClearable: true,
			formatValue: (value: any) => value["value"],
			...props
		}
	);
};

// Avoid specific features like `tunnel' or `ferry`
export enum AvoidFeatureEnum {
	tunnel = 'tunnel',
	ferry = 'ferry',
}

export enum EuropeanCurrencyCodeEnum {
	CZK = 'CZK', // Czech Republic Koruna
	EUR = 'EUR', // Euro used by Eurozone countries, Monaco, San Marino, Vatican, Andorra, Montenegro, Kosovo also use the Euro
	GBP = 'GBP', // United Kingdom Pound
	PLN = 'PLN', // Poland Zloty
	HUF = 'HUF', // Hungary Forint
	CHF = 'CHF', // Switzerland Franc
	NOK = 'NOK', // Norway Krone
	SEK = 'SEK', // Sweden Krona
	DKK = 'DKK', // Denmark Krone
	ISK = 'ISK', // Iceland Krona
	RON = 'RON', // Romania New Leu
	BGN = 'BGN', // Bulgaria Lev
	HRK = 'HRK', // Croatia Kuna
	RSD = 'RSD', // Serbia Dinar
	TRY = 'TRY', // Turkey Lira
	BAM = 'BAM', // Bosnia and Herzegovina Convertible Mark
	MKD = 'MKD', // Macedonia Denar
	BYN = 'BYN', // Belarus Ruble
	MDL = 'MDL', // Moldova Leu
	UAH = 'UAH', // Ukraine Hryvnia
	RUB = 'RUB', // Russia Ruble
	GEL = 'GEL', // Georgia Lari
	AZN = 'AZN', // Azerbaijan Manat
	AMD = 'AMD', // Armenia Dram
	ALL = 'ALL', // Albania Lek
}
export const useEuropeanCurrencyCodeOptions = (props:SelectProps& {params?:GenericMap} ={}):SelectProps => {
	const {t} = useTranslation();
	return (
		{
			options: Object.values(EuropeanCurrencyCodeEnum),
			formatOption: (value: string) => {
				return {
					value: value,
					label: t(`Here.Currency.Shortcut.${value}`)
				}
			},
			isClearable: true,
			formatValue: (value: any) => value["value"],
			...props
		}
	);
};

export enum EuropeanCountryCodeAlpha3 {
	CHE = 'CHE',
	ALB = 'ALB',
	AND = 'AND',
	ARM = 'ARM',
	AUT = 'AUT',
	BLR = 'BLR',
	BEL = 'BEL',
	BIH = 'BIH',
	BGR = 'BGR',
	HRV = 'HRV',
	CYP = 'CYP',
	CZE = 'CZE',
	DNK = 'DNK',
	EST = 'EST',
	FIN = 'FIN',
	FRA = 'FRA',
	GEO = 'GEO',
	DEU = 'DEU',
	GRC = 'GRC',
	HUN = 'HUN',
	ISL = 'ISL',
	IRL = 'IRL',
	ITA = 'ITA',
	XKX = 'XKX',
	LVA = 'LVA',
	LIE = 'LIE',
	LTU = 'LTU',
	LUX = 'LUX',
	MLT = 'MLT',
	MDA = 'MDA',
	MCO = 'MCO',
	MNE = 'MNE',
	NLD = 'NLD',
	MKD = 'MKD',
	NOR = 'NOR',
	POL = 'POL',
	PRT = 'PRT',
	ROU = 'ROU',
	RUS = 'RUS',
	SMR = 'SMR',
	SRB = 'SRB',
	SVK = 'SVK',
	SVN = 'SVN',
	ESP = 'ESP',
	SWE = 'SWE',
	TUR = 'TUR',
	UKR = 'UKR',
	GBR = 'GBR',
	VAT = 'VAT',
}

export type CarPlateDictionary = { [isoCode: string]: string };

export const carPlates: CarPlateDictionary = {
	"AUT": "A",  // Austria
	"BEL": "B",  // Belgium
	"CZE": "CZ", // Czech Republic
	"DNK": "DK", // Denmark
	"EST": "EST",// Estonia
	"FIN": "FIN",// Finland
	"FRA": "F",  // France
	"DEU": "D",  // Germany
	"GRC": "GR", // Greece
	"HUN": "H",  // Hungary
	"ISL": "IS", // Iceland
	"ITA": "I",  // Italy
	"LVA": "LV", // Latvia
	"LTU": "LT", // Lithuania
	"LUX": "L",  // Luxembourg
	"NLD": "NL", // Netherlands
	"NOR": "N",  // Norway
	"POL": "PL", // Poland
	"PRT": "P",  // Portugal
	"SVK": "SK", // Slovakia
	"SVN": "SLO",// Slovenia
	"ESP": "E",  // Spain
	"SWE": "S",  // Sweden
	"CHE": "CH", // Switzerland
	"GBR": "GB",  // United Kingdom
	"TUR": "TR"  // Turkey
};

export const countryCodesIso2ToIso3: { [key: string]: string } = {
	"AT": "AUT", // Austria
	"BE": "BEL", // Belgium
	"CZ": "CZE", // Czech Republic
	"DK": "DNK", // Denmark
	"EE": "EST", // Estonia
	"FI": "FIN", // Finland
	"FR": "FRA", // France
	"DE": "DEU", // Germany
	"GR": "GRC", // Greece
	"HU": "HUN", // Hungary
	"IS": "ISL", // Iceland
	"IT": "ITA", // Italy
	"LV": "LVA", // Latvia
	"LT": "LTU", // Lithuania
	"LU": "LUX", // Luxembourg
	"NL": "NLD", // Netherlands
	"NO": "NOR", // Norway
	"PL": "POL", // Poland
	"PT": "PRT", // Portugal
	"SK": "SVK", // Slovakia
	"SI": "SVN", // Slovenia
	"ES": "ESP", // Spain
	"SE": "SWE", // Sweden
	"CH": "CHE", // Switzerland
	"GB": "GBR", // United Kingdom
	"TR": "TUR"  // Turkey
};