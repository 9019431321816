import {Preprava} from "../../../../model/Preprava";
import {Vozidlo} from "../../../../model/Vozidlo";
import {useTranslation} from "react-i18next";
import React, {useCallback, useEffect, useState} from "react";
import {exist, formatPrice} from "../../../../../common/utils/Util";
import { Form } from '../../../../raal_components/form/Form';
import {Box, Card, CardContent, Grid, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {TextTuple, TextTupleType} from "../../../../../common/component/form/TextTuple";
import numeral from "numeral";
import {sizeIntegerFormat, sizeViewFormat} from './PrepravaAVozidlaShared';
import Avatar from '@material-ui/core/Avatar';
import {CheckMark} from "../../../../../common/component/CheckMark";
import {useCiselnikValues} from "../../../../context/DataContext";
import {CiselnikTyp} from "../../../../model/Ciselnik";
import {ZadavatelHeaderInfoView} from '../../ZadavatelHeaderInfoView';
import {ZadavatelBasicInfoView} from '../../ZadavatelBasicInfoView';
import {isTimeOutOfDetaults} from "./VPColumns";
import {Moment} from "moment";
import {useStyleContext} from "../../../../context/ThemeModeContext";
import {SecuredContentByRole} from "../../../../raal_components/security/SecuredContentByRole";
import {
    AttachMoney,
    Comment,
    Commute,
    CropFree,
    DateRange,
    Directions,
    EventAvailable,
    Extension,
    FitnessCenter,
    Flight,
    GetApp,
    LineWeight,
    LocalShipping,
    Place,
    SpaceBar,
    SpeakerNotesOff,
    SwapHoriz,
    SwapVert,
    SyncAlt
} from '@material-ui/icons';
import {FlagIcon} from '../../../../../common/component/FlagIcon';
import {useEvaluateFieldsVisibility} from '../../../../../common/logic/visibility-logic';
import {KontaktyView} from "../KontaktyView";
import {FormPartBox} from "../prohlizeni/FormPartBox";
import {KilometrovnikLinkPart, KilometrovnikLinkType} from "../kilometrovnik/KilometrovnikLinkPart";
import {useAppContext} from "../../../../context/AppContext";
import {LicenseType} from "../../../../model/CommonTypes";
import {hasLicense} from "../../../../../common/logic/permissions-logic";
import {Waypoint} from "../../../../model/Waypoint";
import {PrejezdKilometrovnikLinkPart} from "../kilometrovnik/PrejezdKilometrovnikLinkPart";
import {Geometry} from "../../../../model/Geometry";
import {DokladkaKilometrovnikLinkPart} from "../kilometrovnik/DokladkaKilometrovnikLinkPart";
import {OfferActionsPart} from "../prohlizeni/OfferActionsPart";
import {isPreprava, isVozidlo} from "./AssertPredicates";
import {OfferArchiveActionsPart} from "../prohlizeni/OfferArchiveActionsPart";
import {ArchivPoznamkyDial} from "../archiv/ArchivPoznamkyDial";
import {CrudOperationType, CrudUpdate, CrudUpdateEvent} from "../../../../model/CrudUpdate";
import {useStompSubscribe} from "../../../../../common/utils/Websocket";
import {useTheme} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import {Button} from "@mui/material";
import {httpEndpoint} from "../../../../../common/utils/HttpUtils";
import {ArchiveRecord} from "../prohlizeni/ModalArchiveViewRecord";
import {useHistory} from "react-router";
import {showSnack} from "../../../../../common/component/SnackContainer";
import {useConfirmDialog} from "../../../../raal_components/ConfirmDialog";
import {useCodeBookControllerContextNew} from "../../../../raal_components/controller/CodeBookController";
import {ExportButtons} from "../../../../raal_components/grid/MTExportContainer";
import {useCodeBookDetailContextNew} from "../../../../raal_components/controller/CodeBookDetail";
import {ComponentMode} from "../../../../routes";
import {DokladkaList} from "../../../../model/PrepravaVozidlo";
import {UserRole} from "../../../../model/User";

export function TupleDetails(props: { data: Preprava | Vozidlo, ciselnikTyp: CiselnikTyp, admin?: boolean }) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const {druhyJoined} = useCiselnikValues(CiselnikTyp.R);
    const zpusobNakladky = [
        props.data.nakladkaBokem && t("Preprava.nakladkaBokem"),
        props.data.nakladkaShora && t("Preprava.nakladkaShora"),
        props.data.nakladkaZezadu && t("Preprava.nakladkaZezadu") /* Bo=8  */].filter(i => i).join(", ");

    const doplnky = [
        props.data.naklProstorVcelku && t("Preprava.naklProstorVcelku"),
        props.data.zvedaciCelo && t("Preprava.zvedaciCelo"),
        props.data.druhyRidic && t("Preprava.druhyRidic"),
        props.data.zakazDokladky && t("Preprava.zakazDokladky"),
        props.data.adr && t("Preprava.adr"),
        props.data.hydraulickaRuka && t("Preprava.hydraulickaRuka"),
        props.data.nadrozmernyNaklad && t("Preprava.nadrozmernyNaklad")].filter(i => i).join(", ");
    const highlightTime = isTimeOutOfDetaults(props.data.datOd, props.data.datDo);
    const {user, hasUserRole} = useAppContext();
    const renderDateTime = (date?: Moment) => {
        return date ? (
            <>
                {date?.format("L")} <span
                className={highlightTime ? classes.coloredText : undefined}>{date?.format("LT")}</span>
            </>
        ) : null;
    };
	const getPrice = () => {
		const price = props.data.cena && props.data.currency?.currencyCode ? formatPrice(props.data.cena, props.data.currency.currencyCode) : props.data.cena ?? null;
		return props.data.jednotka ? price + "/" + props.data.jednotka : price
	}
    return (
        <>
            <Grid container item xs={12} spacing={3}>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar>
                            {props.data.regOd ?
                                <Avatar className={"avatar-flag"}><FlagIcon mpz={props.data.regOd}/></Avatar> :
                                <Avatar className={"avatar-offer"}><Place/></Avatar>}
                        </ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.odkud")} content={props.data.odkudHelper}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar>
                            {props.data.regKam ?
                                <Avatar className={"avatar-flag"}><FlagIcon mpz={props.data.regKam}/></Avatar> :
                                <Avatar className={"avatar-offer"}><Directions/></Avatar>}
                        </ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.kam")} content={props.data.kamHelper}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <SecuredContentByRole condition={user => user.canShowKmFeature()}>
                    <Grid item lg={3} sm={6} xs={12}>
                        <ListItem>
                            <ListItemAvatar><Avatar className={"avatar-offer"}><Flight/></Avatar></ListItemAvatar>
                            <ListItemText>
                                <TextTuple title={t("Preprava.AirDistance")}
                                           content={props.data.airDistance && numeral(props.data.airDistance).format(sizeIntegerFormat)}/>
                            </ListItemText>
                        </ListItem>
                    </Grid>
                </SecuredContentByRole>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><Commute/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple
                                title={`${t("RAALKratkyKody.N")} / ${t("RAALKratkyKody.S")} / ${t("RAALKratkyKody.J")}`}
                                content={<><CheckMark checked={props.data.naves}/> / <CheckMark
                                    checked={props.data.souprava}/> / <CheckMark checked={props.data.jine}/></>}
                                type={TextTupleType.Text}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><SyncAlt/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.delka")}
                                       content={props.data.delka && numeral(props.data.delka).format(sizeViewFormat)}
                                       type={TextTupleType.Number}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><FitnessCenter/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.vaha")}
                                       content={props.data.vaha && numeral(props.data.vaha).format(sizeViewFormat)}
                                       type={TextTupleType.Number}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><LocalShipping/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.druhy")} content={druhyJoined(props.data.druhy)}
                                       type={TextTupleType.Text}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><Comment/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.verejnaPozn")} content={props.data.verejnaPozn}
                                       type={TextTupleType.Text} highlightValue/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><DateRange/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.datOd")} content={renderDateTime(props.data.datOd)}
                                       type={TextTupleType.Text}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><DateRange/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.datDo")} content={renderDateTime(props.data.datDo)}
                                       type={TextTupleType.Text}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><AttachMoney/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.cena")}
                                       content={getPrice()}
                                       highlightValue/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><CropFree/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.objem")}
                                       content={props.data.objem && numeral(props.data.objem).format(sizeViewFormat)}
                                       type={TextTupleType.Number} highlightValue/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><LineWeight/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.palety")} content={props.data.palety}
                                       type={TextTupleType.Number} highlightValue/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><SwapHoriz/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.sirka")}
                                       content={props.data.sirka && numeral(props.data.sirka).format(sizeViewFormat)}
                                       type={TextTupleType.Number} highlightValue/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><SwapVert/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.vyska")}
                                       content={props.data.vyska && numeral(props.data.vyska).format(sizeViewFormat)}
                                       type={TextTupleType.Number} highlightValue/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><SpaceBar/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.lozPlocha")}
                                       content={props.data.lozPlocha && numeral(props.data.lozPlocha).format(sizeViewFormat)}
                                       type={TextTupleType.Number} highlightValue/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><EventAvailable/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.datumZalozeni")} content={props.data.datIns?.format("L LT")}
                                       type={TextTupleType.Text}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><EventAvailable/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("PVI.Modified")} content={props.data.modifiedOn?.format("L LT")}
                                       type={TextTupleType.Text}/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                {(props.data.deletedOn || props.data.datArch) &&
                    <Grid item lg={3} sm={6} xs={12}>
                        <ListItem>
                            <ListItemAvatar><Avatar className={"avatar-offer"}><EventAvailable/></Avatar></ListItemAvatar>
                            <ListItemText>
                                <TextTuple title={t("Preprava.datumArchivace")} content={(props.data.deletedOn ?? props.data.datArch)?.format("L LT")}
                                           type={TextTupleType.Text}/>
                            </ListItemText>
                        </ListItem>
                    </Grid>
                }
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><GetApp/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.zpusobNakladky")} content={zpusobNakladky}
                                       type={TextTupleType.Text} highlightValue/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                <Grid item lg={3} sm={6} xs={12}>
                    <ListItem>
                        <ListItemAvatar><Avatar className={"avatar-offer"}><Extension/></Avatar></ListItemAvatar>
                        <ListItemText>
                            <TextTuple title={t("Preprava.doplnky")} content={doplnky} type={TextTupleType.Text}
                                       highlightValue/>
                        </ListItemText>
                    </ListItem>
                </Grid>
                {(hasUserRole(UserRole.ROLE_ADMIN) || user.provozovna.id === props.data.uzivatel.provozovna.id) &&
                    <Grid item lg={3} sm={6} xs={12}>
                        <ListItem>
                            <ListItemAvatar><Avatar
                                className={"avatar-offer"}><SpeakerNotesOff/></Avatar></ListItemAvatar>
                            <ListItemText>
                                <TextTuple title={t("Preprava.neverPozn")} content={props.data.neverPozn}
                                           type={TextTupleType.Text} highlightValue/>
                            </ListItemText>
                        </ListItem>
                    </Grid>
                }
            </Grid>
        </>
    );

}

function ExtendedViewBody(props: { data: Preprava | Vozidlo, ciselnikTyp: CiselnikTyp, admin: boolean }) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const ev = useEvaluateFieldsVisibility(props.data.uzivatel.provozovna, props.data.uzivatel.provozovna?.firma, props.admin);
    return ev && props.data.uzivatel.provozovna && (
        <>
            <Card style={{height: "100%"}} className={classes.sectionBackground}>
                <CardContent style={{height: "100%", display: "flex", flexFlow: "column nowrap"}}>
                    <Box marginBottom={2}>
                        <Typography variant="h6">{t("Inzerce.OfferDetail")}</Typography>
                    </Box>
                    <Grid container spacing={1}>
                        <TupleDetails {...props}/>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

interface ExtendedViewPrejezdProps {
    trasa: Geometry[];
    prejezdOd: Waypoint;
    prejezdKam: Waypoint;
}

interface ExtendedViewDokladkaProps {
    trasa: Geometry[];
    nakladka: Waypoint;
    vykladka: Waypoint;
    dokladka?: DokladkaList,
}

interface ExtendedViewProps {
    data: Preprava | Vozidlo;
    ciselnikTyp: CiselnikTyp;
    admin?: boolean;
    prejezdProps?: ExtendedViewPrejezdProps;
    dokladkaProps?: ExtendedViewDokladkaProps;
    isSbernaSluzba?: boolean;
    archive?: boolean;
    viewArchive?: boolean;
    reload?: () => void
    history?: boolean
}

/**
 * TODO - Přepsáno do New, ale uvnitř není stejná logika jako v ExtendedViewNew, bude třeba ještě projít a sloučit.
 * @param props
 * @constructor
 */
export function ExtendedView(props: ExtendedViewProps) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const ev = useEvaluateFieldsVisibility(props.data.uzivatel?.provozovna, props.data.uzivatel?.provozovna?.firma, props.admin);
    const {user, setDataChanged} = useAppContext();
    const theme = useTheme();
    const [hasKilometrovnikLicense, setHasKilomentrovnikLicense] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [edited, setEdited] = useState(false);
    const [showConfirm] = useConfirmDialog();
    const {push} = useHistory();
    const {replaceDetailForm, exportConfig, dataGridRef} = useCodeBookControllerContextNew<Preprava | Vozidlo>()
    const {closeDetail} = useCodeBookDetailContextNew<Preprava | Vozidlo>()
	const [data, setData] = useState<any>({});

    const stompCallback = useCallback((data: CrudUpdate) => {
        const stompProcData = CrudUpdateEvent.of(data, true);
        setDisabled(stompProcData.crudOperationType === CrudOperationType.REMOVE && (stompProcData.entity?.id === props.data.id));
        setEdited(stompProcData.crudOperationType === CrudOperationType.UPDATE && (stompProcData.entity?.id === props.data.id));
        // eslint-disable-next-line
    }, []);

    const getStompTopic = (): string => {
        let type = '';
        if (props.data instanceof Preprava) type = 'preprava';
        if (props.data instanceof Vozidlo) type = 'vozidlo';
        return `/crud-${type}`
    }

    useStompSubscribe<CrudUpdate>(getStompTopic(), {clazz: CrudUpdate, callback: stompCallback, userOnly: false});

    useEffect(() => {
        setHasKilomentrovnikLicense(hasLicense(user, props.data.getMista()));
        // eslint-disable-next-line
    }, [props.data.id]);

    return (ev && props.data.uzivatel?.provozovna) ? (
        <div style={disabled ? {
            opacity: 0.9,
            padding: 20,
            backgroundColor: theme.palette.type === 'dark' ? "#222222" : "#dddddd",
            borderRadius: 10
        } : null}>
            {disabled || edited ? <Grid item style={{marginBottom: '1rem'}}><Alert
                action={
                    <Button color="inherit" size="small" onClick={() => {
                        if (edited) {
                            setEdited(false);
                            replaceDetailForm();
                            dataGridRef.current?.onConfirmChangedData(props.data);
                        } else {
                            closeDetail();
                        }
                    }}>
                        {edited ? t('Buttons.Refresh') : t('Buttons.Close')}
                    </Button>
                }
                severity={edited ? 'warning' : 'error'}>{edited ? t("FormLocalization.DataWasEdited") : t("FormLocalization.DataWasRemoved")}</Alert></Grid> : null
            }
            <div style={disabled ? {pointerEvents: 'none'} : null}>
                <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                    {(() => {
                        if ((props.data.rychlyKontakt != null || exist(props.data.uzivatel?.jmeno) || (props.data.dispecer != null && props.data.dispecer.id != null)) || ev(["provozovna.email", "provozovna.telCislaKontaktu"])) {
                            return <Grid item lg={9} sm={12} xs={12}>
                                <ExtendedViewBody ciselnikTyp={props.ciselnikTyp} data={props.data}
                                                  admin={props.admin}/>
                            </Grid>
                        } else {
                            return <Grid item lg={12} sm={12} xs={12}>
                                <ExtendedViewBody ciselnikTyp={props.ciselnikTyp} data={props.data}
                                                  admin={props.admin}/>
                            </Grid>
                        }
                    })()
                    }
                    <Grid item lg={3} sm={12} xs={12}>
                        <Card style={{height: "100%"}} className={classes.sectionBackground}>
                            <KontaktyView data={props.data} admin={props.admin}/>
                        </Card>
                    </Grid>
                    {!props.history && <>
                        {!props.archive && !exist(props.prejezdProps) && !exist(props.dokladkaProps) && hasKilometrovnikLicense && user.typLicence === LicenseType.WITHKM && (props.data.getMista()?.length ?? 0) > 1 ?
                            <Grid item xs={12}>
                                <FormPartBox title={t("Sections.Mileage")}>
									<Form<Preprava> disableFocusForSaveButton={true}
													blockSaveUntilChange={false}
													data={data}
													simpleLabel
													localization={t("FormLocalization", {returnObjects:true})}
									>
										<KilometrovnikLinkPart offerId={props.data.id}
															   linkType={props.data instanceof Preprava ? KilometrovnikLinkType.PREPRAVA : KilometrovnikLinkType.VOZIDLO}
															   checkIsEqual={() => setDataChanged(false)}
															   switchType={false}
															   hideDokladky={false}
															   isSbernaSluzba={props.isSbernaSluzba}
															   data={!exist(props.prejezdProps) && !exist(props.dokladkaProps) ? props.data : null}
										/>
									</Form>
                                </FormPartBox>
                            </Grid> : undefined
                        }

                        {!props.archive && hasKilometrovnikLicense && user.typLicence === LicenseType.WITHKM && (props.data.getMista()?.length ?? 0) > 1 && exist(props.prejezdProps) ?
                            <Grid item xs={12}>
                                <FormPartBox title={t("Prejezdy.kilometrovnikPrejezd")}>
                                    <PrejezdKilometrovnikLinkPart offerId={props.data.id}
                                                                  linkType={props.data instanceof Preprava ? KilometrovnikLinkType.PREPRAVA : KilometrovnikLinkType.VOZIDLO}
                                                                  trasa={props.prejezdProps.trasa}
                                                                  prejezdOd={props.prejezdProps.prejezdOd}
                                                                  prejezdKam={props.prejezdProps.prejezdKam}
                                                                  checkIsEqual={() => setDataChanged(false)}
                                                                  data={props.data}
                                    />
                                </FormPartBox>
                            </Grid> : undefined
                        }

                        {!props.archive && hasKilometrovnikLicense && user.typLicence === LicenseType.WITHKM && (props.data.getMista()?.length ?? 0) > 1 && exist(props.dokladkaProps) ?
                            <Grid item xs={12}>
                                <FormPartBox title={t("Dokladky.kilometrovnik")}>
                                    <DokladkaKilometrovnikLinkPart offerId={props.data.id}
                                                                   trasa={props.dokladkaProps.trasa}
                                                                   nakladka={props.dokladkaProps.nakladka}
                                                                   vykladka={props.dokladkaProps.vykladka}
                                                                   dokladka={props.dokladkaProps.dokladka}
                                                                   checkIsEqual={() => setDataChanged(false)}
                                    />
                                </FormPartBox>
                            </Grid> : undefined
                        }
                        {props.viewArchive && !props.admin ?
                            <Grid item lg={12} sm={12} xs={12}>
                                <FormPartBox title={t("Actions")}>
                                    <Grid item container spacing={1}>
                                        <Grid item>
                                            <Button variant={"contained"} color={"primary"}
                                                    onClick={() => {
                                                        showConfirm({
                                                            onConfirm: async () => {
                                                                const result = await httpEndpoint<ArchiveRecord>(ArchiveRecord, `user/${isPreprava(props.data) ? 'preprava' : 'vozidlo'}-prohlizeni-archiv/${props.data.id}`, {method: "DELETE"});
                                                                if (result.response.status === 200) {
                                                                    showSnack({
                                                                        title: t("FormLocalization.DataRemoved"),
                                                                        severity: "success"
                                                                    });
                                                                    push(`/archiv-prohlizeni/${isPreprava(props.data) ? 'prepravy' : 'volnevozy'}`);
                                                                } else {
                                                                    showSnack({
                                                                        title: t("Errors.CouldNotDelete"),
                                                                        severity: "error"
                                                                    });
                                                                }
                                                            },
                                                            body: t("Default.DeleteText"),
                                                            title: t("Default.DeleteTitle"),
                                                            buttons: {confirm: t("Buttons.Delete")}
                                                        });

                                                    }}>
                                                {t("Buttons.Delete")}</Button>
                                        </Grid>
                                        {exportConfig ?
                                            <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                                    </Grid>
                                </FormPartBox>
                            </Grid>
                            : null}
                        {!props.viewArchive && !props.admin &&
                            <Grid container item spacing={2} alignItems="stretch" direction="row"
                                  style={{paddingBottom: 8}}>
                                <Grid item xs>
                                    {isPreprava(props.data) ? props.archive ?
                                        <OfferArchiveActionsPart data={props.data}/> :
                                        <OfferActionsPart data={props.data}>
                                            {exportConfig ?
                                                <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                                        </OfferActionsPart> : undefined}
                                    {isVozidlo(props.data) ? props.archive ?
                                            <OfferArchiveActionsPart data={props.data}/> :
                                            <OfferActionsPart data={props.data}>
                                                {exportConfig ?
                                                    <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                                            </OfferActionsPart> :
                                        undefined
                                    }
                                </Grid>
                            </Grid>}
                    </>}
                    <ZadavatelHeaderInfoView userData={props.data.uzivatel} id={props.data.id} admin={props.admin}/>
                    <Grid item lg={12} sm={12} xs={12}>
                        <ZadavatelBasicInfoView userData={props.data.uzivatel} admin={props.admin}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    ) : <></>;
}

// Komponenta pro refactor - dočasná
export function ExtendedViewNew(props: ExtendedViewProps) {
    const {t} = useTranslation();
    const {classes} = useStyleContext();
    const ev = useEvaluateFieldsVisibility(props.data.uzivatel?.provozovna, props.data.uzivatel?.provozovna?.firma, props.admin);
    const {user, setDataChanged} = useAppContext();
    const theme = useTheme();
    const [hasKilometrovnikLicense, setHasKilomentrovnikLicense] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [edited, setEdited] = useState(false);
    const [showConfirm] = useConfirmDialog();
    const {push} = useHistory();
    const {replaceDetailForm, exportConfig, dataGridRef} = useCodeBookControllerContextNew<Preprava | Vozidlo>();
    const {closeDetail} = useCodeBookDetailContextNew<Preprava | Vozidlo>()
	const [data, setData] = useState<any>({});

    const stompCallback = useCallback((data: CrudUpdate) => {
        const stompProcData = CrudUpdateEvent.of(data, true);
        setDisabled(stompProcData.crudOperationType === CrudOperationType.REMOVE && (stompProcData.entity?.id === props.data.id));
        setEdited(stompProcData.crudOperationType === CrudOperationType.UPDATE && (stompProcData.entity?.id === props.data.id));
        // eslint-disable-next-line
    }, []);

    const getStompTopic = (): string => {
        let type = '';
        if (props.data instanceof Preprava) type = 'preprava';
        if (props.data instanceof Vozidlo) type = 'vozidlo';
        return `/crud-${type}`
    }

    useStompSubscribe<CrudUpdate>(getStompTopic(), {clazz: CrudUpdate, callback: stompCallback, userOnly: false});

    useEffect(() => {
        setHasKilomentrovnikLicense(hasLicense(user, props.data.getMista()));
        // eslint-disable-next-line
    }, [props.data.id]);

    return (ev && props.data.uzivatel?.provozovna) ? (
        <div style={disabled ? {
            opacity: 0.9,
            padding: 20,
            backgroundColor: theme.palette.type === 'dark' ? "#222222" : "#dddddd",
            borderRadius: 10
        } : null}>
            {disabled || edited ? <Grid item style={{marginBottom: '1rem'}}><Alert
                action={
                    <Button color="inherit" size="small" onClick={() => {
                        if (edited) {
                            setEdited(false);
                            replaceDetailForm();
                            dataGridRef.current?.onConfirmChangedData(props.data);
                        } else {
                            closeDetail();
                        }
                    }}>
                        {edited ? t('Buttons.Refresh') : t('Buttons.Close')}
                    </Button>
                }
                severity={edited ? 'warning' : 'error'}>{edited ? t("FormLocalization.DataWasEdited") : t("FormLocalization.DataWasRemoved")}</Alert></Grid> : null
            }
            <div style={disabled ? {pointerEvents: 'none'} : null}>
                <Grid container spacing={2} alignItems="stretch" direction="row" style={{paddingBottom: 4}}>
                    {(() => {
                        if ((props.data.rychlyKontakt != null || exist(props.data.uzivatel?.jmeno) || (props.data.dispecer != null && props.data.dispecer.id != null)) || ev(["provozovna.email", "provozovna.telCislaKontaktu"])) {
                            return <Grid item lg={9} sm={12} xs={12}>
                                <ExtendedViewBody ciselnikTyp={props.ciselnikTyp} data={props.data}
                                                  admin={props.admin}/>
                            </Grid>
                        } else {
                            return <Grid item lg={12} sm={12} xs={12}>
                                <ExtendedViewBody ciselnikTyp={props.ciselnikTyp} data={props.data}
                                                  admin={props.admin}/>
                            </Grid>
                        }
                    })()
                    }
                    <Grid item lg={3} sm={12} xs={12}>
                        <Card style={{height: "100%"}} className={classes.sectionBackground}>
                            <KontaktyView data={props.data} admin={props.admin}/>
                        </Card>
                    </Grid>
                    {!props.history && <>
                        {!props.archive && hasKilometrovnikLicense && user.typLicence === LicenseType.WITHKM && (props.data.getMista()?.length ?? 0) > 1 ?
                            <Grid item xs={12}>
                                <FormPartBox title={t("Sections.Mileage")}>
									<Form<Preprava> disableFocusForSaveButton={true}
													blockSaveUntilChange={false}
													data={data}
													simpleLabel
													localization={t("FormLocalization", {returnObjects:true})}
									>
										<KilometrovnikLinkPart offerId={props.data.id}
															   linkType={props.data instanceof Preprava ? KilometrovnikLinkType.PREPRAVA : KilometrovnikLinkType.VOZIDLO}
															   checkIsEqual={() => setDataChanged(false)}
															   switchType={false}
															   isSbernaSluzba={props.isSbernaSluzba}
															   data={props.data}
										/>
									</Form>
                                </FormPartBox>
                            </Grid> : undefined
                        }

                        {!props.archive && hasKilometrovnikLicense && user.typLicence === LicenseType.WITHKM && (props.data.getMista()?.length ?? 0) > 1 && exist(props.prejezdProps?.prejezdOd) && exist(props.prejezdProps?.prejezdKam) ?
                            <Grid item xs={12}>
                                <FormPartBox title={t("Prejezdy.kilometrovnikPrejezd")}>
                                    <PrejezdKilometrovnikLinkPart offerId={props.data.id}
                                                                  linkType={props.data instanceof Preprava ? KilometrovnikLinkType.PREPRAVA : KilometrovnikLinkType.VOZIDLO}
                                                                  trasa={props.prejezdProps.trasa}
                                                                  prejezdOd={props.prejezdProps.prejezdOd}
                                                                  prejezdKam={props.prejezdProps.prejezdKam}
                                                                  checkIsEqual={() => setDataChanged(false)}
                                                                  data={props.data}
                                    />
                                </FormPartBox>
                            </Grid> : undefined
                        }

                        {!props.archive && hasKilometrovnikLicense && user.typLicence === LicenseType.WITHKM && (props.data.getMista()?.length ?? 0) > 1 && exist(props.dokladkaProps?.nakladka) && exist(props.dokladkaProps?.vykladka) ?
                            <Grid item xs={12}>
                                <FormPartBox title={t("Dokladky.kilometrovnik")}>
                                    <DokladkaKilometrovnikLinkPart offerId={props.data.id}
                                                                   trasa={props.dokladkaProps.trasa}
                                                                   nakladka={props.dokladkaProps.nakladka}
                                                                   vykladka={props.dokladkaProps.vykladka}
                                                                   dokladka={props.dokladkaProps.dokladka}
                                                                   checkIsEqual={() => setDataChanged(false)}
                                    />
                                </FormPartBox>
                            </Grid> : undefined
                        }
                        {props.viewArchive && !props.admin ?
                            <Grid item lg={12} sm={12} xs={12}>
                                <FormPartBox title={t("Actions")}>
                                    <Grid item container spacing={1}>
                                        <Grid item>
                                            <Button variant={"contained"} color={"primary"}
                                                    onClick={() => {
                                                        showConfirm({
                                                            onConfirm: async () => {
                                                                const result = await httpEndpoint<ArchiveRecord>(ArchiveRecord, `user/${isPreprava(props.data) ? 'preprava' : 'vozidlo'}-prohlizeni-archiv/${props.data.id}`, {method: "DELETE"});
                                                                if (result.response.status === 200) {
                                                                    showSnack({
                                                                        title: t("FormLocalization.DataRemoved"),
                                                                        severity: "success"
                                                                    });
                                                                    push(`/archiv-prohlizeni/${isPreprava(props.data) ? 'prepravy' : 'volnevozy'}`);
                                                                } else {
                                                                    showSnack({
                                                                        title: t("Errors.CouldNotDelete"),
                                                                        severity: "error"
                                                                    });
                                                                }
                                                            },
                                                            body: t("Default.DeleteText"),
                                                            title: t("Default.DeleteTitle"),
                                                            buttons: {confirm: t("Buttons.Delete")}
                                                        });

                                                    }}>
                                                {t("Buttons.Delete")}</Button>
                                        </Grid>
                                        {exportConfig ?
                                            <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                                    </Grid>
                                </FormPartBox>
                            </Grid>
                            : null}
                        {!props.viewArchive && !props.admin &&
                            <Grid container item spacing={2} alignItems="stretch" direction="row"
                                  style={{paddingBottom: 8}}>
                                <Grid item xs>
                                    {isPreprava(props.data) ? props.archive ?
                                        <OfferArchiveActionsPart data={props.data}/> :
                                        <OfferActionsPart data={props.data}>
                                            {exportConfig ?
                                                <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                                        </OfferActionsPart> : undefined}
                                    {isVozidlo(props.data) ? props.archive ?
                                            <OfferArchiveActionsPart data={props.data}/> :
                                            <OfferActionsPart data={props.data}>
                                                {exportConfig ?
                                                    <ExportButtons data={props.data} exportConfig={exportConfig}/> : null}
                                            </OfferActionsPart> :
                                        undefined
                                    }
                                </Grid>
                            </Grid>}

                        {props.archive && !props.admin &&
                            <Grid container item spacing={2} alignItems="stretch" direction="row"
                                  style={{paddingBottom: 4}}>
                                <Grid item xs>
                                    <ArchivPoznamkyDial mode={ComponentMode.GridMode} nabidkaId={props.data?.id}
                                                        nabidkaType={isPreprava(props.data) ? 'P' : 'V'}
                                                        viewArchive={props.viewArchive}/>
                                </Grid>
                            </Grid>}
                    </>}
                    <ZadavatelHeaderInfoView userData={props.data.uzivatel} id={props.data.id} admin={props.admin}/>
                    <Grid item lg={12} sm={12} xs={12}>
                        <ZadavatelBasicInfoView userData={props.data.uzivatel} admin={props.admin}/>
                    </Grid>
                </Grid>
            </div>
        </div>
    ) : <></>;
}
