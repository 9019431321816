const de = {
  "MobileApp": {
    "AppLoading": {
      "CompanyInfo": "1. DATENBANK DER LADUNGEN UND VERFÜGBAREN WAGEN seit 1992."
    },
    "Buttons": {
      "Contact": "Kontakt"
    },
    "Login": {
      "InvalidCredentials": "Ungültige Anmeldedaten"
    },
    "ConfigureParameters": {
      "NavigationTitle": "Ansicht bearbeiten",
      "SectionTitle": "Daten zur Anzeige auswählen"
    },
    "SortParameters": {
      "NavigationTitle": "Sortieren Sie die Liste nach",
      "SortSectionTitle": "Wählen Sie die Verschiebungsrichtung:",
      "ParameterSectionTitle": "Wählen Sie einen Parameter, nach dem die Liste sortiert werden soll:",
      "Descending": "Absteigend",
      "Ascending": "Aufsteigend"
    },
    "FilterParameters": {
      "CancelFilters": "Filter abbrechen",
      "Filter": "Filter"
    },
    "MenuSections": {
      "Input": "Anlegen",
      "View": "Ansicht",
      "Premises": "Firmen",
      "Settings": "Weiter"
    },
    "EmptyPage": {
      "InputMessage": "Sie haben keine Gebote eingegeben",
      "ViewMessage": "Keine Angebote gefunden",
      "PremisesMessage": "Kein Betrieb gefunden"
    },
    "Cargos": {
      "FilterCargos": "Sendungen filtern",
      "AdditionalInfo": "Zusätzliche Informationen zum Transport",
      "ChooseKinds": "Arten auswählen",
      "ChooseClassifications": "Klassifikationen auswählen",
      "All": "Alle",
      "NewCargo": "Neue Frachten",
      "EditCargo": "Bearbeiten eines Datensatzes",
      "FillBasicInfo": "Grundinformation",
      "CargoDetail": "Frachtendetail",
      "Distance": "Distanz"
    },
    "Lorries": {
      "FilterLorry": "Filter LKWS",
      "NewLorry": "Neue LKWS",
      "FillBasicInfo": "Grundinformation",
      "AdditionalInfo": "Zusätzliche Informationen zum LKWS",
      "LorryDetail": "Detail LKWS",
      "EditLorry": "LKWS bearbeiten"
    },
    "Advertising": {
      "FilterAdvertising": "Anzeigen filtern",
      "NewAdvertising": "Neue Anzeige",
      "EditAdvertising": "Bearbeitung der Anzeige",
      "AdditionalInfo": "Zusätzliche Informationen zum Anzeige",
      "FillBasicInfo": "Grundinformation",
      "AdvertisingDetail": "Anzeigendetails"
    },
    "Premises": {
      "FilterPremises": "Betriebe filtern",
      "StreetNumber": "Straße + Hausnummer",
      "Registration": "Anmeldung",
      "ContractDate": "Vertrag /Im RAAL von",
      "DetailInfo": "Detailinformationen",
      "Accountants": "Buchhalter"
    },
    "Tabs": {
      "Settings": "Einstellungen",
      "Cargos": "Frachten",
      "Lorries": "Lkws",
      "Advertising": "Anzeigen"
    },
    "Settings": {
      "AppLanguage": "Sprache",
      "AppAppearance": "Erscheinungsbild",
      "AppSupport": "Benutzerunterstützung",
      "AppSupportDescription": "Sollten Sie irgendwelche Probleme haben, zögern Sie bitte nicht, uns telefonisch oder per E-Mail zu kontaktieren.",
      "AppSupportHtmlContent": "<p>email.: <a href=\"mailto:support@raal.cz\"  target=\"_self\">support@raal.cz</a>&nbsp;</p>                                                                                                                                 <p>mob.: +420 604 105 758</p>                                                                                                                                 <p>tel.: +420 495 217 281</p>                                                                                                                                 <p>tel.: +420 495 217 282</p>",
      "AppVersion": "Version {0}",
      "ChooseAppLanguage": "Wählen Sie die Sprache der Anwendung",
      "ChooseAppAppearance": "Wählen Sie das Aussehen der Anwendung",
      "DarkMode": "Dunkel",
      "LightMode": "Helles",
      "SystemMode": "Systemeinstellungen"
    }
  },
  "AppTitle": "RAALTRANS",
  "AppActivityType": {
    "View": "Ansicht"
  },
  "AppActivitySubType": {
    "Detail": "Detail",
    "Row": "Zeile"
  },
  "AutoRefresh": {
    "true": "Automatisch einlesen einschalten (CTRL+Leertaste)",
    "false": "Automatisch einlesen abschalten (CTRL+Leertaste)",
    "title": "Auto-einlesen",
    "NewData": "Neue Daten"
  },
  "RefreshAlert": {
    "TooltipOn": "Tonbenachrichtigung für neue Angebote EIN",
    "TooltipOff": "Tonbenachrichtigung für neue Angebote AUS",
    "TooltipInactive": "Tonbenachrichtigung angehalten (zum Fortsetzen sind einige Aktionen auf der Seite erforderlich)"
  },
  "Buttons": {
    "Rejstriky": "Registers",
    "MakeInvalid": "Ungültig  machen",
    "MakeValid": "Erneuern",
    "Help": "Hilfe",
    "Login": "Anmelden",
    "Logout": "Abmelden",
    "Save": "Speichern",
    "SaveAsNew": "Als neu speichern",
    "SaveAsCurrent": "Als aktuell speichern",
    "Delete": "Löschen",
    "Refresh": "Aktualisieren",
    "AddNewRecord": "Zugeben neuen Eintrag",
    "Cancel": "Löschen",
    "Confirm": "Bestätigen",
    "Add": "Zugeben",
    "ReloadWait": "Einlesen noch einmal mit langem Warten",
    "Close": "Beenden",
    "Continue": "Weitermachen",
    "Cancellation": "Stornierung",
    "Agree": "Ich stimme zu",
    "Check": "Überprüfen"
  },
  "ConfirmDialog": {
    "Title": "Bestätigung",
    "Body": "Wirklich wollen Sie diese Aktion durchführen?"
  },
  "DataChangedDialog": {
    "Title": "Halbfertiges Formular",
    "Body": "Sie haben ein halbfertiges Formular, wirklich wollen Sie es verlassen?\nAlle halbfertige Daten wurden verloren."
  },
  "UserRoleDialog": {
    "Title": "Berechtigungsfehler",
    "Body": "Sie haben keine Berechtigung, die Anwendung zu verwenden. Bitte kontaktieren Sie den RAALTRANS-Support."
  },
  "Currency": {
    "Title": "Währung",
    "name": "Name",
    "currencyCode": "Kode",
    "kurz": "Kurs (CZK)",
    "datum": "Synchronisiert"
  },
  "DataContextChanged": {
    "title": "Die Daten haben sich geändert. Laden Sie neue Daten mit F5 oder durch Drücken der Taste.\nHinweisung: Alle halbfertige Daten wurden verloren."
  },
  "Default": {
    "Id": "ID",
    "Enabled": "Erlaubt",
    "Telefon": "Telefon",
    "QuickContact": "Schneller Kontakt",
    "PhoneNumbers": "Telefon N.",
    "PhoneNumbersKratke": "Tel. N.",
    "FillCorrectPhone": "Füllen Sie korrekt Telefon Nummer aus",
    "Email": "Email",
    "Polozka": "Posten",
    "Text": "Text",
    "Key": "Schlüssel",
    "Note": "Notiz",
    "NoteAdmin": "Administratornotiz",
    "NoteMaster": "Masternotiz",
    "Slovo": "Wort",
    "Name": "Name",
    "DataName": "Name",
    "Map": "Landkarte",
    "Mpz": "LK",
    "MapDefine": "Eingabe der Koordinaten",
    "Loading": "Einlesen",
    "ModalZakladni": "Details aufzeichnen",
    "TabZakladni": "Elementarangabe",
    "region": "Art der Angebote",
    "typ": "Art der Angebote",
    "typLicence": "Straßenschema",
    "Datum": "Datum",
    "DatCas": "Date of last change",
    "Jazyky": "Sprachen",
    "Yes": "Ja",
    "No": "Nein",
    "Or": "Oder",
    "LoadingDetail": "Detail einlesen",
    "LoadingDetailFailed": "Detail einlesen versagte",
    "NotFound": "Aufnahme nicht gefunden",
    "DeleteText": "Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?",
    "DeleteTitle": "Einen Datensatz löschen",
    "Account": "Benutzerkonten",
    "AccountAdd": "Konto hinzufügen",
    "UnlockText": "Sie möchten das gesperrte Gerät wirklich entsperren \n detail in diesem Tab?",
    "UnlockTitle": "Den Datensatz entsperren",
    "PdfHeader": "Angebot {0} Nummer {1} und sein Auftragnehmer",
    "PdfFirma": "Ausführliche Informationen zur Einrichtung {0}",
    "PdfExportedUser": "Exportiert von",
    "PdfExportedDate": "Datum",
    "SendTheFirstAccessDataTextSingle": "Diese Aktion ist in erster Linie für Räumlichkeiten gedacht, die nur einen Benutzer haben (SINGLE). ",
    "SendTheFirstAccessDataTitleSingle": "Versenden der ersten Zugangsdaten SINGLE",
    "SendTheFirstAccessDataButtonSingle": "SENDEN SIE DIE ERSTZUGANGSDATEN SINGLE",
    "SendTheFirstAccessDataTextMulti": "Diese Aktion ist in erster Linie für Räumlichkeiten mit mehreren Benutzern gedacht. ",
    "SendTheFirstAccessDataTitleMulti": "Versenden der ersten Zugangsdaten MEHRBENUTZERLIZENZ",
    "SendTheFirstAccessDataButtonMulti": "SENDEN SIE DIE ERSTZUGANGSDATEN MEHRBENUTZERLIZENZ",
    "Jednotka": "Einheit",
    "JednotkaShort": "ME"
  },
  "Dials": {
    "Users": "Benutzers",
    "Companies": "Firmen",
    "Places": "Betriebes",
    "Countries": "Staat",
    "Currencies": "Währung und Kurse",
    "Types": "Arten",
    "Params": "Parameters",
    "Jobs": "Joby",
    "ZakazanaSlova": "verbotene Wörte",
    "NevhodnaSlovaFirma": "Gesuchte Wörter für Unternehmen",
    "HlidanaSlova": "Gesuchte Wörter",
    "VolneVozy": "Lkws",
    "Dispeceri": "Dispatcher",
    "Prepravy": "Frachten",
    "Advertising": "Anzeigen",
    "Messages": "Nachricht",
    "ThunderMessages": "Blitzmeldungen",
    "HlidanaSlovaKonfiguraceFirem": "Konfiguration von Unternehmen",
    "Pvi": "Eigene FLA",
    "PviShort": "FLA",
    "Firma": "Firma",
    "Provozovna": "Betrieb",
    "HistoryPrepravy": "Frachten",
    "HistoryAdvertising": "Anzeigen",
    "HistoryVolneVozy": "Lkws",
    "Kilometrovnik": "Plätze",
    "ProfilyVozidel": "Liste der Fahrzeuge",
    "Trasy": "Routen",
    "PrepravaTrasa": "Route - Frachten",
    "VozidloTrasa": "Route - Lkw",
    "KilometrovnikHlaseni": "Fehler melden",
    "KilometrovnikHlaseniAdmin": "gemeldete Fehler",
    "PrejezdyPreprav": "Leerfahrten der Frachten",
    "PrejezdyVozidel": "Leerfahrten der Lkws",
    "Dokladky": "Beiladungen",
    "DiagPage": "Diagnose",
    "Notes": "Kommentar",
    "NepohodlneOsoby": "Unbequeme Leute",
    "Pripady": "Fälle",
    "Nadpripady": "Super Fälle",
    "Faktury": "Rechnungen",
    "ReportTexts": "Übersetzungen von Texten",
    "TriADost": "3 Mal und das reicht",
    "Smlouvy": "Verträge",
    "VypocetTrasy": "Routenberechnung",
    "UlozeneTrasy": "Gespeicherte Routen"
  },
  "DialDefaults": {
    "CreateRecord": "Erstellen einen Datensatz",
    "CreateRecordAndNext": "Erstellen und mehr",
    "CreateRecordAsNew": "Datensatz als neu erstellen",
    "UpdateRecord": "Änderungen speichern",
    "DeleteRecord": "Löschen",
    "ClearField": "Leer",
    "UnlockRecord": "Datensatz entsperren",
    "ServerError": "Serverfehler",
    "ServerErrorMessage": "Unerwarteter Serverfehler",
    "ServerTimeoutMessage": "Die Frist ist abgelaufen"
  },
  "Prejezdy": {
    "odkud": "Leerfahrten von",
    "kam": "Leerfahrten nach",
    "nabidkaOdkud": "Angebot - von",
    "nabidkaKam": "Angebot - nach",
    "radiusNabidkaOdkud": "Angebot - von [km]",
    "radiusNabidkaKam": "Angebot - nach [km]",
    "prejezdy": "Leerfahrten",
    "volneVozy": "Lkws",
    "prepravy": "Frachten",
    "prejezd": "Mit Leerfahrten",
    "prejezdOnly": "Nur Leerfahrten",
    "original": "Original",
    "found": "Gefunden",
    "prejezdCelkem": "L-Insgesamt",
    "prejezdOd": "L-Von",
    "prejezdKam": "L-Nach",
    "radiusPrejezd": "Max Leerfahrt insgesamt [km]",
    "radiusPrejezdOd": "Max L-Von [km]",
    "radiusPrejezdKam": "Max L-Nach [km]",
    "kilometrovnikPrejezd": "Straßenschema Leerfahrt",
    "vyhledaniPrepravy": "Leerfahrten, Transport suchen",
    "vyhledaniVozu": "Leerfahrten, Lkws suchen",
    "calculate": "Leerfahrten suchen",
    "Preprava": "Ausgangs-Fracht",
    "Vozidlo": "Ausgangs-Lkw",
    "trasa": "Route",
    "vozidlo": "Lkw",
    "max": "Max Leerfahrt",
    "info": "Hilfe",
    "datum": "Datum",
    "datumOd": "Datum von",
    "datumDo": "Datum bis"
  },
  "Dispecer": {
    "Username": "Name von Dispatcher",
    "DatCas": "Zeitpunkt der letzten Änderung"
  },
  "Enumerations": {
    "CiselnikTyp": {
      "I": "A_Anzeigen",
      "P": "F_Frachten",
      "V": "L_Lkws",
      "R": "T_Typ",
      "D": "E_Ergänzung",
      "G": "H_Handelsregister",
      "S": "Z_Zustand"
    },
    "Druh": {
      "1": "Plane",
      "2": "Offener Lkw",
      "3": "Kipper",
      "4": "Koffer",
      "5": "Frigo",
      "6": "Isokoffer",
      "7": "Tautliner",
      "8": "Kasten-häng.Kleider",
      "9": "Tank",
      "10": "Mulde",
      "11": "Walkingfloor",
      "12": "Double decker",
      "13": "Tieflader",
      "14": "Pritsche",
      "15": "Container-Chasis",
      "16": "Rungen",
      "17": "Mega",
      "18": "Jumbo",
      "19": "Solo",
      "20": "Lieferung-leinwand",
      "21": "Lieferschrank",
      "22": "Abholen",
      "23": "Autotransporter",
      "250": "Andere"
    },
    "Duvod": {
      "NABIDKA": "Angebot",
      "POPTAVKA": "Nachfrage"
    },
    "InvalDuvodPreprava": {
      "0": "OK",
      "1": "Das Datum liegt bereits in der Vergangenheit",
      "2": "Transport enthält verbotene Wörter",
      "3": "Vom Betreiber ungültig gemacht",
      "4": "Dem Nutzer ist es untersagt, Sendungen zu veröffentlichen",
      "5": "Geschäft eingestellt",
      "6": "Das Datum liegt zu weit von heute entfernt",
      "7": "Großer Bereich zwischen Von-Datum und Bis-Datum",
      "8": "Enthält eine Telefonnummer, die nicht in den Kontakten aufgeführt ist",
      "9": "Duplizität",
      "10": "Benutzer gesperrt",
      "11": "Benutzer ohne Telefon",
      "12": "Entzug der Rechte durch den Administrator",
      "OK": "Ok",
      "NEZNAME_TELC": "Unbekannte Telefonnummer"
    },
    "InvalDuvodVozidlo": {
      "0": "OK",
      "1": "Das Datum liegt bereits in der Vergangenheit",
      "2": "Lkw enthält verbotene Wörter",
      "3": "Vom Betreiber ungültig gemacht",
      "4": "Dem Nutzer ist es untersagt, Lkws zu veröffentlichen",
      "5": "Geschäft eingestellt",
      "6": "Das Datum liegt zu weit von heute entfernt",
      "7": "Großer Bereich zwischen Von-Datum und Bis-Datum",
      "8": "Enthält eine Telefonnummer, die nicht in den Kontakten aufgeführt ist",
      "9": "Duplizität",
      "10": "Benutzer gesperrt",
      "11": "Benutzer ohne Telefon",
      "12": "Entzug der Rechte durch den Administrator",
      "OK": "Ok",
      "NEZNAME_TELC": "Unbekannte Telefonnummer"
    },
    "InvalidDuvodInzerat": {
      "0": "OK",
      "1": "Die Anzeige ist zu alt",
      "2": "Die Anzeige enthält verbotene Wörter",
      "3": "Vom Betreiber ungültig gemacht",
      "4": "Dem Nutzer ist es untersagt, Anzeigen zu schalten",
      "5": "Betrieb eingestellt",
      "8": "Enthält eine Telefonnummer, die nicht in den Kontakten aufgeführt ist",
      "9": "Duplizität",
      "10": "Benutzer gesperrt",
      "11": "Benutzer ohne Telefon",
      "12": "Entzug der Rechte durch den Administrator",
      "OK": "Ok",
      "INZERAT_ZNEPLATNEN": "Anzeige ungültig"
    },
    "InvoiceFrequency": {
      "0": "Monatlich",
      "1": "Vierteljährlich",
      "2": "Halbjährlich",
      "3": "Jährlich",
      "MONTHLY": "Monatlich",
      "QUARTERLY": "Vierteljährlich",
      "HALFYEARLY": "Halbjährlich",
      "YEARLY": "Jährlich"
    },
    "KlasifikaceColor": {
      "0": "Farblos",
      "1": "Grün",
      "2": "Orange",
      "3": "Rot"
    },
    "KlasifikaceValue": {
      "0": "Keine",
      "1": "1",
      "2": "2",
      "3": "3"
    },
    "LicenceTyp": {
      "1": "Nein",
      "2": "Da",
      "WITHKM": "Ja",
      "WITHOUTKM": "Nein"
    },
    "Podstav": {
      "0": "Ohne Status",
      "1": "3 Mal und das reicht",
      "2": "Pauschalzahlungen",
      "3": "Individuelle Anfrage",
      "4": "Unbezahlte SW",
      "5": "Vertragsbruch",
      "6": "Kündigung",
      "7": "Problem",
      "8": "Originaldokumente",
      "BEZ_PODSTAVU": "Ohne Status",
      "X3_A_DOST": "3 Mal und das reicht",
      "PLATBY": "Pauschalzahlungen",
      "POZASTAVEN_VLASTNI_ZADOST": "Individuelle Anfrage",
      "PODEZRELY": "Unbezahlte SW",
      "PORUSENI_SMLOUVY": "Vertragsbruch",
      "VYPOVED": "Kündigung",
      "PROBLEM": "Problem",
      "ORIGINAL": "Originaldokumente"
    },
    "Predmet": {
      "NAKLADNI_AUTA": "Lkws",
      "OSOBNI_AUTA": "Autos",
      "PRIVESY_NAVESY": "Anhänger/Auflieger",
      "NAHRADNI_DILY": "Ersatzteile",
      "JINE": "Andere"
    },
    "Region": {
      "0": "CZ",
      "1": "EU",
      "2": "GLOBAL",
      "3": "SK",
      "4": "CZSK"
    },
    "Roles": {
      "ROLE_ADMIN": "Administrator",
      "ROLE_MASTER": "Master",
      "ROLE_USER": "Benutzer",
      "ROLE_NONE": "Keine Rolle"
    },
    "TypOdesilatele": {
      "FYZICKA_OSOBA": "FO (fyzická osoba)",
      "PRAVNICKA_OSOBA": "PO (právnická osoba)",
      "SLOVENSKO": "SK (Slovensko)"
    },
    "SmlouvaS": {
      "NONE": "Bez smlouvy",
      "FOHK": "FO HK",
      "FONITRA": "FO Nitra",
      "RAALHK": "RAALTRANS a.s. HK",
      "RAALNITRA": "RAALTRANS a.s. Nitra",
      "RAALHKNOVA": "Nová RAALTRANS a.s. HK",
      "FONITRANOVA": "Nová FO Nitra",
      "FOHKNOVA": "Nová FO HK",
      "RAAL_AS_2023": "RAALTRANS a.s. 2023",
      "RAAL_AS_2024": "RAALTRANS a.s. 2024",
      "RAAL_SRO_2023": "RAALTRANS s.r.o 2023",
      "RAAL_SRO_2024": "RAALTRANS s.r.o 2024"
    },
    "Stav": {
      "0": "Aktiv",
      "1": "Ausgesetzt",
      "JEDE": "Aktiv",
      "POZASTAVIL": "Ausgesetzt"
    },
    "SystemParamKey": {
      "PREPRAVA_DATE_RANGE": "Maximale Datenspanne für Transporte (in Tagen)",
      "VOZIDLA_DATE_RANGE": "Maximale Datenspanne für freie Lkws (in Tagen)",
      "INZERAT_MAX_AGE": "Maximal Alter der Anzeige",
      "PREPRAVY_DRUHY_MAX": "Maximale Anzahl von Typen für Transporte",
      "VOZIDLA_DRUHY_MAX": "Maximale Anzahl von Typen für freie Lkws",
      "PREPRAVA_DATE_ADVANCE_MAX": "Maximaler Datenvorschuss für Transporte (in Tagen)",
      "VOZIDLO_DATE_ADVANCE_MAX": "Maximaler Datenvorschuss für freie Lkws (in Tagen)",
      "PREPRAVA_LENGTH": "Ladungsmesser für Transporte",
      "PREPRAVA_WEIGHT": "Transportgewicht",
      "VOZIDLO_LENGTH": "Ladezähler für freie Lkws",
      "VOZIDLO_WEIGHT": "Gewicht für freie Lkws",
      "PROVOZOVNA_CANCEL_PERIOD": "Flag-Einstellung nach Vertragsbeendigung aufgehoben (in Tagen)",
      "MAX_OFFER_EDITS": "Maximale Anzahl an Menüerneuerungen/Änderungen (-1 = unbegrenzt)",
      "OFFER_DELETE_DUPLICATES": "Löschen Sie Duplikate mit dem Angebot",
      "MAX_CALCULATION_JOBS_PER_NODE": "Die maximale Anzahl berechneter Routen bezogen auf einen Server",
      "UZIVATEL_KONTROLA_PODSTAVU": "Liste der Zustände der Betriebs zur Überprüfung der IP-Anmeldung des Benutzers",
      "ARCHIVE_RETENTION_PERIOD": "Angebote aus dem Archiv löschen (in den Tagen bis sie ins Archiv eingefügt werden)",
      "VIEW_ARCHIVE_RETENTION_PERIOD": "Angebote aus dem Archiv löschen (in den Tagen bis sie ins Archiv eingefügt werden)",
      "ARCHIVE_PERIOD": "Angebote ins Archiv verschieben (in Tagen nach der Ungültigmachung)",
      "HIDE_INZERCE": "Anzeigen verdecken",
      "IGNORE_NOTIFICATION_PROVOZOVNA_KOD": "Unbeaufsichtigte Betriebe",
      "POSILAT_SMS_PRI_ZMENE_HESLA": "Geben Sie 1 ein, um eine SMS bei einer Passwortänderung zu senden, ansonsten geben Sie 0 ein.",
      "POSILAT_SMS_PRO_PRVNI_PRISTUPOVE_UDAJE": "Geben Sie 1 ein, um eine SMS beim Generieren der ersten Anmeldedaten zu senden 0."
    },
    "TypZpravy": {
      "B": "Blitzmeldung",
      "C": "C",
      "I": "I",
      "R": "R",
      "S": "S",
      "T": "Ausbildung",
      "H": "H",
      "V": "Systembeschreibung"
    },
    "RoadType": {
      "0": "Schnellstraße",
      "1": "Straße für Kraftfahrzeuge",
      "2": "Straße I. Klassen",
      "3": "Straße II. Klassen",
      "4": "Straße III. Klassen",
      "5": "Andere"
    },
    "TrasaState": {
      "0": "Warten auf Berechnung",
      "1": "Berechnung läuft",
      "2": "Berechnet",
      "3": "Fehler"
    },
    "OsrmProfile": {
      "0": "N1 (bis 3,5 Tonne)",
      "1": "N2 (bis 12 Tonne)",
      "2": "N3 (bis 40 Tonne)"
    },
    "RouteCalculationStatus": {
      "0": "OK",
      "1": "Serverfehler",
      "2": "Route nicht gefunden",
      "3": "Kosten können nicht berechnet werden",
      "4": "Unvollständige Konfiguration – Mautpreise, Tarife, ..."
    },
    "KilometrovnikHlaseniStatus": {
      "0": "Neu",
      "1": "Geschlossen"
    },
    "DluhNadpripadStav": {
      "0": "Geöffnet",
      "1": "Geschlossen"
    },
    "DluhPripadStav": {
      "0": "Nicht gesendet",
      "1": "Unbestätigt",
      "2": "Unbezahlt",
      "3": "Geschlossen",
      "4": "Ohne Bezahlung gelöst",
      "5": "Geschlossen (Kündigung)",
      "6": "Geschlossen (Punkt 3.9)",
      "7": "Verjährung",
      "8": "Bestritten"
    },
    "DluhFakturaStav": {
      "0": "Nicht gesendet",
      "1": "Unbestätigt",
      "2": "Unbezahlt",
      "3": "Bezahlt",
      "4": "Ohne Bezahlung gelöst",
      "5": "Unbezahlt (Kündigung)",
      "6": "Unbezahlt (Punkt 3.9)",
      "7": "Verjährung",
      "8": "Bestritten"
    },
    "ReportLanguage": {
      "cs": "Tschechisch",
      "en": "Englisch",
      "de": "Deutsch",
      "pl": "Polnisch",
      "sk": "Slowakisch"
    },
    "TypPravniDokumentace": {
      "VP": "Allgemeine geschäftsbedingungen",
      "LP": "Lizenzbedingungen",
      "GDPR": "Grundsätze zum schutz personenbezogener daten",
      "PDI": "Bereitstellung von verkehrsinformationen",
      "VYPCZ": "Vertragsbeendigung - CZ",
      "VYPSK": "Vertragsbeendigung - SK"
    },
    "LastAction": {
      "PASSWORD_CHANGE": "Passwortänderung"
    }
  },
  "ErrorComponent": {
    "title": "Fehler",
    "homeButton": "Zurück zur Hauptseite",
    "redirectionMessage": "Sie werden in Kürze zur Startseite weitergeleitet.",
    "error403": "Zugriff wurde verweigert."
  },
  "Export": {
    "detailHeader": "Detail"
  },
  "Filter": {
    "Templates": "Suchfilter",
    "TemplateName": "Filtername",
    "SaveTemplate": "Neu",
    "UpdateTemplate": "Auferlegen",
    "RemoveTemplate": "Löschen",
    "SelectTemplate": "Suchfilter auswählen",
    "NoTemplates": "Kein Filter gefunden",
    "ClearFilter": "Filter löschen",
    "DoFilter": "Suchen",
    "OpenFilter": "Filter",
    "UseFilter": "Filter",
    "TooltipForFilledFilter": "Der Filter ist aktiv",
    "TooltipForEmptyFilter": "Der Filter ist inaktiv",
    "DeleteConfirmationText": "Möchten Sie den Filter wirklich löschen?",
    "Vychozi": "Anfänglich",
    "SaveFilter": "Filter speichern",
    "SavedFilters": "Gespeicherte Filter"
  },
  "Firma": {
    "Id": "Firmen-ID",
    "Title": "Firma",
    "TabProvozovny": "Betrieb",
    "TabUzivatele": "Benutzers",
    "TabDispeceri": "Dispatchers",
    "Provozovna": "Firma (Einrichtungscode)",
    "pozastavena": "Abgesagt",
    "gdpr": "Einwilligung zur DSGVO",
    "UserIdentityLabel": "Firmen Identifikation",
    "UserLabel": "Firma",
    "UserContactLabel": "Registrierungsadresse",
    "UserNextLabel": "Andere Informationen",
    "ContactLabel": "Adressen",
    "NextLabel": "Angaben",
    "R1Nazev": "Name",
    "R1Ulice": "Straße",
    "R1CisloPopisne": "Anzahl der beschreibenden",
    "R1CisloPopisneKratke": "A.b.",
    "R1Obec": "Dorf",
    "R1CastObce": "Teil des Dorfes",
    "R1Psc": "PLZ",
    "R1Stat": "Land",
    "ico": "ID Nummer",
    "dic": "EU MwSt-Nummer/ ID MwSt-Nummer",
    "dic1": "MwSt-Nummer",
    "vznik": "Gründung des Unternehmens",
    "NezadouciZakaznik": "Unerwünschter Kunde",
    "NezadouciZakaznikShort": "Unerwünscht",
    "CreateFirmaAndProvozovna": "Erstellen Sie ein Unternehmen und Betrieb",
    "VytvoritFirmu": "Erstellen Sie ein Unternehmen",
    "Pojisteni": "Versicherung",
    "datPojist": "Datum der Versicherung",
    "ChooseFirma": "Wählen Sie ein Unternehmen aus",
    "FirmaMaSlovo": "Das Unternehmen hat ein Mitspracherecht",
    "EnabledOnFirma": "Ermöglichen in Firma",
    "MissingFirma": "!!! Das Unternehmen fehlt !!!",
    "RegDatum": "Registrierungsdatum",
    "datSmlouvy": "Datum des Vertrags / In RAALu seit",
    "raalOd": "In RAALu seit",
    "http": "WWW",
    "fPoznamka": "Admin-Hinweis",
    "DatumZmenyRejstrik": "Datum der Registeränderung",
    "PoznamkaRejstrik": "Notiz Handelsregister",
    "AktualizaceRejstrik": "Aktualisierung Handelsregister",
    "Nadpripad": "Überfall",
    "TooltipRejstrikyVeFirme": "Zur Anzeige der Register muss das Bundesland ausgefüllt werden"
  },
  "FormLocalization": {
    "DataSaved": "Der Datensatz wurde erfolgreich gespeichert",
    "DataSavedFailed": "Datensatz konnte nicht gespeichert werden",
    "DataRemoved": "Der Datensatz wurde erfolgreich gelöscht",
    "DataWasRemoved": "Der Datensatz wurde gelöscht",
    "DataWasEdited": "Der Datensatz wurde geändert",
    "DataRemovedFailed": "Datensatz konnte nicht gelöscht werden",
    "ServerError": "Serverfehler",
    "ValidationError": "Validierungsfehler",
    "FieldMessages": {
      "FieldIsRequired": "Pflichtangaben werden nicht ausgefüllt",
      "FieldNotNull": "Darf nicht Null sein",
      "FieldIsRequiredValue": "Pflichtangaben werden nicht ausgefüllt {0}",
      "CoordinatesMissing": "Feldkoordinaten fehlen {0}",
      "UserLicenceError": "Die Straßenschemalizenz gilt nicht für die angegebene Adresse"
    },
    "DateTimePicker": {
      "invalidDate": "Das Datum ist ungültig",
      "cancelLabel": "Stornieren",
      "clearLabel": "Löschen",
      "emptyLabel": "Leer",
      "invalidLabel": "Falsches Datum",
      "okLabel": "Bestätigen",
      "todayLabel": "Heute",
      "minDateMessage": "Das Datum darf nicht in der Vergangenheit liegen",
      "minDateTimeMessage": "Datum und Uhrzeit dürfen nicht in der Vergangenheit liegen",
      "maxDateMessage": "Das Datum darf nicht in der Zukunft liegen",
      "disablePast": "Das Datum darf nicht in der Vergangenheit liegen",
      "shouldDisableDate": "Das Datum liegt außerhalb des zulässigen Bereichs",
      "disableFuture": "Das Datum darf nicht in der Zukunft liegen",
      "maxDate": "Falsches Datum",
      "minDate": "Das Datum darf nicht in der Vergangenheit liegen"
    },
    "Vicinity": {
      "Address": "Genau",
      "Range": "Leerfahrt",
      "NoKilom": "Lizenz mit Straßenschema notwendig"
    }
  },
  "FormEditor": {
    "add": "Přidat",
    "cancel": "Zrušit",
    "colorpicker": "Výběr barvy",
    "text": "Text",
    "background": "Pozadí",
    "h": "Nadpis {0}",
    "blockquote": "Odstavec",
    "code": "Kód",
    "blocktype": "Styly",
    "normal": "Normální",
    "embedded": "Vložené",
    "embeddedlink": "Vložený odkaz",
    "enterlink": "Otevřít odkaz",
    "emoji": "Emotikon",
    "fontfamily": "Písmo",
    "fontsize": "Velikost písma",
    "history": "Historie",
    "undo": "Zpět",
    "redo": "Znovu",
    "image": "Vložit obrázek",
    "fileUpload": "Nahrát soubor",
    "byURL": "URL",
    "dropFileText": "Přetáhněte sem soubor nebo klikněte pro výběr",
    "bold": "Tučné",
    "italic": "Kurzíva",
    "underline": "Podtržení",
    "strikethrough": "Přeškrtnuté",
    "monospace": "Kód",
    "superscript": "Horní index",
    "subscript": "Dolní index",
    "linkTitle": "Název odkazu",
    "linkTarget": "Cílová cesta",
    "linkTargetOption": "Otevřít odkaz v novém okně",
    "link": "Vložit odkaz",
    "unlink": "Odebrat odkaz",
    "list": "Seznam",
    "unordered": "Odrážky",
    "ordered": "Číslování",
    "indent": "Zvětšit odsazení",
    "outdent": "Zmenšit odsazení",
    "remove": "Vymazat formátování",
    "textalign": "Zarovnání textu",
    "left": "Zarovnat doleva",
    "center": "Zarovnat na střed",
    "right": "Zarovnat doprava",
    "justify": "Zarovnat do bloku"
  },
  "GeneratePassword": {
    "title": "Neues Passwort senden",
    "tooltip": "Ein neues Passwort wird für den Benutzer an die eingegebene E-Mail Adresse zugesandt.",
    "success": "Ein Passwort wurde an den Benutzer gesendet.",
    "error": "Fehler beim Generieren des Passworts."
  },
  "GoogleMaps": {
    "Title": "Google Landkarte",
    "SearchPlaces": "Suchen nach Orten"
  },
  "HttpLocalization": {
    "SignedOut": "Geprüft",
    "NetworkError": "Netzwerkfehler",
    "Error401FingerPrint": "Sie wurden abgemeldet, weil Sie sich in einem anderen Browser angemeldet haben oder Ihr Anmeldetoken abgelaufen ist",
    "LogoutBySystem": "Sie wurden vom System abgemeldet.",
    "Error401": "Das Anmeldetoken ist abgelaufen"
  },
  "Informations": {
    "Info": "Raal info",
    "System": "Systembeschreibung",
    "TollRate": "Mautsatz",
    "ExchangeRates": "Wechselkurse",
    "ReleaseNotes": "Notiz zur Aushändigung"
  },
  "Inzerce": {
    "Id": "ID der Anzeige",
    "InvalidInzeratConfirm": "Möchten Sie die Anzeige ernsthaft für ungültig erklären?",
    "ValidInzeratConfirm": "Möchten Sie die Anzeigenungültigmachung ernsthaft aufheben?",
    "CurrencyRequiredWhenPrice": "Es muss zusammen mit dem Preis ausgefüllt werden",
    "Price": "Preis",
    "currency": "Währung",
    "popis": "Beschreibung",
    "rychlyKontakt": "Schneller Kontakt",
    "datIns": "Erstellungsdatum",
    "modifiedOn": "Datum der Änderung",
    "valid": "Gültig",
    "invalDuv": "Grund für die Ungültigmachung",
    "cena": "Preis",
    "Description": "Beschreibung",
    "AdText": "Beschreibung",
    "Dispecer": "Dispatcher",
    "History": "Geschichte",
    "OfferDetail": "Angebot",
    "ArchiveConfirm": "Möchten Sie die Anzeige archivieren?",
    "zadavatel": "Anbieter"
  },
  "ItemAge": {
    "Title": "Das Alter des Angebots",
    "OldMax": "Höchstens alt",
    "FromLastBrowse": "Neuere ab der letzten Ansicht",
    "ByDate": "Nach Datum",
    "Unknown": "Unbekannt",
    "search": "Suchen"
  },
  "javax": {
    "validation": {
      "constraints": {
        "NotEmpty": {
          "message": "darf nicht leer sein"
        },
        "Email": {
          "message": "Falsches E-Mail-Adressformat"
        }
      },
      "user": {
        "adminWithoutProvozovna": "Dem Administrator darf keine Niederlassung zugeordnet sein",
        "userWithProvozovna": "Dem Benutzer muss eine Niederlassung zugewiesen sein"
      },
      "dispatcher": {
        "licenseLimit": "Die Anzahl der Disponenten hat das Lizenzlimit erreicht"
      },
      "firma": {
        "requiredIdFields": "Obligatorische Kombination Staat + Ident.-Nr./MwSt-Nummer/Ident. MwSt-Nummer"
      },
      "phone": {
        "notValid": "Ungültiges Telefonnummernformat"
      },
      "offer": {
        "rychlyKontaktNotInProvozovna": "Die Telefonnummer ist nicht in der Liste der Betriebsnummern enthalten"
      },
      "lock": {
        "couldNotLock": "Datensatz konnte nicht gesperrt werden"
      },
      "waypoint": {
        "noLicense": "Die Straßenschemalizenz gilt nicht für die angegebene Adresse"
      }
    }
  },
  "Job": {
    "Stav": "Zustand",
    "Action": "Aktion"
  },
  "Licence": {
    "Id": "Id",
    "Title": "Lizenz",
    "GenerateUsers": "Benutzer generieren",
    "NumberGreaterThan": "Die Zahl muss größer sein als {{value}}"
  },
  "Links": {
    "ZivnostenskyCr": "Gewerberegister CZ",
    "ZivnostenskySk": "Gewerberegister SK",
    "ObchodniCr": "Handelsregister CZ",
    "Ares": "Verwaltungsregister der Wirtschaftssubjekte",
    "ObchodniSk": "Handelsregister SK"
  },
  "Map": {
    "ClearWayPoints": "Löschen Sie die Karte",
    "SearchPlace": "Wählen Sie ein Ziel aus oder klicken Sie auf die Karte",
    "SearchNearPlace": "Wählen Sie ein Zie *",
    "noLicense": "Sie haben keine Lizenz",
    "noHighway": "Landstraße nicht gefunden",
    "noHighwaySelected": "Es wurde kein Abschnitt ausgewählt",
    "navigation": "Routebeschreibung"
  },
  "MaterialTable": {
    "columns": {
      "showColumnsTitle": "Spalten festlegen",
      "menuTitle": "Spalten hinzufügen oder entfernen"
    },
    "body": {
      "emptyDataSourceMessage": "Keine Daten verfügbar",
      "addTooltip": "Fügen Sie schnell einen neuen Datensatz hinzu",
      "deleteTooltip": "Aufzeichnung löschen",
      "removeTooltip": "Aufzeichnung abnehmen",
      "updateTooltip": "Aufzeichnung erneuern",
      "noUpdateTooltip": "Aufzeichnung kann nicht wiederhergestellt werden",
      "editTooltip": "Aufzeichnung bearbeiten",
      "dataChanged": "Die Daten wurden geändert",
      "dateTimePickerLocalization": {},
      "filterRow": {
        "filterTooltip": "Filter"
      },
      "editRow": {
        "saveTooltip": "Speichern",
        "cancelTooltip": "Storno",
        "mode": {
          "add": {
            "saveTooltip": "Uložit nový",
            "cancelTooltip": "Zrušit"
          },
          "update": {
            "saveTooltip": "Uložit změny",
            "cancelTooltip": "Zrušit změny"
          },
          "delete": {
            "saveTooltip": "Potvrdit smazání",
            "cancelTooltip": "Zrušit mazání"
          }
        },
        "deleteText": "Sind Sie sicher, dass Sie diesen Eintrag löschen möchten?",
        "addTooltip": "Zum erweiterten Eintrag gehen",
        "editTooltip": "Zur erweiterten Bearbeitung gehen",
        "readTooltip": "Zur Vorschau gehen"
      }
    },
    "grouping": {
      "placeholder": "gruppieren"
    },
    "pagination": {
      "labelDisplayedRows": "{from}-{to} z {count} ({overall} total)",
      "labelRowsSelect": "Reihen",
      "labelRowsCount": "Zahl der Reihen",
      "labelRowsPerPage": "Anzahl pro Seite",
      "firstAriaLabel": "erste",
      "firstTooltip": "erste",
      "previousAriaLabel": "vorherige",
      "previousTooltip": "vorherige",
      "nextAriaLabel": "nächste",
      "nextTooltip": "nächste",
      "lastAriaLabel": "letzt",
      "lastTooltip": "letzt"
    },
    "toolbar": {
      "searchTooltip": "aussuchen",
      "searchPlaceholder": "aussuchen"
    },
    "header": {
      "actions": ""
    },
    "resetColumnOrder": "Spalten erneuern",
    "resetColumnOrderQuestion": "Möchten Sie die Tabellenspalten unbedingt in ihre ursprüngliche Form zurückversetzen?",
    "openMultipleAction": "Mehrere aktion öffnen",
    "closeMultipleAction": "Mehrere aktion schließen",
    "export": "Export",
    "exportAll": "Alles exportieren",
    "exportFilter": "Filter exportieren",
    "exportPdf": "Als PDF exportieren",
    "exportXls": "Nach Excel exportieren (XLS)",
    "exportCsv": "Als CSV exportieren",
    "exportXml": "Als XML exportieren",
    "exportPrint": "Drucken",
    "exportSelect": "Wählen Sie die Spalten und deren Reihenfolge aus"
  },
  "MuiAutocomplete": {
    "NoOption": "Keine Ergebnisse",
    "GPSCurrentPosition": "Holen sich die aktuelle GPS-Position"
  },
  "MuiInput": {
    "MinTip": "Untergrenze für das Filtern von Aufzeichnungen",
    "MaxTip": "Obergrenze für das Filtern von Aufzeichnungen"
  },
  "NevhodnaSlova": {
    "VsichniZakaznici": "Alle Unternehmen",
    "ZadniZakaznici": "Keine Unternehmen",
    "AllWords": "Alle Wörter",
    "HlidaneSlovoFirmy": "Unternehmen, die dieses Wort verwenden"
  },
  "Vozidlo": {
    "id": "Fahrzeugidentifikation",
    "ArchiveConfirm": "Ist es Ihnen ernst mit der Archivierung eines Lkw?"
  },
  "TransportNabidka": {
    "pocetEditaci": "Anzahl der Bearbeitungen"
  },
  "Preprava": {
    "id": "Transport-ID",
    "zadavatel": "Anbieter",
    "regOd": "Staat von",
    "pscOdkud": "PLZ von",
    "datOd": "Datum von",
    "mena": "Währung",
    "regKam": "Staat nach",
    "datDo": "Datum bis",
    "datum": "datum",
    "pscKam": "PLZ nach",
    "cena": "Preis",
    "vahaShort": "M [t]",
    "paletyShort": "P [Stck]",
    "objemShort": "V [m3]",
    "delkaShort": "L [m]",
    "sirkaShort": "W [m]",
    "vyskaShort": "H [m]",
    "lozPlochaShort": "S [m2]",
    "doplnujiciUdaje": "Mehr Details",
    "vaha": "Gewicht [t]",
    "palety": "Paletten [Stck]",
    "objem": "Volumen [m3]",
    "lozPlocha": "Ladefläche [m2]",
    "lozPlochaSmaller": "L-fläche [m2]",
    "delka": "Länge [m]",
    "sirka": "Breite [m]",
    "vyska": "Höhe [m]",
    "datIns": "Erstellungsdatum",
    "modifiedOn": "Datum der Änderung",
    "valid": "gültig",
    "invalDuv": "Grund für die Ungültigmachung",
    "odkud": "VON",
    "odkudKamToolTip": "Um mehrere LK / PLZ einzugeben, verwenden Sie ein Komma als Trennzeichen",
    "kam": "NACH",
    "naklProstorVcelku": "Laderaum gesamt",
    "neverPozn": "Private Notiz",
    "zvedaciCelo": "Ladebordwand",
    "druhyRidic": "Zweiter Fahrer",
    "zakazDokladky": "Beiladung verboten",
    "adr": "ADR",
    "hydraulickaRuka": "Hydraulische Ladehilfe",
    "zpusobNakladky": "Beladungsmethode",
    "datumZalozeni": "Gründungsdatum",
    "datumArchivace": "Datum der Archivierung",
    "nadrozmernyNaklad": "Übergroße Ladung",
    "nakladkaZezadu": "Beladung von hinten",
    "nakladkaBokem": "Beladung von der Seite",
    "nakladkaShora": "Beladung von oben",
    "nakladkaZezaduShort": "von hinten",
    "nakladkaBokemShort": "von der Seite",
    "nakladkaShoraShort": "von oben",
    "doplnky": "Zubehör",
    "jine": "Andere",
    "souprava": "Zug",
    "naves": "Auflieger",
    "druhy": "Aufbautyp",
    "verejnaPozn": "Notiz",
    "uzivatel": "Anbieter",
    "dispecer": "Dispatcher",
    "History": "Geschichte",
    "InvalidPrepravaConfirm": "Wollen Sie die Fracht stornieren?",
    "ValidPrepravaConfirm": "Wollen Sie die Ungültigmachung der Fracht stornieren?",
    "InvalidVozidloConfirm": "Wollen Sie den Lkw stornieren?",
    "ValidVozidloConfirm": "Wollen Sie die Ungültigmachung des Lkws stornieren?",
    "Misto": "Platz",
    "NSJAnyMustBeChecked": "Wählen Sie mindestens einen Artikel von AZS aus",
    "NSJOneMustBeChecked": "Wählen Sie nur einen Artikel von AZS aus",
    "GeocodingCoordinates": "Ich erhalte Koordinaten von einer manuell eingegebenen Adresse",
    "DruhyOverflow": "Maximale Anzahl {0}",
    "AirDistance": "Distanz [km]",
    "AirDistanceShort": "KM",
    "AirDistanceToolTip": "Dies ist die berechnete Entfernung in Luftlinie",
    "CurrencyRequiredWhenPrice": "Es muss zusammen mit dem Preis ausgefüllt werden",
    "okoliOdkud": "Umkreis von",
    "okoliKam": "Umkreis nach",
    "radiusOdkud": "Umkreis von bis [km]",
    "radiusKam": "Umkreis nach bis [km]",
    "ArchiveConfirm": "Wollen Sie eine Fracht archivieren?",
    "rychlyKontakt": "Schneller Kontakt",
    "firma": "Firma",
    "airDistance": "Distanz [km]",
    "currency": "Währung",
    "nsj": "A,Z,S",
    "psc": "PLZ",
    "mpz": "LK"
  },
  "Provozovna": {
    "0": "Provozovna jede",
    "1": "Geschäft eingestellt",
    "Id": "Betriebs-ID",
    "Title": "Betrieb",
    "Nazev": "Name des Betriebs",
    "stopped": "Ausgesetzt",
    "PayInfo": "Fakturierung",
    "souhrnne": "Fakturierung insgesamt",
    "uOsobaUser": "Buchhalter",
    "uTelefonUser": "Buchhalter Telefon",
    "uEmailUser": "Buchhalter E-Mail",
    "uosobaUser": "Buchhalter",
    "utelefonUser": "Buchhalter Telefon",
    "uemailUser": "Buchhalter E-Mail",
    "uosoba": "Buchhalter (Fakturierung)",
    "utelefon": "Buchhalter Telefon (Fakturierung)",
    "uemail": "Buchhalter E-Mail (Fakturierung)",
    "OtherInfo": "Kontakte",
    "emaily": "E-Mails",
    "telCislaKontaktu": "Telefonnummern",
    "FilterInfo": "Nutzen Sie den einfachen/erweiterten Filter, um potenziell nach 30 relevanten Betrieben zu suchen",
    "UserIdentityLabel": "Identifizierung des Betriebs",
    "CompanyContactLabel": "Firmenanschrift",
    "UserContactLabel": "Betriebanschrift",
    "UserCorespondLabel": "Kontaktadresse",
    "KodProvozovny": "Betriebscode",
    "UserInDetail": "im Detail",
    "IcoDic": "ID / EU MwSt.Nr. / MwSt.N.",
    "PrefStat": "Worwahl Staat",
    "PNazev": "P Name",
    "PUlice": "P Straße",
    "PCisloPopisne": "P Anzahl der beschreibenden",
    "PCisloPopisneKratke": "P A.der b.",
    "PUliceCisloPopisne": "P Straße + A.der b.",
    "PObec": "P Dorf",
    "PCastObce": "P Teil des Dorfes",
    "PPsc": "P PLZ",
    "PStat": "P Staat",
    "KNazev": "K Name",
    "KAdresat": "K Adressat",
    "KUlice": "K Straße",
    "KCisloPopisne": "K Anzahl der beschreibenden",
    "KObec": "K Dorf",
    "KCastObce": "K Teil des Dorfes",
    "KPsc": "K PLZ",
    "KStat": "K Staat",
    "Kod": "Code",
    "datSmlouvyZmena": "Neuer Vertrag",
    "RaalJmeno1": "Raal Kontakt 1",
    "RaalJmeno2": "Raal Kontakt 2",
    "RaalJmeno3": "Raal Kontakt 3",
    "KdoZmenil": "Wer hat sich verändert",
    "KdyZmena": "Wann hat er sich verändert",
    "PotlacInzDatum": "Anzeigen unterdrücken – Datum",
    "ZasilatReport": "Bericht senden",
    "PotlacInz": "Werbung unterdrücken",
    "PotlacPrepravy": "Transporte unterdrücken",
    "PotlacVv": "Freie Lkws unterdrücken",
    "PotlacPrepravyDatum": "Transporte unterdrücken - Datum",
    "PotlacVvDatum": "Freie Lkws unterdrücken - Datum",
    "VytvoritProvozovnu": "Betrieb erstellen",
    "ZkusebniDoba": "Probezeit",
    "datzkus": "Probezeit bis",
    "ipDll": "IP-Adresse aus der Bestellung",
    "DatovaSchranka": "Datenfeld",
    "SmlouvaS": "Vertrag mit",
    "EmailProfa": "E-Mails zum Versenden von Rechnungen und Erinnerungen",
    "SmsUpominky": "Telefonnummern für SMS-Rechnungserinnerungen",
    "Pojisteni": "Versicherung",
    "PojisteniNakladu": "Frachtversicherung",
    "ZpusobFakturace": "Methode zum Versenden von Rechnungen",
    "email1": "Rechnungen per E-Mail versenden",
    "posta": "Rechnungen per Post verschicken",
    "odlisadr": "Andere Adresse",
    "Nezobrazovat": "Werden nicht angezeigt",
    "OmezeneInformace": "Begrenzte Informationen",
    "NonGdprInfo": "Informationen, die nicht der DSGVO entsprechen",
    "ZkraceneInformace": "Gekürzte Informationen",
    "Fax": "Fax",
    "FrekvenceFaktur": "Häufigkeit der Rechnungen",
    "Stav": "Status",
    "Podstav": "Status",
    "Dispecer": "Dispatcher",
    "dispeceri": "Dispatchers",
    "EmailsFilter": "E-mail (wird die Büros, Disponenten und Buchhalter durchsuchen)",
    "PhoneNumbersFilter": "Tel. N. (wird die Büros, Disponenten und Buchhalter durchsuchen)",
    "TooltipZmenaVeFirme": "Die Änderung wird das gesamte Unternehmen betreffen",
    "ProcessProvozovny": "Verarbeiten Sie andere Betriebe des jeweiligen Unternehmens?",
    "not1": "Notiz",
    "poznamka": "Administratornotiz",
    "Records": "Aufzeichnungen",
    "Uzavreno": "Geschlossen",
    "DatumUzavreni": "Einsendeschluss",
    "ManualDataFetch": "Nutzen Sie den einfachen/erweiterten Filter, um potenziell nach 30 relevanten Betrieben zu suchen",
    "PoslPripoj": "Letzte Verbindung",
    "PocPripoj": "Anzahl der Verbindungen",
    "PocetUzivatelu": "Anzahl der Nutzer",
    "Zalozen": "Gegründet",
    "datzmenstav": "Zustandsänderung",
    "SpStat": "Spezialisiert auf Staaten",
    "jazyky": "Sprachen",
    "Auta": "Eigene Lkws",
    "vs": "Variables Symbol",
    "GenerateUsers": "Benutzer generieren",
    "ToolTipGenerateUsers": "Geben Sie ein, wie viele lizenzierte Benutzer automatisch generiert werden sollen",
    "ContainsNumberOfBlocked": "Die Einrichtung enthält die Telefonnummern gesperrter Einrichtungen: {0}. Möchten Sie die Aufnahme speichern?",
    "okoli": "Umkreis",
    "radius": "Umkreis [km]",
    "airDistance": "Distanz [km]",
    "notEqualAddress": "Die Adresse des Unternehmens und der Niederlassung stimmen nicht überein",
    "equalAddress": "Die Adresse des Unternehmens und der Niederlassung stimmen überein",
    "historieUzivatelu": "Benutzerhistorie",
    "Smlouvy": "Verträge",
    "LastChange": "Letzte Bearbeitung"
  },
  "PVI": {
    "RefreshRecord": "Erneuern (überreste {{value}}x)",
    "Refresh": "Erneuern",
    "UpdateRecord": "Änderungen speichern (überreste {{value}}x)",
    "Inserted": "Eingefügt",
    "Modified": "Ändern",
    "Creator": "Benutzer",
    "CreatedOn": "Erstellungsdatum",
    "ModifiedOn": "Datum der Änderung",
    "ListEmpty": "Die Liste ist leer",
    "Marked": "Markiert",
    "InvalDuv": "Grund für die Ungültigmachung",
    "Valid": "Gültig",
    "codes": {
      "P": "F",
      "V": "L",
      "I": "A",
      "All": "FLA",
      "S": "S",
      "T": "T",
      "H": "H",
      "Pr": "P",
      "U": "U",
      "F": "F"
    },
    "tooltip": {
      "P": "Frachten",
      "V": "Lkws",
      "I": "Anzeigen",
      "All": "PVI",
      "S": "Liste der Fahrzeuge",
      "T": "Routen",
      "H": "Fehler melden",
      "Pr": "Betriebsstätte",
      "U": "Benutzer",
      "F": "Firmen",
      "TRASA": "Routenberechnung",
      "TRASY": "Gespeicherte Strecken",
      "UpdateButton": "Speichern/Aktualisieren aktualisiert das Datum, nach dem die Angebote in der Suche sortiert werden.",
      "UpdateDisabledButton": "Die maximale Anzahl, wie oft das Angebot erneut gespeichert werden kann, ist bereits erreicht"
    }
  },
  "RAALKratkyKody": {
    "N": "A",
    "S": "Z",
    "J": "S",
    "ADR": "ADR"
  },
  "Sections": {
    "Start": "Einführung",
    "Task": "Anlegen",
    "Browse": "Ansicht",
    "Company": "Betriebsstätte",
    "Messages": "RAAL-Meldungen",
    "ArchiveTask": "Archiv des Anlegens",
    "ArchiveBrowse": "Archiv der Ansicht",
    "Pass": "Leerfahrten",
    "Fill": "Beiladungen",
    "CollectionService": "Abholservice",
    "Mileage": "Straßenschema",
    "Assign": "Anlegen",
    "View": "Ansicht",
    "Master": "Verwaltung",
    "Administration": "Administration",
    "Logs": "Logs",
    "Help": "Hilfe",
    "Zpravy": "Meldungen",
    "Informations": "Information",
    "Learning": "Ausbildung",
    "Support": "Benutzer-Support",
    "Stiznosti": {
      "Title": "Beschwerden",
      "Statistiky": "Statistiken"
    },
    "Admin": {
      "Offers": "Angebote",
      "History": "Geschichte",
      "Archiv": "Archiv"
    },
    "History": "Geschichte",
    "User": {
      "Kilometrovnik": "Straßenschema"
    },
    "Search": "Suche",
    "Soubory": "Datei",
    "Others": "Weiter"
  },
  "ShowHidePhones": {
    "true": "Telefonnummern",
    "false": "Verstecken"
  },
  "ShowHideMap": {
    "true": "Koordinaten eingeben",
    "false": "Verstecken"
  },
  "Stat": {
    "Id": "ID",
    "nazevCz": "Name",
    "countryCode": "Code",
    "Flag": "Fahne",
    "TooltipKodZeme": "Geben Sie den Ländercode für OpenStreetMap-Zwecke ein",
    "predvolba": "Vorwahl"
  },
  "State": {
    "true": "aktiv",
    "false": "inaktiv"
  },
  "SystemParameter": {
    "Value": "Wert"
  },
  "Texty": {
    "Kod": "Code",
    "Hodnota": "Wert ({0})",
    "TypTextu": "Typ"
  },
  "Theme": {
    "ThemeChange": "Hell/Dunkel-Thema",
    "Title": "Thema",
    "Dark": "Dunkel",
    "Light": "Hell"
  },
  "ThunderNews": {
    "Notifications": "Benachrichtigung",
    "MessagesType": "Nachrichtentyp",
    "MessagesInstantLabel": "Blitzmeldung",
    "MessagesInstantUsers": "Warnung: Wenn Sie keinen bestimmten Benutzer / kein bestimmtes Unternehmen / keine bestimmte Einrichtung auswählen, wird die Blitzmeldung an ALLE Benutzer gesendet!",
    "MessagesValidTo": "Gültig bis",
    "NoMessages": "Keine Meldungen",
    "AllMessages": "Alle Meldungen",
    "Predmet": "Objekt",
    "TypZpravy": "Nachrichtentyp",
    "Notification": "Du hast eine neue Nachricht",
    "Ok": "Verstecken",
    "Open": "Offen"
  },
  "TimeMeasures": {
    "minutes": {
      "plural": "Minuten",
      "singular": "Minute",
      "genitivPlural": "Minuten"
    },
    "hours": {
      "plural": "Uhren",
      "singular": "Uhr",
      "genitivPlural": "Uhren"
    }
  },
  "User": {
    "Id": "Benutzer-ID",
    "Title": "Benutzer",
    "provozovna": "Betriebsstätte",
    "login": "Anmeldungname",
    "jmeno": "Benutzername",
    "Password": "Passwort",
    "SignIn": "Anmeldung",
    "AddNew": "Ein neues Konto hinzufügen",
    "ChangePassword": "Passwort ändern",
    "Switch": "Schalten",
    "Remove": "Entfernen",
    "roles": "Rolle",
    "IPAdresaPosledni": "Letzte IP-Adresse",
    "View": "Anbieter",
    "ViewInfo": "Informationen über den Anbieter",
    "ToolTipGenerateUsers": "Benutzer und Lizenzen generieren",
    "KdoZmenil": "Wer hat sich verändert",
    "NoUserRoleError": "Dies ist eine Lizenz, bitte stoppen Sie die gesamte Prämisse.",
    "lastAction": "Letzte Aktion"
  },
  "LoginPage": {
    "Info": "1. DATENBANK DER LADUNGEN UND VERFÜGBAREN WAGEN seit 1992",
    "NewPassword": "Neu Passwort",
    "ConfirmPassword": "Passwort Bestätigung",
    "Captcha": "Code aus dem Bild",
    "GenerateNewCaptcha": "Ein neues Bild erzeugen",
    "RememberMe": "Erinnere dich an mich",
    "TroubleLoggingIn": "Haben sie probleme beim einloggen?",
    "RegularCustomersTitle": "Stammkunden",
    "RegularCustomersInfo": "Die Anmeldeinformationen wurden Ihnen per E-Mail von der Adresse <a href=\"mailto:noreply@raaltrans.com\">noreply@raaltrans.com</a> zugesandt. Bei Problemen kontaktieren Sie uns <span>(+420 495 217 481 – 2)</span> und wir helfen Ihnen gerne weiter. Alternativ können Sie uns auch unter <a href=\"mailto:support@raal.cz\">support@raal.cz</a> kontaktieren.",
    "NewCustomersTitle": "Neue kunden",
    "NewCustomersInfo": "Ein Bestellformular finden Sie auf unserer Website <a href=\"https://www.raal.cz/\" target=\"_blank\" rel=\"noopener noreferrer\">www.raal.cz</a>. Wir werden uns nach der Bearbeitung der Bestellung mit Ihnen in Verbindung setzen."
  },
  "InputErrors": {
    "Password": {
      "ConfirmationNotMatch": "Die Passwörter stimmen nicht überein.",
      "TooShort": "Das Passwort ist zu kurz, die Mindestlänge beträgt 6 Zeichen."
    }
  },
  "UserModified": {
    "Title": "Benutzerdaten geändert",
    "Body": "Um Änderungen an Ihrem Benutzerkonto vollständig zu initialisieren, müssen Sie sich wiederholt anmelden. Jetzt abmelden?"
  },
  "Validations": {
    "RychlyKontaktLengthConstraint": "Die Anzahl der Zeichen muss zwischen 9 und 15 liegen"
  },
  "Websocket": {
    "Connected": "In Verbindung gebracht",
    "Disconnected": "Getrennt",
    "Connecting": "Verbinden"
  },
  "YesNo": {
    "false": "Nein",
    "true": "Ja"
  },
  "Kilometrovnik": {
    "Psc": "PLZ",
    "Nazev": "Name",
    "Cc2": "Länderkennzeichen",
    "Typ": "Typ",
    "Lat": "geographische Breite",
    "Lon": "geographische Länge",
    "DisplayRoute": "Route und Kosten",
    "DisplayRouteOnly": "Nur Route",
    "Vozidlo": "Fahrzeug",
    "Note": "Do polí \"Leehfahrt von\" a \"Leehfahrt nach\" zadejte místa, která co nejvíce odpovídají Vaší trase. To může být např. místo, kde stojí nevytížené auto a místo, kam s ním chcete dojet, např. sídlo firmy. V polích \"Maximální přejezd odkud/kam/celkem\" lze nepovinně zadat, z jak velkého okolí od zadaných míst se mají nabídky zobrazovat. Necháte-li příslušné okolí nevyplněné, vyberou se nabídky bez ohledu na vzdálenost od zvoleného místa."
  },
  "Contacts": {
    "SendEmail": "E-mail senden",
    "SipEmail": "Kontakt über SIP-Protokoll (Teams usw.)",
    "Call": "Anrufen",
    "SMS": "SMS senden",
    "WhatsApp": "WhatsApp",
    "Viber": "Viber"
  },
  "Klasifikace": {
    "klasifikace": "Klassifikation",
    "klasifikaceShort": "[K]",
    "nezobrazovat": "Keine Angebote anzeigen",
    "poznamka": "Benutzerhinweis"
  },
  "ProfilVozidla": {
    "title": "Fahrzeuge",
    "nazev": "Name",
    "casovyNakladH": "Zeitkosten [Preis/Uhr]",
    "casovyNakladHTooltip": "Zeitkosten (z.B. Fahrergehalt)",
    "fixniNaklad": "Fixkosten [Preis/km]",
    "fixniNakladTooltip": "(Abschreibungen)",
    "spotreba": "Verbrauch [l / 100 km]",
    "cenaPaliva": "Kraftstoffpreis [cena/l]",
    "tollPrice": "Mauttarif",
    "osrmProfile": "Navigationsprofil",
    "displayPreprava": "In Transporten anzeigen",
    "displayVozidlo": "In freie Lkws anzeigen",
    "defaultPreprava": "Ausfall bei Transporten",
    "defaultVozidlo": "Ausfall bei Lkws",
    "properties": "Fahrzeugeigenschaften",
    "maxWeight": "Gesamtgewicht des Fahrzeugs [t]",
    "maxHeight": "Gesamthöhe des Fahrzeugs [m]",
    "maxWidth": "Gesamtbreite des Fahrzeugs [m]",
    "maxLength": "Gesamtlänge des Fahrzeugs [m]",
    "hazmat": "Transport gefährlicher Stoffe",
    "hazmatWater": "Transport von wassergefährdenden Materialien",
    "switzerland": "Vermeiden Sie die Schweiz, wenn es in diesem Land kein Be-/Entladen gibt",
    "delka": "Ladelänge [m]",
    "vaha": "Ladegewicht [t]"
  },
  "CenaMyta": {
    "title": "Mautpreis",
    "kodZeme": "Staat",
    "sazba": "Rate [Preis/km]",
    "typSilnice": "Straßentyp",
    "CurrencyRequiredWhenPrice": "Es muss zusammen mit dem Tarif ausgefüllt werden"
  },
  "Trasa": {
    "title": "Routen",
    "Calculate": "Umrechnen",
    "CalculateAndSave": "Umrechnen und verlagern",
    "nazev": "Name",
    "state": "Berechnungsstatus",
    "profilVozidla": "Fahrzeugprofil",
    "currency": "Kostenberechnungswährung",
    "distance": "Distanz",
    "duration": "Fahrzeit",
    "cost": "Kosten",
    "mpz": "LK",
    "distancePaid": "Bezahlte Abschnitte [km]",
    "distanceUnpaid": "Unbezahlte Abschnitte [km]",
    "currencyTooltip": "Ungefähre Berechnung nach dem ČNB-Wechselkurs",
    "modifiedOn": "Datum der Änderung"
  },
  "KilometrovnikHlaseni": {
    "osrmId": "OSRM ID",
    "report": "Report",
    "status": "Status",
    "uzivatel": "Benutzer",
    "osrmEdit": "Bericht an OSRM",
    "process": "Sperren",
    "reportSent": "Die Nachricht wurde versendet",
    "title": "Gemeldete Fehler",
    "datIns": "Erstellungsdatum",
    "datUzavreni": "Schließungdatum"
  },
  "Dokladky": {
    "kilometrovnik": "Straßenschema Beiladung",
    "nakladka": "Aufladestelle",
    "vykladka": "Entladungstelle",
    "odkud": "Fracht - von",
    "kam": "Fracht - nach",
    "vyhodnost": "Bequemlichkeit",
    "dokladky": "Beiladungen",
    "zajizdka": "Umleitung [km]",
    "datumDokladkyOd": "Datum des Beiladung von",
    "datumDokladkyDo": "Datum des Beiladung bis",
    "zadaniDokladky": "Beiladungen der Frachten",
    "calculate": "Nach Beiladungen suchen",
    "preprava": "Fracht",
    "total": "Insgesamt",
    "original": "Original",
    "dokladka": "Mit der Beiladung",
    "Preprava": "Original Fracht",
    "datum": "Datum"
  },
  "SbernaSluzba": {
    "sbernaSluzba": "Abholservice",
    "datumOd": "Frachten von",
    "datumDo": "Frachten bis",
    "calculate": "Errechnen",
    "trasa": "Route",
    "vozidlo": "Fahrzeug",
    "datum": "Datum",
    "search": "Abholservice anlegen",
    "prepravaOrder": "Rangliste",
    "map": "Sehen Sie sich die Karte an",
    "noTransportsFound": "Es wurden keine passenden Transporte gefunden",
    "profilVozidlaAtributy": "Das Fahrzeugprofil muss ausgefüllt werden: Währung, Verbrauch, Fixkosten",
    "parametryVypoctu": "Berechnungsparameter",
    "maxPrejezd": "Maximale Überfahrt von der Ladestelle [km]",
    "maxZajizdka": "Maximale Umwege auf der Strecke [km]",
    "nalezeniPreprav": "Erfüllung der Abholservice",
    "neobsahujePrepravy": "Frachten automatisch ergänzen?",
    "doplnitPrepravy": "Frachten automatisch hinzufügen",
    "pridatPrepravu": "den Frachten manuell hinzufügen",
    "pridaniPrepravy": "den Frachten automatisch hinzufügen",
    "calculateToll": "die Kosten berechnen",
    "maxDelka": "Maximale Länge der Teilfrachten [m]",
    "maxVaha": "Maximalgewichte der Teilfrachten [t]",
    "PrepravaRemoveConfirm": "Möchten Sie das Hauptmenü wirklich entfernen? Wenn Sie diese Aktion bestätigen, werden alle Angebote entfernt.",
    "noveZadani": "Neue Auftrag",
    "noveZadaniBody": "Der Auftrag enthält Transporte, Sie möchten die Transporte löschen und von vorne beginnen?",
    "processing": "Die Aktion kann nicht ausgeführt werden, da gerade eine Berechnung ausgeführt wird."
  },
  "ServerErrors": {
    "404": "Die Informationen wurden auf dem Server nicht gefunden",
    "500": "Interner Serverfehler",
    "FailedToFetch": "Daten konnten nicht geladen werden. Bitte überprüfen Sie Ihre Internetverbindung.",
    "UnableToGeocode": "Die Geokodierung der Koordinate ist fehlgeschlagen",
    "NetworkError": "Bei der Verarbeitung der Anfrage ist ein Netzwerkfehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung."
  },
  "SeznamMaps": {
    "Title": "Landkarte Seznam.cz"
  },
  "SearchEnum": {
    "entityType": "Aufnahmetyp",
    "Preprava": {
      "PREPRAVA": "N",
      "PREPRAVA_HISTORIE": "N (H)",
      "PREPRAVA_ARCHIV": "A",
      "PREPRAVA_HISTORIE_ARCHIV": "A (H)"
    },
    "VolneVozy": {
      "VOZIDLO": "N",
      "VOZIDLO_HISTORIE": "N (H)",
      "VOZIDLO_ARCHIV": "A",
      "VOZIDLO_HISTORIE_ARCHIV": "A (H)"
    },
    "Inzerat": {
      "INZERAT": "N",
      "INZERAT_HISTORIE": "N (H)",
      "INZERAT_ARCHIV": "A",
      "INZERAT_HISTORIE_ARCHIV": "A (H)"
    }
  },
  "TrikratADost": {
    "Title": "Statistika \"3x und genug\" ke dni {0}",
    "celkemPripadu": "Gesamtzahl {0} der Fälle",
    "celkemCastky": "Gesamtbeträge: {0}",
    "NadpripadyTotal": "Super Fälle: {0} offen und {1} geschlossen, davon {2} geschlossene Rücktritte",
    "Stav": "Zustand",
    "Castka": "Summe",
    "CurrencyCode": "Währung",
    "PocetPripad": "Zahl der Fälle"
  },
  "DOMException": {
    "AbortError": "Das Laden der Daten wurde unterbrochen (oder der Server hat nicht in der erforderlichen Zeit geantwortet)."
  },
  "Diag": {
    "host": "Gast",
    "serverPort": "Port",
    "healthy": "Zustand",
    "instances": "Instanz",
    "dbReplicas": "Datenbankreplikate",
    "couldNotLoad": "Die Daten konnten nicht geladen werden",
    "replicationNotActive": "Die Datenbank wird nicht repliziert",
    "resources": "Ressourcen",
    "requiredDbConnectionsRead": "Erforderliche DB-Verbindungen zum Lesen",
    "requiredDbConnectionsWrite": "Erforderliche DB-Verbindungen zum Schreiben",
    "availableDbConnectionsRead": "Verfügbare DB-Verbindungen zum Lesen (1 db)",
    "availableDbConnectionsWrite": "Verfügbare DB-Verbindungen zum Schreiben",
    "availableDbConnections": "Verfügbare DB-Verbindungen",
    "dbConnectionsNotEnough": "Die Anzahl der Datenbankverbindungen reicht möglicherweise nicht aus, um den Bedarf der Server zu decken",
    "jobs": "Jobs",
    "rateLimit": "Rate limit info"
  },
  "ArchiveRecord": {
    "typ": "Typ",
    "poznamka": "Notiz",
    "owner": "Archiviert"
  },
  "Archive": {
    "Archive": "Archiv",
    "Archived": "Archiviert"
  },
  "Poznamka": {
    "poznamka": "Notiz",
    "uzivatel": "Letzter Autor",
    "datIns": "Erstellungsdatum"
  },
  "CommonGrid": {
    "History": "Geschichte"
  },
  "DluhNepohOsoba": {
    "Prijmeni": "Nachname",
    "Jmeno": "Name",
    "Rodcis": "Geburtsnummer",
    "Adresa": "Adresse",
    "Ica": "ID Nummer",
    "Poznamka": "Notiz",
    "DatCas": "Datum",
    "Uzivatel": "Von wem geändert",
    "Provozovny": "Betriebsstätte"
  },
  "EmailForm": {
    "Sender": "Absender",
    "Subject": "E-Mail Betreff",
    "Receiver": "Senden Sie eine E-Mail an die Adresse",
    "Body": "E-Mail-Inhalt"
  },
  "EmailStiznostiForm": {
    "smlouva": "Vertragsdatum",
    "pozastaveno": "Sperren Sie den Zugriff auf den Tag"
  },
  "WithUserBase": {
    "Id": "Nummer",
    "DatCas": "Zeitpunkt der letzten Änderung",
    "Uzivatel": "Den letzten hat er geändert",
    "Zmena": "Letzte Bearbeitung",
    "Generovat": "Generieren und senden",
    "ChooseLanguage": "Sprachauswahl"
  },
  "DluhPripad": {
    "Dluznik": "Schuldner",
    "Veritel": "Gläubiger",
    "ZodpOsoba": "Verantwortliche",
    "IcoDluznika": "ID-Nummer des Schuldners",
    "DicDluznika": "MwSt.-Nummer des Schuldners ",
    "RegNazevDluznika": "Name von Schuldner",
    "RegUliceDluz": "Straße des Schuldners",
    "RegSidloDluz": "Dorf des Schuldners",
    "RegPscDluz": "PLZ des Schuldners",
    "RegStatDluz": "Staat des Schuldners",
    "PrefStatDluz": "Vorwahl des Schuldners",
    "PrefMestoDluz": "Vorwahl der Stadt",
    "FaxDluz": "Fax des Schuldners",
    "TelDluz": "Telefonnummer des Schuldners",
    "MobilDluz": "Mobilnummer des Schuldners",
    "HttpDluz": "WWW des Schuldners",
    "EmailDluz": "E-mail des Schuldners",
    "IcqDluz": "Icq des Schuldners",
    "SkypeIdDluz": "Skype des Schuldners",
    "ProvozovnaDluznika": "Betriebsstätte des Schuldners",
    "ProvozovnaDluznikaStav": "Status der Betriebsstätte des Schuldners",
    "zodpMailDluz": "Verantwortliche Person des Schuldners",
    "IcoVer": "ID-Nummer des Gläubigers",
    "DicVer": "MwSt.-Nummer des Gläubigers",
    "RegNazevVer": "Name von Gläubiger",
    "RegUliceVer": "'Straße des Gläubigers",
    "RegSidloVer": "Dorf des Gläubigers",
    "RegPscVer": "PLZ des Gläubigers",
    "RegStatVer": "Staat des Gläubigers",
    "PrefStatVer": "Vorwahl des Gläubigers",
    "ProvozovnaVer": "Betriebsstätte des Gläubigers",
    "ProvozovnaVerStav": "Status der Betriebsstätte des Gläubigers",
    "zodpMailVer": "Verantwortliche Person des Gläubigers",
    "PrefMestoVer": "Stadtcode des Gläubigers",
    "FaxVer": "Fax des Gläubigers",
    "TelVer": "Telefonnummer des Gläubigers",
    "MobilVer": "Mobilnummer des Gläubigers",
    "HttpVer": "WWW des Gläubigers",
    "EmailVer": "E-mail des Gläubigers",
    "IcqVer": "Icq des Gläubigers",
    "SkypeIdVer": "Skype des Gläubigers",
    "KdoZaloz": "Wer hat gegründet",
    "DatCas": "Datum",
    "Stav": "Zustand",
    "ZodpTel": "Telefonnummer der verantwortlichen Person",
    "ZodpEmail": "E-mail der verantwortlichen Person",
    "Kontakty": "Kontakte",
    "ProvozovnyFirmy": "Das Gelände der Muttergesellschaft",
    "AktKeDni": "Aktualisierung zum Tag",
    "AktKeDniButton": "Jetzt",
    "GenerovatDopisVer": "Erstellen Sie einen Brief und senden Sie ihn an den Gläubiger",
    "GenerovatDopisDluznik": "Erstellen Sie einen Brief und senden Sie ihn an den Schuldner",
    "StavProvozovnyDluznika": "Status der Betriebsstätte des Schuldners",
    "StavProvozovnyVer": "Status der Betriebsstätte des Gläubiger",
    "StavAction": {
      "otevrit": "Öffnen",
      "uzavrit": "Abschließen",
      "vyresitBezUhrady": "Ohne Bezahlung lösen",
      "promlcet": "Verjähren",
      "zamitnout": "Abweisen",
      "Confirmation": "Der Status wurde erfolgreich geändert"
    },
    "PripadExist": "AUFMERKSAMKEIT! Im System sind bereits aktive Fälle mit demselben Schuldner und Gläubiger vorhanden: {pripadyIds}! Wollen Sie fortsetzen?",
    "FaktExist": "AUFMERKSAMKEIT! In folgenden Fällen ist bereits eine Rechnung mit dieser Nummer im System vorhanden: {pripadyIds}! Wollen Sie fortsetzen?",
    "PripadExistTitle": "Bestätigung",
    "FaktExistTitle": "Bestätigung",
    "Active": "Aktiv"
  },
  "DluhNadpripad": {
    "NadpripadId": "Überfall",
    "Stav": "Zustand",
    "KdyPozastavit": "Wann sperren",
    "Vypoved": "Kündigung",
    "Bod39": "Punkt 3.9",
    "Upozorneni": "Hinweisung",
    "Confirmation": "Bestätigung",
    "GenerovatDopisDluznik": "Erstellen Sie einen Brief und senden Sie ihn an den Schuldner",
    "StavAction": {
      "otevrit": "Öffnen",
      "uzavritSUhradou": "Schließen (mit Zahlung)",
      "uzavritBezUhrady": "Schließen (ohne Zahlung)",
      "uzavritVypoved": "Schließen (Kündigung)",
      "uzavrit39": "Schließen (Punkt 3.9)",
      "New": "Du willst wirklich einen Überfall aufbauen?",
      "CreateConfirmation": "Der Überfall wurde erfolgreich erstellt"
    },
    "Buttons": {
      "New": "Überfall Erstellen",
      "Navigate": "Überfall ansehen",
      "NavigateToPripad": "Zurück zum Fall"
    }
  },
  "DluhFakt": {
    "Title": "Rechnungen",
    "Cislo": "Rechnungsnummer",
    "Castka": "Summe",
    "DatSplat": "Fälligkeitsdatum",
    "KdyUhradil": "Wann bezahlt",
    "KdoUhradil": "Wer bezahlt",
    "KdyBezUhrady": "Wann ohne Zahlung",
    "KdoBezUhrady": "Wer ohne Zahlung",
    "Stav": "Zustand",
    "UdajeFaktury": "Rechnungsdaten",
    "OhlasUhr": "Zahlungsmitteilung des Gläubigers",
    "DuvodZamitnuti": "Grund für die Ablehnung",
    "Poznamka": "Notiz",
    "Poznamky": "Notizen",
    "StavAction": {
      "uhradit": "Bezahlen",
      "vyresitBezUhrady": "Ohne Bezahlung lösen",
      "promlcet": "Verjähren",
      "zamitnout": "Abweisen",
      "otevrit": "Öffnen"
    }
  },
  "DluhSoub": {
    "Title": "Datei",
    "Soubor": "Datei",
    "NovySoubor": "Neue Datei",
    "Popis": "Beschreibung",
    "Stazeni": "Datei herunterladen"
  },
  "DluhStateActionConfirmDialog": {
    "Title": "Bestätigung",
    "Body": "Wollen Sie wirklich das unternehmen {{action}}?"
  },
  "ReportText": {
    "TextKey": "Schlüssel",
    "Translation": "Übersetzung"
  },
  "Report": {
    "Language": "Sprache"
  },
  "popis": "Beschreibung",
  "NetworkError": "Netzwerkfehler",
  "UnexpectedError": "Bei der Verarbeitung der Anfrage ist ein unerwarteter Fehler aufgetreten",
  "Error401": "Abgemeldet",
  "Error401FingerPrint": "Sie wurden abgemeldet, weil Sie sich mit einem anderen Browser angemeldet haben.",
  "SignedOut": "Ausloggen",
  "BadCredentials": "Schlechte Anmeldedaten",
  "RecordCannotBeSaved": "Die Aufnahme kann nicht gespeichert werden",
  "ModificationConcurrency": "Die Entität wurde von einem anderen Benutzer geändert",
  "LoginFailed": "Fehler bei der Anmeldung",
  "LoginFailedGeneratedPwd": "Fehler bei der Anmeldung. Bitte überprüfen Sie mithilfe der Augentaste neben dem Passwort, ob Sie das richtige Passwort eingegeben haben.",
  "PasswordChangeRequired": "Passwortänderung erforderlich",
  "TryConnectAgain": "Versuchen Sie erneut, eine Verbindung herzustellen",
  "ConnectionError": "Verbindungsfehler. Entweder sind die Server ausgefallen oder Sie haben keinen Internetzugang.",
  "Unique": "Der Datensatz wird dupliziert",
  "ipDll": "Letzte IP",
  "DataFetchError": "Beim Laden der Daten ist ein Fehler aufgetreten",
  "WebsocketConnecting": "Ich stelle eine Verbindung her",
  "Attempt": "Versuchen",
  "ArchiveRecordShort": "Archivieren",
  "RestoreRecordShort": "Erneuen",
  "ArchiveSuccess": "Die Archivierung war erfolgreich",
  "AppActivityTypeName": "Typ",
  "AppActivitySubTypeName": "Untertyp",
  "ActivityViewType": "Methode anzeigen",
  "ArchiveView": "Archivierung des angesehenen Angebots",
  "Actions": "Aktion",
  "Errors": {
    "CouldNotFetchCode": "Beim Laden des freien Betriebsstättecodes ist ein Fehler aufgetreten.",
    "CouldNotFetchCount": "Beim Abrufen der Anzahl der Datensätze ist ein Fehler aufgetreten.",
    "CouldNotCancelEdit": "Bearbeitung kann nicht rückgängig gemacht werden",
    "CouldNotEdit": "Datensatz kann nicht bearbeitet werden",
    "CouldNotDelete": "Datensatz konnte nicht gelöscht werden",
    "CouldNotDeleteIsLocked": "Der Datensatz konnte nicht gelöscht werden, der Datensatz ist gesperrt",
    "CouldNotArchiveIsLocked": "Der Datensatz konnte nicht archiviert werden, der Datensatz ist gesperrt",
    "LockLost": "Die Bearbeitung wurde abgebrochen, da die Datensatz entsperrt wurde",
    "SystemOverload": "Die Seite kann nicht angezeigt werden, da das System mit der Bearbeitung anderer Anfragen beschäftigt ist. Bitte versuchen Sie später, die Seite anzuzeigen."
  },
  "Unable to geocode": "Die Standortadresse konnte nicht ermittelt werden",
  "Consent": {
    "BasicInfo": "Grundinformation",
    "LegalDocs": "Rechtliche Dokumentation",
    "Agreements": "Genehmigung der rechtlichen Dokumentation",
    "Warning": "Bitte stimmen Sie den folgenden Bedingungen zu, bevor Sie die RAALTRANS Mobile App in vollem Umfang nutzen können.",
    "MinimalRequirements": "Minimale Systemvoraussetzungen",
    "VPAgreement": "Ich stimme den Allgemeinen Geschäftsbedingungen zu",
    "LPAgreement": "Ich stimme den Lizenzbedingungen zu",
    "GDPRAgreement": "Ich stimme der Datenschutzerklärung zu",
    "PDIAgreement": "Ich stimme dem Vertrag zur Bereitstellung von Verkehrsinformationen zu.",
    "Download": "Herunterladen",
    "Open": "Offen"
  },
  "PravniDokumentace": {
    "TypSmlouvy": "Vertragstyp",
    "Soubor": "Vertrag",
    "DatumPodepsaniRall": "Unterzeichnungsdatum durch RAALTRANS",
    "DatumPodepsaniPrijemce": "Bestätigungsdatum durch empfänger",
    "DatumVytvoreni": "Erstellungsdatum",
    "Sign": "Verträge unterschreiben",
    "SignConfirm": "Möchten Sie wirklich alle Verträge unterschreiben?",
    "AlreadySigned": "Verträge wurden bereits unterschrieben",
    "SignFailed": "Unterschrift der Verträge fehlgeschlagen",
    "SignSuccess": "Verträge wurden unterschrieben",
    "Delete": "Verträge löschen",
    "DeleteConfirm": "Sind Sie sicher, dass Sie alle Verträge löschen möchten?",
    "AlreadyDeleted": "Verträge wurden bereits gelöscht",
    "DeleteFailed": "Verträge konnten nicht gelöscht werden",
    "DeleteSuccess": "Verträge wurden gelöscht"
  },
  "StaticLinks": {
    "Thumbnail": {
      "MasterTutorial": "/videos/thumbnail/master_tutorial_de.jpg"
    }
  },
  "Cms": {
    "Oznameni": {
      "TitleAfterLogin": "Neuigkeiten seit dem letzten Login",
      "TitleStomp": "Wichtiger Hinweis",
      "ReloadPage": "AKTUALISIEREN WEBSITE",
      "ReloadInfo": "Die Anwendung wurde gerade auf eine neue Version aktualisiert – die Seite muss aktualisiert werden, damit sie ordnungsgemäß funktioniert.",
      "ReloadDisclaimer": "Andernfalls funktionieren einige Funktionen möglicherweise nicht ordnungsgemäß."
    }
  },
  "Oznaceni": {
    "DeleteViewRecords": "Ansichtsverlauf löschen",
    "ViewRecordsClearConfirm": "Möchten Sie Ihren Anzeigeverlauf wirklich löschen?"
  },
  "NabidkaMultiCRUD": {
    "RefreshTooltip": "Datensätze aktualisieren",
    "DeleteTooltip": "Datensätze löschen",
    "RefreshConfirm": "Sind Sie sicher, dass Sie alle ausgewählten Datensätze aktualisieren möchten?",
    "DeleteConfirm": "Sind Sie sicher, dass Sie alle ausgewählten Datensätze löschen möchten?",
    "RefreshSuccess": "Angebote wurden aktualisiert",
    "DeleteSuccess": "Angebote wurden gelöscht",
    "UnableToRefresh": "Einige Angebote konnten nicht aktualisiert werden",
    "UnableToDelete": "Einige Angebote konnten nicht gelöscht werden",
    "AlreadyDeletedRecords": "Bereits nicht vorhanden: {0}x",
    "LockedRecords": "Gesperrt: {0}x",
    "MaxEditsReachedRecords": "Maximale Anzahl an Aktualisierungen erreicht: {0}x",
    "ExpiredOfferRecords": "Ungültige angebote: {0}x",
    "InvalidOffersDateChangeInfo": "Ausgewählte Angebote enthalten ein Datum, das bereits in der Vergangenheit liegt. Um eine Erneuerung zu ermöglichen, muss ein neues Datum festgelegt werden:",
    "ValidOffersDateChangeInfo": "Hinweis: Die Auswahl enthält auch gültige Angebote - bei diesen wird nur das Enddatum geändert.",
    "DateFrom": "Datum von",
    "DateTo": "Datum bis"
  },
  "VlastniMisto": {
    "titulek": "Einen neuen Standort hinzufügen",
    "Pridat": "Fügen Sie einen neuen Ort hinzu",
    "mpz": "LK",
    "psc": "PLZ",
    "mesto": "Stadt",
    "notFound": "Der Standort wurde nicht gefunden. Sie können ihn verwenden, aber die Entfernungskilometer werden nicht berechnet."
  },
  "Mail": {
    "KodZadavateleNabidky": "BIETERCODE",
    "PrepravaSubject": "Reaktion auf Transportangebot in RAALTRANS ({0})",
    "VozidloSubject": "Reaktion auf das Angebot eines kostenlosen LKWs in RAALTRANS ({0})",
    "InzeratSubject": "Reaktion auf das Werbeangebot in RAALTRANS ({0})",
    "PrepravaBodyBegin": "Wir antworten auf Ihr in der RAALTRANS-Datenbank veröffentlichtes Transportangebot (unser Code in RAALTRANS ist {0}):",
    "VozidloBodyBegin": "Wir antworten auf Ihr kostenloses LKW-Angebot, das in der RAALTRANS-Datenbank veröffentlicht wurde (unser Code in RAALTRANS ist {0}):",
    "InzeratBodyBegin": "Wir reagieren auf Ihre in der RAALTRANS-Datenbank veröffentlichte Anzeige (unser Code in RAALTRANS ist {0}):"
  },
  "DataTemplate": {
    "Save": "Vorlage speichern",
    "Name": "Vorlagenname",
    "Add": "Vorlage hinzufügen",
    "Select": "Wählen Sie eine Vorlage aus, um ein Menü zu erstellen",
    "SavedTemplates": "Gespeicherte Vorlagen",
    "NoData": "Keine Vorlage gefunden",
    "DeleteConfirmationText": "Sind Sie sicher, dass Sie die Vorlage löschen möchten?",
    "RemoveTemplate": "Löschen",
    "Saved": "Die Vorlage wurde gespeichert",
    "SaveFailed": "Die Vorlage konnte nicht gespeichert werden, der Name ist bereits einer anderen Vorlage zugewiesen oder der Name ist zu lang",
    "Edited": "Die Vorlage wurde bearbeitet",
    "EditFailed": "Vorlage konnte nicht bearbeitet werden",
    "Removed": "Die Vorlage wurde entfernt",
    "RemoveFailed": "Entfernen der Vorlage fehlgeschlagen"
  },
  "Here": {
    "Dial": {
      "TitulekTabulky": "Fahrzeuge",
      "Nazev": "Name",
      "Mena": "Währung",
      "Vyska": "Höhe [m]",
      "Sirka": "Breite [m]",
      "Delka": "Länge [m]",
      "CelkovaVaha": "Gesamtgewicht [t]",
      "CreateRecordAsNew": "Strecke als neue speichern",
      "CreateRecord": "Strecke speichern",
      "UpdateRecord": "Strecke speichern"
    },
    "CustomRoute": {
      "Dial": {
        "TitulekTabulky": "Gespeicherte Strecken",
        "Odkud": "Wovon",
        "Kam": "Wo",
        "ProfilVozidla": "Fahrzeugprofil",
        "ZadejteNazevTrasy": "Geben Sie den Namen der Strecke ein",
        "NelzePridatDoKilometrovniku": "Zum Meilenstein kann kein Ort ohne Breiten- und Längengrad hinzugefügt werden."
      },
      "FirstTab": "Routenberechnung",
      "Titulek": "Routenname",
      "Button": {
        "Vycistit": "Sauber",
        "Save": "Auferlegen",
        "SaveNew": "Neue Route speichern",
        "SaveRoute": "Strecke speichern",
        "CleanForm": "Formular löschen",
        "UlozitJakoNovouTrasu": "Als neue Strecke speichern"
      },
      "Validation": {
        "VyplnitAlesponDveMista": "In die Strecke müssen mindestens zwei Orte eingefügt werden"
      }
    },
    "TrasaDetail": {
      "CelkovaCenaZaSekci": "Gesamtpreis für den Abschnitt",
      "PoplatkyV": "Gebühren in",
      "Routing": "ROUTING",
      "Sekce": "Abschnitt",
      "OdhadovanaDobaJizdy": "Geschätzte Reisezeit",
      "Poplatky": "MAUT",
      "PoplatkyCelkem": "Gesamtgebühren",
      "DetailPoplatku": "Klicken Sie hier, um die Mautdetails ein-/auszuklappen",
      "DetailTrasy": "Zum Ein- oder Ausklappen der Routen-Details hier klicken",
      "Table": {
        "IndexMyta": "Mautindex",
        "IndexPoplatku": "Gebührenindex",
        "LokalniCena": "Lokaler Preis",
        "KovertovanaCena": "Umgerechneter Preis",
        "Zeme": "Land",
        "MytnySystem": "Mautsystem",
        "PlatebniMetody": "Zahlungsmethoden",
        "PlatebniMetoda": {
          "cash": "Bargeld",
          "bankCard": "Bankkarte",
          "creditCard": "Kreditkarte",
          "transponder": "Transponder",
          "travelCard": "Reisekarte",
          "passSubscription": "Fahrkartenabonnement",
          "videoToll": "Video-Maut"
        },
        "Predplatne": {
          "days": "Täglich",
          "months": "Monatlich",
          "annual": "Jährlich"
        }
      },
      "HereError": "Das Fahrzeugprofil enthält wahrscheinlich unzulässige Kombinationen.",
      "HereErrorNoRoutes": "Es wurde keine Route gefunden, die dem Standort und dem Profil des Fahrzeugs entspricht. Dies könnte z. B. daran liegen, dass ein Land gesperrt ist und eine Route in diesem Land beginnt oder endet.",
      "Upozorneni": "Warnung",
      "NotTruckTransportNotification": "Bitte beachten Sie: Auf der Strecke gibt es teilweise andere Verkehrsmittel (Fähre/Zug) als auf der Achse selbst. Die Tarife sind möglicherweise nicht vollständig.",
      "NotTollsTransportNotification": "Bitte beachten Sie, dass in diesem Abschnitt keine Gebühren aufgeführt sind. Das bedeutet nicht, dass es keine gibt. Es ist möglich, dass das System sie nicht erkennen konnte.",
      "ZobrazitMapu": "Klicken Sie hier, um die Karte ein-/auszuklappen",
      "KliknutimVybertePrujedniBod": "Klicken Sie auf die Karte, um einen Wegpunkt auszuwählen.",
      "VyberZMapy": "Auswahl aus der Karte"
    },
    "Kilometrovnik": {
      "Button": {
        "Trasa": "ROUTE",
        "Naklady": "MAUT",
        "TrasaANaklady": "ROUTE UND MAUT"
      },
      "ProfilVozidla": "Fahrzeugprofil",
      "ViaInput": "Ortsauswahl",
      "AddViaToList": "Zur Strecke hinzufügen",
      "Trasa": "ROUTE"
    },
    "Nazev": "Name des Fahrzeugprofils",
    "Currency": {
      "Title": "Kosten für die Rückgabe in Währung",
      "TitleToolTip": "Die Kosten der berechneten Route werden in dieser Währung erstattet",
      "Shortcut": {
        "EUR": "Euro",
        "GBP": "Britisches Pfund",
        "CHF": "Schweizer Franken",
        "NOK": "Norwegische Krone",
        "SEK": "Schwedische Krone",
        "DKK": "Dänische Krone",
        "ISK": "Isländische Krone",
        "CZK": "Tschechische Krone",
        "PLN": "Polnischer Zloty",
        "HUF": "Ungarischer Forint",
        "RON": "Rumänischer Leu",
        "BGN": "Bulgarischer Lew",
        "HRK": "Kroatische Kuna",
        "RSD": "Serbischer Dinar",
        "TRY": "Türkische Lira",
        "BAM": "Bosnisch-Herzegowinische Konvertible Mark",
        "MKD": "Mazedonischer Denar",
        "BYN": "Weißrussischer Rubel",
        "MDL": "Moldauischer Leu",
        "UAH": "Ukrainische Hrywnja",
        "RUB": "Russischer Rubel",
        "GEL": "Georgisches Lari",
        "AZN": "Aserbaidschanischer Manat",
        "AMD": "Armenischer Dram",
        "ALL": "Albanischer Lek"
      }
    },
    "Vehicle": {
      "Section": "Grundinformationen über das Fahrzeug",
      "PayloadCapacity": "Zulässige Nutzlast einschließlich Anhänger [t]",
      "Type": "Typ des Frachtfahrzeugs",
      "TypeEnum": {
        "straightTruck": "Direkter LKW",
        "tractor": "Traktor"
      },
      "TunnelCategory": "Kategorie Tunnel",
      "TunnelCategoryToolTip": "Tunnelkategorie zur Beschränkung des Transports spezifischer Güter",
      "TrailerAxleCount": "Gesamtzahl der Achsen (alle Anhänger)",
      "TrailerAxleCountToolTip": "Gesamtzahl der Achsen über alle an das Fahrzeug angehängten Anhänger",
      "AxleCount": "Gesamtzahl der Achsen im Fahrzeug",
      "TrailerCount": "Anzahl der Anhänger",
      "WeightPerAxleGroup": "Gewicht pro Achsgruppe [t]",
      "WeightPerAxle": "Gewicht pro Achse [t]",
      "GrossWeight": "Gesamtgewicht [t]",
      "Length": "Länge [m]",
      "Width": "Breite [m]",
      "Height": "Höhe [m]"
    },
    "TollEmission": {
      "Section": "Parameter für die Mautberechnung basierend auf Emissionen",
      "TollEmissionType": "Emissionstyp",
      "TollEmissionTypeCO2EmissionClass": "CO2-Emissionsklasse",
      "Type": {
        "euro1": "EURO1",
        "euro2": "EURO2",
        "euro3": "EURO3",
        "euro4": "EURO4",
        "euro5": "EURO5",
        "euro6": "EURO6",
        "euroEev": "EUROEEV"
      }
    },
    "HazardousGoods": {
      "Section": "Liste der gefährlichen Güter, die im Fahrzeug transportiert werden",
      "HazardousGood": {
        "explosive": "Explosiv",
        "gas": "Gas",
        "flammable": "Entflammbar",
        "combustible": "Brennbar",
        "organic": "Organisch",
        "poison": "Gift",
        "radioactive": "Radioaktiv",
        "corrosive": "Ätzend",
        "poisonousInhalation": "Giftig bei Einatmung",
        "harmfulToWater": "Wassergefährdend",
        "other": "Andere"
      }
    },
    "AvoidFeatures": {
      "Section": "Vermeiden von speifischen Wegen",
      "Feature": {
        "tunnel": "Tunnel",
        "ferry": "Fähre"
      }
    },
    "ExcludeCountry": {
      "Section": "Vermeidung folgender Länder",
      "Country": {
        "ALB": "Albanien",
        "AND": "Andorra",
        "ARM": "Armenien",
        "AUT": "Österreich",
        "BLR": "Belarus",
        "BEL": "Belgien",
        "BIH": "Bosnien und Herzegowina",
        "BGR": "Bulgarien",
        "HRV": "Kroatien",
        "CYP": "Zypern",
        "CZE": "Tschechische Republik",
        "DNK": "Dänemark",
        "EST": "Estland",
        "FIN": "Finnland",
        "FRA": "Frankreich",
        "GEO": "Georgien",
        "DEU": "Deutschland",
        "GRC": "Griechenland",
        "HUN": "Ungarn",
        "ISL": "Island",
        "IRL": "Irland",
        "ITA": "Italien",
        "XKX": "Kosovo",
        "LVA": "Lettland",
        "LIE": "Liechtenstein",
        "LTU": "Litauen",
        "LUX": "Luxemburg",
        "MLT": "Malta",
        "MDA": "Moldawien",
        "MCO": "Monaco",
        "MNE": "Montenegro",
        "NLD": "Niederlande",
        "MKD": "Nordmazedonien",
        "NOR": "Norwegen",
        "POL": "Polen",
        "PRT": "Portugal",
        "ROU": "Rumänien",
        "RUS": "Russland",
        "SMR": "San Marino",
        "SRB": "Serbien",
        "SVK": "Slowakei",
        "SVN": "Slowenien",
        "ESP": "Spanien",
        "SWE": "Schweden",
        "CHE": "Schweiz",
        "TUR": "Türkei",
        "UKR": "Ukraine",
        "GBR": "Vereinigtes Königreich",
        "VAT": "Vatikanstadt"
      }
    },
    "DefaultPVSettings": {
      "Section": "Einstellungen bei Transporten und freien Fahrzeugen",
      "Preprava": "Standard bei Transporten",
      "Vozidlo": "Standard bei freien Fahrzeugen",
      "Kilometrovnik": "Standard bei Plätze"
    },
    "CustomNaklady": {
      "NaHodinu": "Kosten pro Stunde",
      "NakladNaKm": "Kosten pro km",
      "CelkemZaHodin": "Streckenkosten (Entfernung * Stundensatz)",
      "CelkemZaKm": "Kosten der Route (Entfernung * Preis pro km)",
      "Celkem": "Gesamtkosten der Route",
      "Titulek": "BENUTZERDEFINIERTE KOSTEN"
    }
  }
};
export default de;