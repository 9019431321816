import React, {
    CSSProperties,
    MutableRefObject,
    ReactElement,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import {FormInput} from "./FormInput";
import {FormTextarea} from "./FormTextarea";
import {SelectOptionType, SelectProps} from "./FormSelect";
import {FormCheckbox} from "./FormCheckbox";
import {CustomDateTimePickerOptions, FormDatetime} from "./FormDatetime";
import {FormInputType} from "../../../web/raal_components/form/Form";
import {CustomFieldComponent, DataHolder, FormFieldInterfaceProps} from "./FormFieldInterface";
import {FormMuiAutocomplete} from "./FormMuiAutocomplete";
import {TextFieldProps} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {CustomDateTimePickerLocalization} from "../CustomDateTimePicker";
import {FormNumber, NumberProps} from "./FormNumber";
import {FieldError} from "./ValidationError";
import {useDidMount} from "../hooks/SharedHooks";
import {exist} from "../../utils/Util";
import {invoke} from "../../utils/Invoke";

export const StandaloneFieldDataContext = React.createContext({});

export type StandaloneFieldProps<CustomOptions = any> = {
    disabled?:boolean,
    placeholder?:string,
    variant?:'outlined'|'filled'|'standard'
    title?:ReactNode,
    value?:any,
    type:FormInputType|string,
    dataTip?:any,
    focused?:boolean,
    selectProps?: SelectProps,
    textFieldProps?:TextFieldProps,
    numberProps?:NumberProps,
    customComponent?: CustomFieldComponent | any,
    customComponentOptions?: CustomOptions,
    dateTimeOptions?: CustomDateTimePickerOptions,
    onKeyDown?:(e:React.KeyboardEvent<HTMLInputElement>)=>void,
    onKeyUp?:(e:React.KeyboardEvent<HTMLInputElement>)=>void,
    showIcon?:boolean,
    style?: CSSProperties,
    error?:string,
    onBlur?:(o:StandaloneFieldExposed)=>void,
    onFocus?:(o:StandaloneFieldExposed, userFocus:boolean, index?:number)=>void,
    showAdornment?:boolean,
    onError?:(e:string[], o:StandaloneFieldExposed)=>void
    autofillValue?:boolean,
    inputClassName?: string,
    onValueChanged?(value:any):void
    onMount?:(o:StandaloneFieldExposed)=>void,
    onDismount?:(o:StandaloneFieldExposed)=>void,
    allErrors?:FieldError[],
    onInteractStart?:()=>void,
    onInteractEnd?:()=>void,
    onDisableActionKeys?:(isDisabled: boolean)=>void,
    autoSelectFirstValueOnTab?: boolean,
    getOptionDisabled?:(option: SelectOptionType) => boolean,
    useDynamicFontSize?:boolean,
    isPrejezd?:boolean
    isDokladka?:boolean
    isFilterRow?:boolean,
    isFilterOdkud?:boolean,
    isFilterKam?:boolean,
    updateIndexes?: () => void
    /**
     * Pouzije custom Popper element s optimalizovanim sirky obsahu v dropdowne
     */
    useCustomPopperElement?: boolean
};
const getRenderFormNumber = (props:StandaloneFieldProps, defaultProps:FormFieldInterfaceProps<any>):[ReactElement] => {
    return [<FormNumber {...defaultProps} textFieldProps={{variant:props.variant || "standard", ...props.textFieldProps}} numberProps={props.numberProps}/>];
};
const getRenderFormInput = (props:StandaloneFieldProps, defaultProps:FormFieldInterfaceProps<any>):[ReactElement] => {
    return [<FormInput type={props.type}  {...defaultProps} textFieldProps={{variant:props.variant || "standard", ...props.textFieldProps}} />];
};

const getRenderFormTextarea = (props:StandaloneFieldProps, defaultProps:FormFieldInterfaceProps<any>):[ReactElement] => {
    return [<FormTextarea  {...defaultProps}  />];
};

const getRenderFormCheckbox = (props:StandaloneFieldProps, defaultProps:FormFieldInterfaceProps<any>):[ReactElement] => {
    return [<FormCheckbox  {...defaultProps} size={"small"} />];
};

const getRenderDatetime = (props:StandaloneFieldProps, defaultProps:FormFieldInterfaceProps<any>, localization:CustomDateTimePickerLocalization):[ReactElement] => {
    return [<FormDatetime  {...defaultProps} dateTimeOptions={props.dateTimeOptions} localization={localization} />];
};
const getRenderAutocomplete = (props:StandaloneFieldProps, defaultProps:FormFieldInterfaceProps<any>):[ReactElement] => {
    return [<FormMuiAutocomplete  {...defaultProps} selectProps={props.selectProps} autoSelectFirstValueOnTab={props.autoSelectFirstValueOnTab} useCustomPopperElement={props.useCustomPopperElement} />];
};
const getRenderCustomField = (props:StandaloneFieldProps, defaultProps:FormFieldInterfaceProps<any>, holder:MutableRefObject<DataHolder>):[ReactElement] => {
    if(props.customComponent) {
        return [<props.customComponent {...defaultProps} updateIndexes={props.updateIndexes} options={props.customComponentOptions} dataHolder={holder}/>];
    } else {
        return [<div>Custom component must have set prop customComponent</div>];
    }
};


export type StandaloneFieldExposed = {
    setFocus(status:boolean, index?:number):void;
    getData():DataHolder;
}


export function StandaloneField<CustomOptions = any>(props:StandaloneFieldProps<CustomOptions>) {
    const {t} = useTranslation();
    const dataHolder = useRef<DataHolder>({supportTabFocus:0, currentTabFocused:1, hasError:false});
    const [focused, setFocused] = useState({status:props.focused, index:undefined});

    const instance = useRef<StandaloneFieldExposed>({
        setFocus: (status, index?:number) => setFocused({status, index}),
        getData: () => dataHolder?.current
    });
    useEffect(()=>{
        setFocused({status:props.focused, index: undefined});
    }, [props.focused]);

    dataHolder.current.disabled = props.disabled || false;
    dataHolder.current.focused = focused.status || false;
    dataHolder.current.hasError = exist(props.error);

    const enableFocusSupport = useCallback((countAs:number = 1, currentFocused:number=0)=>{
        dataHolder.current.supportTabFocus = countAs;
        dataHolder.current.currentTabFocused = currentFocused;
    }, [dataHolder]);
    const {onFocus:onFocusProps} = props;
    const onFocus = useCallback((userfocus,index)=> onFocusProps&&onFocusProps(instance.current, userfocus, index), [onFocusProps]);
    const onError = useCallback((e:string[]) => {
        (props.onError ?? (()=>{}))(e.filter(s=>s&&s.trim().length > 0), instance.current)
    }, [props.onError]);
    const {onBlur:onBlurProps} = props;
    const onBlur = useCallback(() => onBlurProps&&onBlurProps(instance.current), [onBlurProps]);
    const onDisableActionKeys = useCallback((isDisabled) => invoke(props.onDisableActionKeys, isDisabled), [props.onDisableActionKeys]);
    const createDefaultComponentProps = () => {
        return {
            ...props,
            name:"",
            title:props.title || "",
            enableFocusSupport:enableFocusSupport,
            currentFocused:dataHolder.current.currentTabFocused,
            focused:focused.status,
            onBlur:onBlur,
            onFocus:onFocus,
            focusedIndex:focused.index,
            onError:onError,
            onDisableActionKeys: onDisableActionKeys,
            inputClassName: props.inputClassName
        } as FormFieldInterfaceProps<any>;
    };

    const renderComponent = ():[ReactElement] => {
        const defaultProps = createDefaultComponentProps();
        if (props.type === FormInputType.Text || props.type === FormInputType.Password) {
            return getRenderFormInput(props, defaultProps);
        } else if (props.type === FormInputType.Select) {
            return getRenderAutocomplete(props, defaultProps);
        } else if (props.type === FormInputType.TextArea) {
            return getRenderFormTextarea(props, defaultProps);
        } else if (props.type === FormInputType.Checkbox) {
            return getRenderFormCheckbox(props, defaultProps);
        } else if (props.type === FormInputType.Custom) {
            return getRenderCustomField(props, defaultProps, dataHolder);
        } else if (props.type === FormInputType.DateTime) {
            return getRenderDatetime(props, defaultProps, t("FormLocalization.DateTimePicker", {returnObjects:true}));
        } else if (props.type === FormInputType.AutoComplete) {
            return getRenderAutocomplete(props, defaultProps);
        } else if(props.type === FormInputType.Number) {
            return getRenderFormNumber(props, defaultProps);
        }
        return [<div>{`Wrong input type ${props.type}`}</div>];
    };
    useDidMount(()=>{
        props.onMount&&props.onMount(instance.current);
        return () => {
            props.onDismount&&props.onDismount(instance.current);
        };
    });
    return renderComponent()[0];

}
