import * as React from "react";
import {FormEvent, PropsWithChildren, useContext, useRef} from "react";
import {Loading, LoadingExposed} from "../../../common/component/Loading";
import {httpEndpointCustom} from "../../../common/utils/HttpUtils";
import {clamp, deepEqual, distinctArray, exist, jsonToFormData, jsonToFormUrlEncoded} from "../../../common/utils/Util";
import _ from 'lodash';
import {Mapper} from "../../../common/utils/objectmapper/Mapper";
import {ValidationError} from "../../../common/component/form/ValidationError";
import {showSnack} from "../../../common/component/SnackContainer";
import {GenericMap} from "../../../index.d";
import {FormHttpResponse, FormProps, FormState, FormStatus} from "./Form.d";
import {invoke} from "../../../common/utils/Invoke";
import {FormTabsComponent, FormTabsProps, useStyles} from "../../../common/component/FormTabs";
import {FormFieldComponent, FormFieldProps, HiddenFormFieldProps} from "./FormField";
import {FormButtonClickEvent, FormButtonComponent, FormButtonProps} from "./FormButton";
import i18next from "i18next";

/********************
 *
 * FORM
 *
 ********************/
export enum FormInputType {
    Text = "text",
    Password = "password",
    Email = "email",
    Editor = "editor",
    File = "file",
    AutoComplete = "autocomplete",
    Select = "select",
    TextArea = "textarea",
    DateTime = "datetime",
    Number = "number",
    Checkbox = "checkbox",
    Custom = "Custom",
    CustomWithRef = "CustomWithRef",
    PhoneNumber = "PhoneNumber",
    Color = "Color"
}

export const Context = React.createContext({} as FormContextState<any>);

export type FormDialog = {
    body?: string,
    title?: string,
    buttons?: {
        confirm?:  string
    }
}

/**Exported form components**/
export function FormButton<CustomOptions = any>(props:PropsWithChildren<FormButtonProps<CustomOptions>>) {
    const {getForm} = useContext(Context);
    return <FormButtonComponent {...props} getForm={getForm} children={props.children} />;
}

export function FormField<CustomOptions = any, Value=any>(props:FormFieldProps<CustomOptions, Value>) {
    const {getForm} = useContext(Context);
    return <FormFieldComponent<CustomOptions, Value> {...props} getForm={getForm}/>;
}

export function FormTabs<CustomOptions = any>(props:PropsWithChildren<FormTabsProps<CustomOptions>>) {
    const {getForm} = useContext(Context);
    const classes = useStyles();
    return <FormTabsComponent {...props} getForm={getForm} className={classes.invertTabs} />;
}
/**----**/

type FormContextState<Data> = {
    getForm():FormComponent<Data>
}

const voidFormSubmit = (e: FormEvent) => e.preventDefault();

function deepCopy<T>(mapper:Mapper<T>, d:T) {
    return mapper.readValue(mapper.writeValueAsJson(d, {springSupport:false}));
}

export function Form<Data>(props:PropsWithChildren<FormProps<Data>>) {

    const ref = useRef<FormComponent<Data>>();
    return (
        <Context.Provider value={{getForm: ()=> ref.current}}>
            <FormComponent {...props} onConstruct={form => ref.current = form} children={props.children} />
        </Context.Provider>
    );
}

export function useForm<D>():FormComponent<D> {
    const {getForm} = useContext(Context);
    return getForm();
}
export function useFormSafe<D>():FormComponent<D> {
	const {getForm} = useContext(Context);
	if (getForm) {
		return getForm();
	} else {
		return null;
	}
}
export function useFormData<D>():D {
    const {getForm} = useContext(Context);
    return getForm().data;
}

export function useFormIsDefaultVicinityAddress(): boolean | undefined {
    const { getForm } = useContext(Context);
    return getForm()?.props.isDefaultFormVicinityAddress;
}

/**
 * Slouzi ke zpracovavani formularovych dat.
 *
 * Umi odesilat data na server,
 * validovat fieldy na FE i BE,
 * field focus,
 * dirty check,
 * handlovat chyby ze serveru
 */

export class FormComponent<Data> extends React.Component<FormProps<Data>&{onConstruct:(form:FormComponent<Data>)=>void}, FormState> {
    instance: any;
    loading = React.createRef<LoadingExposed>();
    hasFile: boolean = false;
    tabs: Array<FormTabsComponent> = [];
    fields: Array<FormFieldComponent> = [];
    buttons: Array<FormButtonComponent<Data>> = [];
    fieldCount:number = 0;
    state:FormState  = {errors:new ValidationError(), onKeyDisabled: false};
    isActionKeysDisabled = false;
    static defaultProps = {
        blockSaveUntilChange:false,
        method: "post",
        simpleLabel: false,
        inputGroupEnabled: true,
        isEndpoint: false,
        focusFieldWhenMounted:false
    };
    data: Data = this.props.data;
    mapper = new Mapper<Data>({constructor:this.data.constructor as {new():Data}});
    originData: Data = this.props.origin ? deepCopy(this.mapper, this.props.origin) : deepCopy(this.mapper, this.props.data);
    _ = this.props.onConstruct(this);

    //slouzi pro exclude fieldu pri blockSaveUntilChange
    __bsuc_excludeFields:string[] = this.props.excludeFieldsForDirtyCheck ?? [];
    excludeFieldsForIntegerCheck: string[] = this.props.excludeFieldsForIntegerCheck ?? [];
    excludeFieldsForTimeCheck: string[] = this.props.excludeFieldsForTimeCheck ?? [];
    processing: boolean = false;
    nextTabIndex = 0;
    nextTabButtonIndex = 0;
    currentFocusedField?: FormFieldComponent = null
    isChildDataGridFocused?: boolean
    isChildFormFocused?: boolean

    // @ts-ignore
    isCreate: boolean = !exist(this.data?.id) && !this.props.filterForm && !this.props.childForm;

    hiddenFieldProps = (props:FormFieldProps) : HiddenFormFieldProps => {
        return {
            autofillValue:this.props.autofillValues,
            blockSaveUntilChange: this.props.blockSaveUntilChange,
            fieldErrors: this.state.errors.errors.filter(i => i.name.startsWith(props.name) || (exist(props.errorKey) && i.name.startsWith(props.errorKey)) || (props.additionalServerErrorKeys && props.additionalServerErrorKeys.indexOf(i.name)>=0)),
            simpleLabel: this.props.simpleLabel,
            inputGroupEnabled: this.props.inputGroupEnabled,
            // @ts-ignore
            defaultValue: props.getValue ? props.getValue(this.data, props.name) : _.get(this.data, props.name),
            onValueChanged: this.onValueChanged.bind(this),
            localization: this.props.localization.FieldMessages,
            dateTimePickerLocalization:this.props.localization.DateTimePicker,
            formFieldOverrides:this.props.formFieldOverrides?.find(f=>f.name===props.name),
            onFocus:(field, userfocus) => {
                this.currentFocusedField = field;
                if(userfocus) this.focusField(field);
                if (!field.props?.isContainer) this.resetContainerFocus();
            },
        };
    };
    componentDidMount(): void {
        if(this.props.validateOnLoad) {
            this.validate();
        }

        this.testBlockSaveUntilChange();
        if(this.props.focusFieldWhenMounted || this.isCreate) {
            let field: FormFieldComponent = null;

            if (this.props.focusedFieldWhenMounted ) {
                const sp = this.supportedFields();
                field = sp?.find(f => f.props.name === this.props.focusedFieldWhenMounted)
                this.currentFocusedField = field;
            }
            setTimeout(() => this.focusField(field, null, null, field !== null), 1)
        }

        this.setProcessing = this.setProcessing.bind(this);
        this.validate = this.validate.bind(this);

        if (this.props.isReadOnly || this.props.disabled) {
            setTimeout(() => this.focusButton(), 1);
        }
        if (this.isCreate)
            setTimeout(() => this.testBlockSaveUntilChange(), 1000);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<FormProps<Data>>, nextContext: any): void {
        this.data = nextProps.data;
        this.fieldCount = 0;
        if(nextProps.updateOriginToo || nextProps.preventCloseAfterSave) {
            this.originData = nextProps.origin ?  deepCopy(this.mapper, nextProps.origin) : deepCopy(this.mapper, this.props.data);
        }
        //this is dangerous use this function carefully
        nextProps.onPropsChanged&&nextProps.onPropsChanged(this);
        if(nextProps.validateOnLoad) {
            this.validate();
        }
        this.testBlockSaveUntilChange();
        this.fields.forEach(f=>{f.updateProps(); f.forceUpdate()});
    }

    componentDidUpdate(prevProps: Readonly<FormProps<Data>>, prevState: Readonly<FormState>, snapshot?: any): void {

    }

    testBlockSaveUntilChange() {
        this.buttons.forEach(button=>button.setDisabled(false));
        if(this.props.blockSaveUntilChange) {
            const isEqual = deepEqual(this.mapper.writeValueAsJson(this.originData, {springSupport:false}), this.mapper.writeValueAsJson(this.data, {springSupport:false}), [...this.__bsuc_excludeFields], [...this.excludeFieldsForIntegerCheck], [...this.excludeFieldsForTimeCheck]);
            invoke(this.props.onDataChanged, this, isEqual);
            this.buttons.filter(b=>!b.props.skipBlock).forEach(button=>button.setDisabled(isEqual));
        }
    }

    validate(preventFocusError?: boolean):boolean {
        this.resetContainerFocus();
        let errors = new ValidationError();
        let fe = this.props.validate && this.props.validate(this.data);
        if(fe) {
            errors.errors = errors.errors.concat(fe);
        }
        let firstError: FormFieldComponent = null
        this.fields.forEach(field => {
            const fieldErrors = field.validate();
            if(fieldErrors !== null) {
                if (!preventFocusError && fieldErrors.length !== 0 && !firstError) {
                    firstError = field
                }
                errors.errors = errors.errors.concat(fieldErrors);
            }
        });
        errors.errors =  distinctArray(errors.errors, item => item.name);
        this.setState({errors:errors}, ()=>{
            this.fields.forEach(f=>{f.updateProps(); f.forceUpdate()});
            firstError && this.focusError(firstError);
        });
        return errors.errors.length === 0;
    }

    resetContainerFocus() {
        const containers = this.supportedFields().filter(f=> f.props.isContainer)
        containers.forEach(f =>f.resetFocus());
    }

    resetFormFocus() {
        const supportedFields = this.supportedFields();
        supportedFields.filter(f=>f.state.focused).forEach(f=>f.setState({focused:false}));
        this.currentFocusedField = null;
        this.nextTabIndex = 0;
        this.nextTabButtonIndex = 0;
    }

    onDisableActionKeys(isDisabled: boolean, focusNext?: boolean) {
        this.isActionKeysDisabled = isDisabled;
        !isDisabled && focusNext && setTimeout(() => this.focusField(null), 1);
    }

    onFocusNextField(focusFirstOrButtons?: boolean) {
        if (focusFirstOrButtons) {
            const saveEnabled = this.buttons && this.buttons.some(b => !b.state?.disabled);
            if (saveEnabled) {
                const sf = this.supportedFields();
                this.currentFocusedField = sf?.length !== 0 ? sf[sf.length -1] : null;
                this.focusButton();
            } else {
                this.focusFirstField();
            }
        } else {
            setTimeout(() =>this.focusField(null), 1);
        }
    }

    onFocusPrevField = (index: number = 0) => {
        if (index < 0) return;
        const sf = this.supportedFields();
        setTimeout(() => this.focusField(sf[index], null, null, true), 1);
    }

    setProcessing(status: boolean) {
        this.processing = status;
        if (this.props.showLoading) {
            this.loading.current.setShow(status);
        }
        this.buttons.forEach(o => o.setStatus(status));

    }

    async send(event: FormButtonClickEvent) {
        if (this.processing) {
            return;
        }

        this.props.beforeSend&&this.props.beforeSend(this.data);

        if (event.skipValidation) {
            this.sendInternal(event).then();
        }else {
            this.setState({errors: new ValidationError()}, () => {
                if (exist(this.props.handlers?.handleSend)) {
                    this.props.handlers.handleSend(() => this.sendInternal(event), this.setProcessing, this.data);
                } else if (this.props.saveAndNextButton) {
                    this.sendInternal(event).then((test) => {
                        if (test)
                            setTimeout(() => this.focusFirstField(), 500)
                    });
                } else {
                    this.sendInternal(event).then();
                }
            });
        }
    }

    async sendInternal(event: FormButtonClickEvent) : Promise<void | boolean> {
        let success = false
        try {
            if(!event.skipValidation && !this.validate()) {
                return;
            }
            this.setProcessing(true);
            let result: FormStatus | FormHttpResponse<Data>;
            if (this.props.onSend) {
                result = await this.props.onSend(this, event);
            } else {
                result = await this.sendForm(event);
            }

            let status: FormStatus;
            let response: FormHttpResponse<Data>;

            if (result instanceof FormHttpResponse) {
                response = result as FormHttpResponse<Data>;
                status = response.status;
            } else {
                status = result as FormStatus;
                response = new FormHttpResponse<Data>();
                response.status = status;
            }
            switch (status) {
                case FormStatus.Error:
                    this.showError(response);
                    break;
                case FormStatus.Validation:
                    this.showValidation(response);
                    break;
                case FormStatus.Success:
                    if (event.type === 'remove') {
                        this.showRemoved(response);
                    } else {
                        this.showSuccess(response);
                    }
                    success = true
                    break;
            }
            if (status !== FormStatus.Skip) {
                this.setProcessing(false);
            }

            this.props.onResult && this.props.onResult(response, event);
        } catch {
            this.setProcessing(false);
        }

        return success;
    }

    async sendForm(event: FormButtonClickEvent): Promise<FormHttpResponse<Data>> {
        var url = event.modifyUrl ? event.modifyUrl(this.props.url) : this.props.url;
        let clonedData = Boolean(this.props.createParameters) ? this.props.createParameters(this.data) : this.data;
        const json = this.mapper.writeValueAsJson(clonedData);
        //kod nefunguje dobre je treba to obohatit, kdyz je treba v entite mapa polozek nebo kdyz je treba dynamicke pole atd....
        // const json:Keyed = {};
        // this.fields.forEach(f=>{
        //     if(!f.props.disabled) {
        //         json[f.props.name] = fullJson[f.props.name];
        //     }
        // });

        let method = "POST";
        let headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'};
        if(this.props.rest) {
            method = url.includes("new") ? "POST" : "PUT";
        }
        let convertMethod:(json:GenericMap, userOptions?:{})=>any = jsonToFormUrlEncoded;
        if(this.hasFile){
            convertMethod = jsonToFormData;
            delete headers['Content-Type'];
            if(method!=="POST") {
                method = "POST";
                url = url + "/m"
            }
        }

        const init = _.merge({method: method, headers:headers, body: convertMethod(json)}, _.merge(event.requestInit, this.props.requestInit));
        const result = await httpEndpointCustom(url, init);
        const response = new FormHttpResponse<Data>();
        response.status = FormStatus.Nothing;

        if((result || null) !== null) {
            response.response = result.response;
            if (result.response.status >= 200 && result.response.status < 300) {
                response.status = FormStatus.Success;
                if(this.props.saveResponseSetter) {
                    this.props.saveResponseSetter(response, this.mapper, result.json);
                    response.data = this.mapper.readValue(result.json);
                }
                else {
                    response.data = this.mapper.readValue(result.json);
                }
            } else if (result.response.status === 422) {
                response.status = FormStatus.Validation;
                response.validationError = new Mapper<ValidationError>({constructor:ValidationError}).readValue(result.json);
            } else {
                response.status = FormStatus.Error;
            }
        }
        return response;

    }
    // Validace z BE
    showValidation(response: FormHttpResponse<Data>) {
        this.fields.forEach(f => {
            response?.validationError.errors.forEach(ve => {
                if(ve.name.startsWith(f.props.name))
                    ve.formField = f;
            })
        });
        const messageList = distinctArray(response?.validationError?.errors?.filter(e => e.formField)?.map(e => `${e.formField?.props?.title}: ${e.getErrorMessage()}`).filter(i => exist(i)), item => item);
        if(messageList.length===0) {
            const message = response?.validationError?.getOneErrorMessage();
            if(message) {
                messageList.push(message);
            }
        }
        const errorMessages = (messageList.length >3 ? [...messageList.slice(0, 3), "..."] : messageList).map((em : string )=> (i18next.exists(em) ? i18next.t(em) : em)).join("\n");
        this.setState({errors: response.validationError}, () => {
            if(!this.props.suppressToastMessage)
                showSnack({severity:"warning", title:`${this.props.localization.ValidationError} \n ${errorMessages ?? ''}`, duration: 4000});
            this.fields.forEach(f=>{f.updateProps(); f.forceUpdate()});
            // Zjistit chyby z BE
            const errorsFields = this.fields.filter(f => f.hiddenProps.fieldErrors.length !== 0);
            errorsFields.length !== 0 && this.focusError(errorsFields[0]);
        })
    }

    showError(_: FormHttpResponse<Data>) {
        if(!this.props.suppressToastMessage)
            showSnack({severity:"error", title:this.props.localization.ServerError});
    }

    showSuccess(_: FormHttpResponse<Data>) {
        if(!this.props.suppressToastMessage)
            showSnack({severity:"success", title:this.props.localization.DataSaved});
    }

    showRemoved(_: FormHttpResponse<Data>) {
        if(!this.props.suppressToastMessage)
            showSnack({severity: "success", title: this.props.localization.DataRemoved });
    }

    onRefreshFieldValue(fieldName: string) {
        const field = this.fields.find(f => f.props.name === fieldName);

        if (!field) return;

        field.updateProps();
        field.forceUpdate();

        this.onValueChanged(field, true);
        setTimeout(() => this.focusField(field, null, null, true), 10);
    }

    onValueChanged(field: FormFieldComponent, skipSetValue?: boolean) {
        if (!skipSetValue) {
            if (field.props.setValue) {
                field.props.setValue(this.data, field.props.name, field.value);
            } else {

                // @ts-ignore
                _.set(this.data, field.props.name, field.value);
            }
        }

        const isEqual = deepEqual(this.mapper.writeValueAsJson(this.originData, {springSupport:false}), this.mapper.writeValueAsJson(this.data, {springSupport:false}), [...this.__bsuc_excludeFields], [...this.excludeFieldsForIntegerCheck], [...this.excludeFieldsForTimeCheck]);
        this.props.onChange && this.props.onChange(this, isEqual);
        if(field.props.forceUpdateWhenDataChanged) {
            let updateFields = this.fields;
            if(field.props.forceUpdateWhenDataChanged.length > 0) {
                updateFields = this.fields.filter(f=>field.props.forceUpdateWhenDataChanged.includes(f.props.name));
            }
            updateFields.filter(f=>f!==field).forEach(f=>{
                f.updateProps();
                f.forceUpdate();
            });
        }

        if (this.state.errors?.errors && this.state.errors?.errors?.length !== 0) {
            setTimeout(() => this.validate(true), 0);
        }
    }

    focusFirstField() {
        const supportedFields = this.supportedFields();
        this.nextTabIndex = 0;
        this.nextTabButtonIndex = 0;
        supportedFields.filter(f=>f.state.focused).forEach(f=>f.setState({focused:false}));
        setTimeout(() => supportedFields[this.nextTabIndex]?.setState({focused: true}),  1);
    }

	setOnKeyDisabled(disabled: boolean) {
		this.setState({onKeyDisabled: disabled});
	}

    onKey = (e:KeyboardEvent) => {
		if (this.state.onKeyDisabled) {
			return;
		}
        if (this.props.isReadOnly && e.key !== ' ' && !this.isChildFormFocused) {
            e.preventDefault();
            return;
        }
        if (e.shiftKey && e.key === "+") {
            if (this.currentFocusedField?.addNew) {
                e.preventDefault();
                this.currentFocusedField?.addNew();
                return;
            }
        }

        if (e.shiftKey && e.key === "Delete") {
            if (this.currentFocusedField?.deleteItem) {
                e.preventDefault();
                this.currentFocusedField?.deleteItem();
                return;
            }
        }

        // @ts-ignore
        if (!this.isActionKeysDisabled && e.shiftKey && (e.key === "Tab" || e.key === "Enter")) {
            e.preventDefault();
            if (this.isChildDataGridFocused || this.isChildFormFocused) return;
            if (this.props.stopImmediatePropagation) e.stopImmediatePropagation();
            this.backwardFocus(this.currentFocusedField?.props?.isContainer && this.currentFocusedField?.value?.length !== 0);
        }
        else {
            if (!this.props.filterForm &&
                (!this.currentFocusedField?.props.autoSelectFirstValueOnTab || this.nextTabButtonIndex !== 0) &&
                // @ts-ignore
                (e.key === "Tab" || (!this.isActionKeysDisabled && e.key === "Enter" && e.target.type && e.target.type !== "textarea"))) {
                e.preventDefault();
                if (this.isChildDataGridFocused || this.isChildFormFocused || (this.currentFocusedField?.props.preventFocusNext && this.currentFocusedField?.state.focused)) return;
                if (this.props.stopImmediatePropagation) e.stopImmediatePropagation();
                this.forwardFocus();
            }
            // @ts-ignore
            if (this.props.filterForm && (!this.isActionKeysDisabled && e.key === "Enter" && e.target.type && e.target.type !== "textarea")) {
                setTimeout(() => invoke(this.props.onFilterButtonClick), 100);
            }
            // Close Filter with Escape
            if (this.props.filterForm && (!this.isActionKeysDisabled && e.key === "Escape")) invoke(this.props.onFilterEscapePressed);
        }
    };

    forwardFocus = () => {
        if ((this.currentFocusedField && this.currentFocusedField?.props?.isContainer && this.currentFocusedField.isLastContainerField && this.currentFocusedField?.props?.isLastInTab) ||
            (this.currentFocusedField && !this.currentFocusedField?.props?.isContainer && this.currentFocusedField?.props?.isLastInTab)) {
            this.tabs[0].switchTab();
        }
        const supportedFields = this.supportedFields();
        this.switchTabToRigth(supportedFields);
        const saveEnabled = this.buttons && this.buttons.some(b => !b.state?.disabled);
        let focusButtons = false;
        if (saveEnabled && supportedFields.indexOf(this.currentFocusedField) === (supportedFields.length - 1)) {
            if (!this.currentFocusedField?.props?.isContainer) focusButtons = true;
            if (this.currentFocusedField?.props?.isContainer && this.currentFocusedField.isLastContainerField) focusButtons = true;
        }
        if (focusButtons) {
            if (!this.isActionKeysDisabled) this.focusButton();
        } else {
            if (!this.isActionKeysDisabled) {
                setTimeout(() => this.focusField(), 1);
            }
            this.nextTabButtonIndex = 0;
        }
    }

    switchTabToRigth(supportedFields: FormFieldComponent[]){
        const nextFieldIndex = supportedFields.indexOf(this.currentFocusedField) + 1;
        if (supportedFields.length > nextFieldIndex && supportedFields[nextFieldIndex].props.isFirstInTab === 0)
        {
            this.tabs[0].switchTab(true, false);
        }
    }

    backwardFocus = (isContainer?: boolean) => {
        if (this.currentFocusedField?.props?.isFirstInTab) this.tabs[0].switchTab(false,true);
        if (this.nextTabButtonIndex === 0) {
            const supportedFields = this.supportedFields();
            const index = supportedFields.indexOf(this.currentFocusedField) - 1;
            if (index > -1 && supportedFields.length > index && supportedFields[index].props?.type === "checkbox") {
                this.focusField(null, 2, isContainer);
            } else {
                setTimeout(() => this.focusField(null, 2, isContainer), 1);
            }
        }
        else {
            const index = this.buttons?.length !== 0 ? this.buttons.findIndex(b => !b.props?.disabled) : 0;
            if (this.nextTabButtonIndex === index + 1) {
                setTimeout(() => this.focusField(null, 1), 1);
                this.nextTabButtonIndex = 0;
            }
            else {
                this.nextTabButtonIndex = this.nextTabButtonIndex - 2;
                this.focusButton();
            }
        }
    }

    focusButton = () => {
        if (!this.props.disableFocusForSaveButton && this.buttons.length > this.nextTabButtonIndex) {
            this.resetContainerFocus();
            if (!this.buttons[this.nextTabButtonIndex].props?.disabled) {
                this.buttons[this.nextTabButtonIndex].setFocus();
                this.nextTabButtonIndex++;
            } else {
                const index = this.buttons.findIndex(b => !b.props?.disabled);
                if (index !== -1) {
                    this.buttons[index].setFocus();
                    this.nextTabButtonIndex = index + 1;
                }
            }
        }
        else {
            const saveEnabled = this.buttons && this.buttons.some(b => !b.state?.disabled && (b.props.skipBlock !== true || b.props.forceTabClick));
            if (saveEnabled) {
                this.onSaveButtonClick();
            } else {
                if (!this.isActionKeysDisabled)
                    setTimeout(() => this.focusField(null), 1);
                this.nextTabButtonIndex = 0;
            }
        }



    };

    onSaveButtonClick = () => {
        if(this.buttons.length > 0) {
            if(this.buttons.length === 1) {
                this.buttons[0].doClick();
            } else {
                const mainButtons = this.buttons.filter(b=>b.props.main);
                if(mainButtons.length > 0) {
                    mainButtons[0].doClick();
                }
            }

        }
    }

    supportedFields = () => {
        return this.fields.filter(i => (i.supportTabFocus > 0 &&
            (typeof (i.props.disabled) === 'function' ? !i.props.disabled() : !i.props.disabled) &&
            !i.props.noAutofocus && !i.hiddenProps.formFieldOverrides?.disabled && !i.hiddenProps.formFieldOverrides?.invisible) && !i.props.isFormGrid);
    }

    focusError = (field?:FormFieldComponent) => {
        const supportedFields = this.supportedFields();
        const index = supportedFields.indexOf(field);
        this.nextTabIndex = clamp(index, 0, supportedFields.length);
        supportedFields.filter(f=>f.state.focused).forEach(f=>f.setState({focused:false}));
        setTimeout(() => supportedFields[this.nextTabIndex]?.setState({focused: true}),  1);
    }

    focusField = (field?:FormFieldComponent, goBack?: number, isContainer?: boolean, setFocus?: boolean) => {
        if(this.props.disabled) {
            return;
        }
        this.nextTabButtonIndex = 0;

        let start = Date.now();
        const supportedFields = this.supportedFields();
        const resolveNextTabIndex = (f:FormFieldComponent) => {
            this.nextTabIndex = supportedFields.indexOf(f);
            if(f.supportTabFocus === 1 || f.currentTabFocused === f.supportTabFocus) {
                this.nextTabIndex = this.nextTabIndex + 1;
            }
        };
        if(field) {
            resolveNextTabIndex(field);
            supportedFields.filter(f=>f.state.focused).forEach(f=>f.setState({focused:false}));
            if (setFocus)
                supportedFields.find(f => f.props.name === field.props.name)?.setState({focused:true});
        } else {
            supportedFields.forEach((f)=>{
                if(f.state.focused) {
                    resolveNextTabIndex(f);
                    f.setState({focused:false});
                }
            });
            // Go to previous field
            if (goBack) {
                if (!isContainer) {
                    this.nextTabIndex = clamp(this.nextTabIndex - goBack, 0, supportedFields.length);
                }
                else {
                    this.nextTabIndex = supportedFields.findIndex(f => f === this.currentFocusedField) - 1
                }
            }

            if(this.nextTabIndex >= supportedFields.length) {
                const saveEnabled = this.buttons && this.buttons.some(b => !b.state?.disabled);
                if (saveEnabled && supportedFields[this.nextTabIndex - 1].props.autoSelectFirstValueOnTab) {
                    this.focusButton();
                } else {
                    this.nextTabIndex = 0;
                }
            }
            if(this.nextTabIndex < supportedFields.length)
                supportedFields[clamp(this.nextTabIndex, 0, supportedFields.length)].setState({focused:true});
        }

        console.log(`focusField took ${Date.now() - start}ms`);
    };

    enableKeyOverriding = (e:React.FocusEvent<HTMLFormElement>) => {
		if (!this.props.disableKeyEvents) {
			e.target.addEventListener('keydown', this.onKey);
		}
    };

    disableKeyOverriding = (e:React.FocusEvent<HTMLFormElement>) => {
		if (!this.props.disableKeyEvents) {
			e.target.removeEventListener('keydown', this.onKey);
		}
    };

    onFieldMounted(field:FormFieldComponent) {
        if(field.props.ignoreBlockSave) {
            this.__bsuc_excludeFields.push(field.props.name);
        }
        if (field.props.type === 'file') {
            this.hasFile = true;
        }
    }

    render() {
        return (
            <form method={this.props.method}
                  autoComplete={"off"}
                  className={this.props.className ?? ""}
                  onSubmit={voidFormSubmit}
                  onFocus={this.enableKeyOverriding}
                  onBlur={this.disableKeyOverriding}
                  action={this.props.url} ref={o => this.instance = o}
                  onSubmitCapture={voidFormSubmit} style={{position: "relative", ...this.props.style}}>
                {this.props.children}
                <Loading ref={this.loading}/>
            </form>
        );
    }

}
