import {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {RouteComponentProps} from '../../../routes';

export function RouteRedirect(props: RouteComponentProps): any {
	const history = useHistory();

	useEffect(() => {
		history.replace(props.redirect);
	}, [history]);

	return null;
}