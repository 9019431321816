import {dispatchModal, ModalActionType} from "../../common/component/ModalContainer";
import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button, Typography} from "@material-ui/core";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";

/**
 * @param handled - se nastavuje jen pokud se v hooku definuje onCancel, ale v showModal se definuje pouze onConfirm
 */
export type Callback = (handled?:boolean)=>void

export type Callbacks = {
    onConfirm?:Callback
    onCancel?:Callback
}

export type ConfirmOptions = {
    title?:string,
    body?:string,
    htmlBody?:string,
    delay?:number,
    buttons?: {
        confirm?:string
        cancel?:string
    },
    hideCancel?:boolean
}&Callbacks


export function useConfirmDialog(callbacks?:Callback|Callbacks) {
    const {t} = useTranslation();
    const bRef = useRef<HTMLButtonElement>();
    const rippleRefCancel = useRef<any>();
    const rippleRefConfirm = useRef<any>();
    const showModal = (arg?:ConfirmOptions | Callback | Callbacks) => {
        const closeModal = () => dispatchModal({type:ModalActionType.Hide});
        const resolveOptions = () => {
            if(typeof callbacks !== "function") {
                return arg as ConfirmOptions;
            }
            return null;
        };

        const resolveOnConfirmCallback = ():Callback => {
            if(typeof arg === "function") {
                return arg as Callback;
            }
            if(typeof callbacks === "function") {
                return callbacks as Callback;
            }
            return null;
        };

        const resolveCallbacks = ():Callbacks => {
            if (arg && typeof arg !== "function") {
                return {
                    onConfirm:arg.onConfirm,
                    onCancel:arg.onCancel
                };
            }
            if (callbacks && typeof callbacks !== "function") {
                return {
                    onConfirm:callbacks.onConfirm,
                    onCancel:()=>callbacks.onCancel && callbacks.onCancel(true)
                };
            }
            return null;
        };


        const _callbacks = resolveCallbacks();
        const _callback = resolveOnConfirmCallback();
        const options = resolveOptions();

        const resolve = () => {
            let onConfirm = _callback ?? _callbacks?.onConfirm ?? (()=>{});
            let onCancel = _callbacks?.onCancel ?? (()=>{});

            return {onConfirm, onCancel};
        };
        const onConfirm = () => {
            resolve().onConfirm();
            closeModal();
        };
        const onCancel = () => {
            resolve().onCancel();
            closeModal();
        };

        const multilineBody = () => {
            const body = options?.body ?? t("ConfirmDialog.Body");
            const bodyArray = body.split('\n');
            const textBody = bodyArray.map((ba, index) => <Typography key={index}>{ba}</Typography>)
            return options?.htmlBody ? <div dangerouslySetInnerHTML={{__html: options?.htmlBody}}/> : textBody;
        }

        dispatchModal({
            delay:options.delay,
            type: ModalActionType.Show, title: options?.title ?? t("ConfirmDialog.Title"), body: (
                <Box>
                    <Box m={1}>{multilineBody()}</Box>
                    <Box>
                        <Box display="flex" flexDirection="row" justifyContent={"flex-end"}>
                            {options?.hideCancel ? undefined :
                                <Box p={1}>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        disableElevation
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onCancel&&onCancel();
                                        }}
                                        onFocus={() => rippleRefCancel?.current?.start({ center: true })}
                                        disableRipple
                                        onBlur={() => setTimeout(() => rippleRefCancel?.current?.stop({}), 1)}>
                                        {options?.buttons?.cancel ?? t("Buttons.Cancel")}
                                        <TouchRipple ref={rippleRefCancel} center />
                                    </Button>
                                </Box>
                            }
                            <Box p={1}>
                                <Button
                                    variant="contained"
                                    ref={bRef}
                                    autoFocus
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onConfirm&&onConfirm();
                                    }}
                                    onFocus={() => {
                                        rippleRefConfirm?.current?.start({center: true});
                                        setTimeout(() => {
                                            if (bRef.current && bRef.current !== document.activeElement)
                                                bRef.current?.focus();
                                        }, 10);
                                    }}
                                    disableRipple
                                    onBlur={() =>  setTimeout(() => rippleRefConfirm?.current?.stop({}), 1)}
                                >
                                    {options?.buttons?.confirm ?? t("Buttons.Confirm")}
                                    <TouchRipple ref={rippleRefConfirm} center />
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )
        });

    };


    return [showModal];
}
